
import {Menu, Typography} from "antd";
import styled from "styled-components";
import {color, devices} from "../../../../Theme";

export const ModalBox = styled.div`
  padding: 24px 5px 16px;
  height: 250px;

  @media ${devices.tablet} {
    padding: 20px 5px 10px;
    height: 250px;
  }
  @media ${devices.mobile} {
    padding: 5px 0;
    height: 250px;
  }
`;

export const ModalTitleWrap = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`;

export const ModalTitle = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding-right: 15px;
  @media ${devices.tablet} {
    font-size: 20px;
    line-height: 22px;
  }
  @media ${devices.mobile} {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const CloseModalIconWrap = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const ReviewWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const MenuWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
`;

export const ToolTipWrap = styled.div`
  position: absolute;
  transition: 0.2s;
  display: block;
  opacity: 0;
  height: 76px;
  width: 200px;
  bottom: -80px;
  right: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #fff;
  z-index: 100;
  &.active {
    opacity: 1;
  }
`;

export const MenuWrapItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 50%;
  transition: 0.2s;
  padding: 4px 15px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 500;
  &:hover{
    background: rgba(0, 0, 0, 0.04);
  }
  
  svg{
    margin-right: 9px;
  }
`;



