import React from 'react';

export const ArrowLeftIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5922 3.225L13.1089 1.75L4.86719 10L13.1172 18.25L14.5922 16.775L7.81719 10L14.5922 3.225Z"
                  fill="black" fillOpacity="0.87"/>
        </svg>
    );
};
