import {TabsProps} from "antd";
import React from "react";
import {AllArts} from "../View";
import {FavoriteArts} from "../View/FavoriteArts";
import {LocalLibraryOutlinedIcon} from "../../../Icons";
import {FlexCenter} from "../../../Components/styled";
import {FavoriteOutlinedIcon} from "../../../Icons/FavoriteOutlinedIcon";

export const items: TabsProps['items'] = [
    {
        key: '1',
        label: (
            <FlexCenter>
                <LocalLibraryOutlinedIcon/>
                all art
            </FlexCenter>
        ),
        children: <AllArts/>,
    },
    {
        key: '2',
        label: (
            <FlexCenter>
                <FavoriteOutlinedIcon/>
                my favorites
            </FlexCenter>
        ),
        children: <FavoriteArts/>,
    },
];