import styled from 'styled-components';
import { Input, InputNumber } from 'antd';
import { color } from '../../Theme';
import { variant } from 'styled-system';

const InputSize = variant({
  prop: 'size',
  variants: {
    md: {
      height: '54px',
      padding: '10px 20px'
    }
  }
});

const InputType = variant({
  prop: 'type',
  variants: {
    search: {
      color: 'rgba(0, 0, 0, 0.6)',
      background: 'rgba(0, 0, 0, 0.06)',
      borderRadius: '4px',
      border: '1px solid transparent',
      input: {
        background: 'transparent'
      }
    }
  }
});

export const StyledInput = styled(Input)`
  height: 32px;
  border: 1px solid ${color.grey};
  border-radius: 30px;
  padding: 5px 12px 5px 12px;

  &:active,
  :focus,
  :hover {
    border: 1px solid ${color.dark};
    box-shadow: none;
  }

  ${InputType}
  ${InputSize}
`;

export const StyledInputNew = styled(Input)`
  height: 49px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 13px 12px 13px 12px;

  &:active,
  :focus,
  :hover {
    border: 1px solid ${color.dark};
    box-shadow: none;
  }

  ${InputType}
  ${InputSize}
`;

export const StyledInputNumberNew = styled(InputNumber)`
  height: 49px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 13px 12px 13px 12px;
  width: 100%;
  &:active,
  :focus,
  :hover {
    border: 1px solid ${color.dark};
    box-shadow: none;
  }

  ${InputType}
  ${InputSize}
`;

export const InputPassword = styled(Input.Password)`
  height: 54px;
  border: 1px solid ${color.grey};
  border-radius: 30px;
  padding: 5px 12px 5px 12px;

  &:active,
  :focus,
  :hover {
    border: 1px solid ${color.dark};
    box-shadow: none;
  }

  ${InputSize}
`;
