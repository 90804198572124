
import CloseModalIcon from "../../../../Icons/CloseModalIcon";
import {Modal, Spin} from "antd";
import React, {FC, useEffect, useState} from "react";
import {useLazyListReviewsQuery} from "../../../../Redux/reviews/reviewsAPI";
import {
    CloseModalIconWrap, CustomModal,
    ModalBox,
    ModalTitle,
    ModalTitleWrap, ProgressBar, ProgressBarPercents,
    ReviewerDate,
    ReviewerName,
    ReviewerText,
    ReviewList,
    ReviewListAvatar,
    ReviewListItem,
    ReviewListItemInfo,
    ReviewListItemMoreInfo,
    ReviewStatistic, ReviewStatisticCount,
    ReviewStatisticItem,
    ReviewStatisticText, ReviewStatisticWrap,
    ReviewWrap
} from "./styled";
import StarRatings from 'react-star-ratings';
import YellowStarIcon from "../../../../Icons/YellowStarIcon";
import {getDayMonthYear} from "../../../../Utils/DateFormat";

interface IReviewsListModal {
    isModalOpen:boolean;
    setIsModalOpen: any;
    user: {id: string; name: string; hidden: boolean};
    artId: string;
}

export const ReviewsListModal:FC<IReviewsListModal> = ({user, isModalOpen,setIsModalOpen, artId}) => {
    const [getListReview, {data, isFetching,isLoading}] = useLazyListReviewsQuery();
    const [rateStat, setRateStat] = useState<{mark: number; count: number;percents: number}[]>([]);
    useEffect(()=>{
        if(isModalOpen){
            getListReview({
                userId: user.id,
                artId: artId
            }).unwrap();
        }
    },[isModalOpen])

    useEffect(()=>{
        console.log(data);
        if(data?.meta) {
            // console.log(data?.meta);
            const newArr = [];
            const newArr3 = [
                {mark: 1, count: 0, percents: 0},
                {mark: 2, count: 0, percents: 0},
                {mark: 3, count: 0, percents: 0},
                {mark: 4, count: 0, percents: 0},
                {mark: 5, count: 0, percents: 0},
            ];

            for (const key in data?.meta.rateStat) {
                const param = data?.meta.rateStat[key];
                for (const key2 in param) {
                    newArr.push( {mark: Number(key2), count: param[key2][0], percents: param[key2][1]})
                }
            }

            newArr.map((item)=>{
                newArr3.map((item2, index)=>{
                    if(item2.mark == item.mark){
                        newArr3[index].count = item.count;
                        newArr3[index].percents = item.percents;
                    }
                })
            });

            setRateStat([...newArr3.reverse()])
        }
    },[data])


    return (
        <CustomModal
            style={{padding: '0'}}
            open={isModalOpen}
            width={970}
            footer={null}
            closable={false}
        >
            { (isFetching || isLoading) && <Spin size="large" />}
            {
                !isFetching && !isLoading &&
                <ModalBox>
                    <ModalTitleWrap>
                        <ModalTitle>{user.name}</ModalTitle>
                        <CloseModalIconWrap onClick={()=>setIsModalOpen(false)}>
                            <CloseModalIcon />
                        </CloseModalIconWrap>
                    </ModalTitleWrap>
                    <ReviewWrap>
                        <ReviewStatistic>
                            <ReviewStatisticText>
                                <YellowStarIcon /> {data?.meta.rateScore}. {data?.meta.total} reviews
                            </ReviewStatisticText>
                            <ReviewStatisticWrap>
                                {
                                    rateStat.length &&
                                    rateStat.map((item, index)=>
                                        <ReviewStatisticItem key={item.mark}>
                                            <StarRatings
                                                rating={item.mark}
                                                isSelectable={false}
                                                starDimension={'20px'}
                                                starRatedColor='#FFB400'
                                                starHoverColor='#FFB400'
                                                starEmptyColor='rgba(0, 0, 0, 0.23)'
                                                numberOfStars={5}
                                                name='rating'
                                                starSpacing={'1px'}
                                                svgIconPath='M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z'
                                                svgIconViewBox={'0 0 20 20'}
                                            />
                                            <ProgressBar>
                                                <ProgressBarPercents style={{width: `${item.percents}%`}} />
                                            </ProgressBar>
                                            <ReviewStatisticCount>
                                                {item.count} reviews
                                            </ReviewStatisticCount>
                                        </ReviewStatisticItem>
                                    )
                                }
                            </ReviewStatisticWrap>
                        </ReviewStatistic>
                        <ReviewList>
                            {data?.data.map((item) =>
                                <ReviewListItem key={item.id}>
                                    <ReviewListAvatar>
                                        {item.attributes.reviewerName[0]}
                                    </ReviewListAvatar>
                                    <ReviewListItemInfo>
                                        <StarRatings
                                            rating={item.attributes.rate}
                                            isSelectable={false}
                                            starDimension={'20px'}
                                            starRatedColor='#FFB400'
                                            starHoverColor='#FFB400'
                                            starEmptyColor='rgba(0, 0, 0, 0.23)'
                                            numberOfStars={5}
                                            name='rating'
                                            starSpacing={'1px'}
                                            svgIconPath='M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z'
                                            svgIconViewBox={'0 0 20 20'}
                                        />
                                        <ReviewListItemMoreInfo>
                                            <ReviewerName>{item.attributes.reviewerName + ' - '}</ReviewerName>
                                            <ReviewerDate>{getDayMonthYear(item.attributes.createdAt)}</ReviewerDate>
                                        </ReviewListItemMoreInfo>
                                        {item.attributes.reviewerName != 'Anonymous' &&
                                            <ReviewerText>
                                                {item.attributes.text}
                                            </ReviewerText>
                                        }
                                    </ReviewListItemInfo>
                                </ReviewListItem>
                            )}
                        </ReviewList>
                    </ReviewWrap>
                </ModalBox>
            }
        </CustomModal>
    )
}
