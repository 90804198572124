import styled from "styled-components";
import {color, devices} from "../../../../Theme";
import {Input} from "antd";

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
`

export const UserBox = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  background: white;
  color: ${color.dark};
  padding: 16px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-bottom: 8px;
  max-width: 75%;
  word-break: break-word;

  &.sent {
    align-self: flex-end;
    background: rgba(0, 0, 0, 0.04);
  }

  &.received {
    align-self: flex-start;
  }
  @media ${devices.tabletS} {
    padding: 14px 8px;
  }
  @media ${devices.mobileL} {
    padding: 8px 6px;
  }
`

export const MessageBox = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${devices.tabletS} {
    padding: 10px 5px;
  }
`

export const New = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 8px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.46px;
`

export const UserAvatar = styled.img`
  display: flex;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 16px;
`

export const MessageWrapper = styled.div`
  flex: 1 0 auto;
`

export const ContentWrapper = styled.div`
  overflow-y: scroll;
  height: 90%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 9;
  background: #ffffff;
  flex-shrink: 0;
  color: ${color.dark};
  border-top: 1px solid ${color.lightGrey};
  padding: 8px;
  font-size: 16px;
  font-weight: 500;

  form {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 0 !important;
  }
`

export const Time = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
`

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`

export const MessageInput = styled(Input)`
  font-size: 16px;
  padding: 6px 6px;
  margin-right: 10px;
  
  @media ${devices.tablet} {
    font-size: 14px;
   
  }
  
  @media ${devices.mobileL} {
    font-size: 12px;
   
  }
`
