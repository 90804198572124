import React, { FC } from 'react';
import { GalleryBtnMore, GalleryCardsGrid } from './styled';
import Button from '../Button';
import { Empty, Spin } from 'antd';
import { IGalleryProps } from './typed';
import { EmptyGalleryIcon } from '../../Icons';
import { GalleryCardItem } from './GalleryCard';
import { FlexCenter } from '../styled';

const Gallery: FC<IGalleryProps> = ({
  data,
  isLoading,
  isSuccess,
  handleSize,
  isFetching,
  children,
  countArts,
  favorite
}) => {
  const artLength = countArts && data?.links?.next;
  const artLengthEmpty = data?.data.length > 0;

  return (
    <>
      {children}
      {(isLoading || isFetching) && (
        <FlexCenter>
          <Spin tip="Loading" size="small" />
        </FlexCenter>
      )}
      <GalleryCardsGrid>
        {isSuccess &&
          data?.data.map((item) => (
            <GalleryCardItem favorite={favorite} item={item} key={item.id} />
          ))}
      </GalleryCardsGrid>
      {!artLengthEmpty && !isFetching && (
        <Empty
          image={<EmptyGalleryIcon />}
          description={'There are no results that match your search'}
        />
      )}
      {artLength && !isFetching ? (
        <GalleryBtnMore>
          <Button onClick={handleSize}>{isFetching || isLoading ? 'loading...' : 'more'}</Button>
        </GalleryBtnMore>
      ) : null}
    </>
  );
};

export default Gallery;
