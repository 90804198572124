// Landing Page
import React, { useEffect, useState } from 'react';
import {
  SectionOneContent,
  SectionOneImage,
  NavItem,
  NavList,
  SectionOneImageContainer,
  ContentOnLeft,
  BodyText,
  HeaderOne,
  Button
} from './styledCopy';
import buyImage from '../../../Img/buy.jpg';
import sellImage from '../../../Img/sell.jpeg';
import analyticsImage from '../../../Img/analytics.jpeg';
import documentImage from '../../../Img/document.jpeg';
import { useNavigate } from 'react-router-dom';
export const SectionOne = () => {
  const [activeNavItem, setActiveNavItem] = useState('BUY');
  const [activeImage, setActiveImage] = useState(buyImage);
  const [activeHeader, setActiveHeader] = useState('Buy Direct, No Fees');
  const [activeBody, setActiveBody] = useState(
    'Search the fastest growing online database of investment quality fine art from top artist like Warhol, Basquiat, Haring, and more.'
  );
  const [activeButton, setActiveButton] = useState('Browse Artwork');
  const handleNavItemClick = (
    navItem: string,
    image: string,
    header: string,
    body: string,
    button: string
  ) => {
    setActiveNavItem(navItem);
    setActiveImage(image);
    setActiveHeader(header);
    setActiveBody(body);
    setActiveButton(button);
  };
  const navigate = useNavigate();
  return (
    <SectionOneContent>
      {/* <ContentOnLeftContainer> */}
      <ContentOnLeft>
        <NavList>
          <NavItem
            active={activeNavItem === 'BUY'}
            onClick={() =>
              handleNavItemClick(
                'BUY',
                buyImage,
                'Buy Direct, No Fees',
                'Search the fastest growing online database of investment quality fine art from top artist like Warhol, Basq, Haring, and more.',
                'Browse Artwork'
              )
            }
            isActive={activeNavItem === 'BUY'}>
            BUY
          </NavItem>
          <NavItem
            active={activeNavItem === 'SELL'}
            onClick={() =>
              handleNavItemClick(
                'SELL',
                sellImage,
                ' Sell On Your Terms',
                'Upload your artwork and have it listed on Provenart in just a few minutes. No middleman, no fees, no hassle. Control what you want to display or keep private.',
                'Find Out Demand'
              )
            }
            isActive={activeNavItem === 'SELL'}>
            SELL
          </NavItem>
          <NavItem
            active={activeNavItem === 'DOCUMENT'}
            onClick={() =>
              handleNavItemClick(
                'DOCUMENT',
                documentImage,
                'Organize Your Collection',
                'Provenart neatly and automatically organizes your collection information. Gone are the days of messy binders and email inboxes. Record your provenance info, receipts, and other documents securely in the cloud.',
                'Sign Up Now'
              )
            }
            isActive={activeNavItem === 'DOCUMENT'}>
            DOCUMENT
          </NavItem>
          <NavItem
            active={activeNavItem === 'ANALYTICS'}
            onClick={() =>
              handleNavItemClick(
                'ANALYTICS',
                analyticsImage,
                'Data that Works for You',
                'Get financial tracking information into your collection and watch your portfolios performance over time. View your collection by artist breakdown and return over time.',
                'Get Analytics Now'
              )
            }
            isActive={activeNavItem === 'ANALYTICS'}>
            ANALYTICS
          </NavItem>
        </NavList>
        <HeaderOne>{activeHeader}</HeaderOne>
        <BodyText>{activeBody}</BodyText>
        <Button primary="true" onClick={() => navigate('/gallery')}>
          {activeButton}
        </Button>
      </ContentOnLeft>
      {/* </ContentOnLeftContainer> */}
      <SectionOneImageContainer>
        <SectionOneImage src={activeImage} alt="Image Item"></SectionOneImage>
      </SectionOneImageContainer>
    </SectionOneContent>
  );
};
