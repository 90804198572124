import React from 'react';
import moment from 'moment';
import { DecimalBox } from './styled';
import { KeyboardUpArrowIcon } from '../../../Icons/KeyboardUpArrowIcon';
import { KeyboardDownArrowRed } from '../../../Icons';

export const Cell: any = [
  {
    title: 'Artwork Name',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Purchase Date',
    dataIndex: 'purchaseDate',
    key: 'purchaseDate',
    render: (text) => text && moment(text).format('DD MMMM yyyy')
  },
  {
    title: 'Purchase Price, $ ',
    dataIndex: 'purchasePrice',
    key: 'purchasePrice',
    render: (text) => text && '$' + text.toLocaleString()
  },
  {
    title: 'Selling Price, $',
    dataIndex: 'sellingPrice',
    key: 'sellingPrice',
    render: (text) => text && '$' + text.toLocaleString()
  },
  {
    title: 'Gain/Loss, %',
    dataIndex: 'gainLoss',
    key: 'gainLoss',
    render: (text) => (
      <DecimalBox>
        {text.slice(0, 2) === 'up' ? <KeyboardUpArrowIcon /> : <KeyboardDownArrowRed />}
        {text.slice(2, text.length)}
      </DecimalBox>
    )
  }
];
