import styled from 'styled-components';
import { Input } from 'antd';

export const CustomTextarea = styled(Input.TextArea)`
  padding: 10px;
  border-radius: 4px;
  label {
    color: rgba(0, 0, 0, 0.6)!important;
  }
  .ant-checkbox-wrapper {
    color: rgba(0, 0, 0, 0.6)!important;
  }
`;
