import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../../store';

export const notificationAPI = createApi({
    reducerPath: 'notificationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.provenart.io/api/v1/',
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).user.accessToken;
            // if (token) {
            headers.set('authorization', `Bearer ${token}`);
            // }
            return headers;
        }
    }),
    tagTypes: ['Notification'],
    endpoints: (build) => ({
        getNotifications: build.query({
            query: ({size, page}) => ({
                url: `notifications?page[size]=${size}&page[number]=${page}`,
                method: 'GET'
            })
        }),
        readNotification: build.mutation({
            query: ({id}) => ({
                url: `notifications/${id}/read`,
                method: 'PATCH'
            })
        })
    })
});

export const {
    useGetNotificationsQuery,
    useLazyGetNotificationsQuery,
    useReadNotificationMutation
} = notificationAPI;
