export const medium = [
  { id: 0, value: 'print_multiple', name: 'Print-Multiple' },
  { id: 1, value: 'painting', name: 'Painting' },
  { id: 2, value: 'ceramic', name: 'Ceramic' },
  { id: 3, value: 'objects', name: 'Objects' },
  { id: 4, value: 'drawing_watercolor', name: 'Drawing-Watercolor' },
  { id: 5, value: 'furniture', name: 'Furniture' },
  { id: 6, value: 'photography', name: 'Photography' },
  { id: 7, value: 'audiovisual_multimedia', name: 'Audiovisual-Multimedia' },
  { id: 8, value: 'sculpture_volume', name: 'Sculpture-Volume' },
  { id: 9, value: 'tapestry', name: 'Tapestry' },
  { id: 10, value: 'other', name: 'Other' }
];
