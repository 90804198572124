import React, {useCallback, useEffect, useState} from 'react';
import {FormBasic, FormCategoryTitle, Layout, UploadComponent, UploadDoc} from '../../../Components';
import {Col, message, Radio, Space, Switch} from 'antd';
import Button from '../../../Components/Button';
import {FormField, FormFieldNew, FormMediumTag} from '../../../Components/Form/styled';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {CreateArtFormItemConfig} from '../../../Components/Form/FormItem/config';
import {uuid} from '../../../Utils/RandomID';
import {StyledInputNew, StyledInputNumberNew} from '../../../Components/Input/styled';
import {StickyUpload} from '../../../Components/Upload/styled';
import {useCreateArtDocumentsMutation, useCreateArtMutation} from '../../../Redux/query/ArtsAPI';
import {Art, DocType} from '../View/SingleArt/typed';
import {
    AddNewArtContainer,
    AddNewArtFormWrap, AddNewArtFormWrapImage,
    AddNewArtTitleContainer,
    AddNewArtWrap,
    AddNewDocument,
    AddNewDocumentBox,
    ArtAddTitle,
    AvailableForChat,
    InputWrapContainerLeft,
    InputWrapContainerRight,
    PrivateCheckbox,
    PrivateNotesBar,
    PrivateNotesSubTitle,
    PrivateNotesTitle,
    PurchasePrice,
    TopBar
} from './styled';
import {medium} from "../../../Components/Form/FormMedium/config";
import {CustomTextarea} from "../../../Components/Form/FormTextarea/styled";
import {FormInputRow} from "../../../Components/Form/FormInputGroup/styled";
import {GalleryCarousel} from "../../../Components/Form/GalleryCarousel";

export const CreateArtObject = () => {
    const navigate = useNavigate();

    const [createArt, {isSuccess, isLoading}] = useCreateArtMutation();
    const [createArtDocuments] = useCreateArtDocumentsMutation();
    const [otherCategory, setOtherCategory] = useState(false);
    const [imageArr, setImageArr] = useState([]);
    const [stringImageArr, setStringImageArr] = useState<string[]>([]);
    const [object, setObject] = useState<DocType>([
        {id: uuid(), title: '', description: '', file: null, fileString: '', fileList: []}
    ]);

    const [artInfo, setArtInfo] = useState<Art>(
        {availableForChat: true,
            price: 1,
            purchasePrice: 0,
            sellingPrice: 1
        } as Art);

    const [privateFields, setPrivateFields] = useState({
        price: true,
        height: false,
        width: false,
        condition: false,
        year: false,
        city: false,
        provenance: false,
        period: false,
        description: false,
        notes: false,
        previous_owner: false,
        purchasePrice: true,
        selling_price: false
    });


    const changeMainObject = useCallback(
        (param: string, value) => {
            setArtInfo({...artInfo, [param]: value});
        },
        [artInfo]
    );

    const [addingDocument, setAddingDocument] = useState<boolean>(false);

    const handleOtherField = (e) => {
        if (e.target.value === 'other') {
            setOtherCategory(true);
        } else {
            setOtherCategory(false);
        }
    };

    const handleCheckbox = (e) => {
        const {value, checked} = e.target;
        setPrivateFields((prev) => ({...prev, [value]: checked}));
    };

    const addNewDoc = () => {
        setAddingDocument(true);
    };

    const infoImage = () => {
        message.warning('please upload images');
    };
    const infoMedium= () => {
        message.warning('please select medium');
    };
    const successAlert = () => {
        message.success('Successfully created Art');
    };

    const onFinish = async (data: Art) => {
        // console.log('data',data);
        // console.log('stringImageArr',stringImageArr);
        // console.log('privateFields.private_fields', privateFields);

        if (imageArr.length > 0) {
            if(!artInfo.medium) {infoMedium(); return }
            const artData = await createArt({
                art: {...artInfo, images: stringImageArr, private_fields: privateFields}
            }).unwrap();
            console.log(artData.data.id);
            object.forEach((doc) => {
                if (doc?.fileString) {
                    const obj = {
                        document: {
                            title: doc.title,
                            description: doc.description,
                            image: doc.fileString,
                            fileName: doc.fileName
                        }
                    };
                    createArtDocuments({
                        body: obj,
                        id: artData.data.id
                    }).unwrap();
                }
            });
        } else {
            infoImage();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            successAlert();
            navigate('/portfolio');
        }
    }, [isSuccess]);


  return (
    <Layout>
      <FormBasic name="art" onFinish={onFinish} layout="vertical" scrollToFirstError={true}>
        <AddNewArtWrap>
          <TopBar>
            <AvailableForChat>Available for chat </AvailableForChat>
            <Switch defaultChecked onChange={(checked) => changeMainObject('availableForChat', checked)} />
          </TopBar>
          <AddNewArtContainer>
            <AddNewArtFormWrapImage>
              <StickyUpload {...CreateArtFormItemConfig.images}>
                <UploadComponent
                    setImageArr={setImageArr}
                    imageArr={imageArr}
                    setStringImageArr={setStringImageArr}
                />
                {imageArr.length > 0 && (
                    <GalleryCarousel
                        setImageArr={setImageArr}
                        list={imageArr}
                        setStringImageArr={setStringImageArr}
                    />
                )}
              </StickyUpload>
            </AddNewArtFormWrapImage>
            <AddNewArtFormWrap>
              <AddNewArtTitleContainer>
                <ArtAddTitle>
                  Add art
                </ArtAddTitle>
                <FormField>
                  <Button type="primary" ghost disabled={isLoading} htmlType="submit">
                    {isLoading ? 'loading...' : 'PUBLISH ART'}
                  </Button>
                </FormField>
              </AddNewArtTitleContainer>
              <FormCategoryTitle name="Main information" />
              <FormField
                  required
                  label="Medium *"
                  rules={[{ required: true, message: 'Please check one of the categories!' }]}
                  initialValue={artInfo.medium}
              >
                    <Radio.Group name="medium">
                      <Space size={[10, 10]} wrap>
                        {medium.map((item) => (
                            <FormMediumTag
                                key={item.id}
                                onClick={(event) => {
                                  changeMainObject('medium', event.target.value);handleOtherField(event);}}
                                value={item.value}
                            >
                              {item.name}
                            </FormMediumTag>
                        ))}
                      </Space>
                    </Radio.Group>
              </FormField>
              {otherCategory && (
                  <FormFieldNew name="mediumOtherValue " label="Other" style={{marginTop: '25px'}}>
                    <Col flex={1}>
                      <CustomTextarea
                          onChange={(event) => changeMainObject('mediumOtherValue', event.target.value)}
                          value={artInfo?.mediumOtherValue || artInfo?.mediumOtherValue }
                          placeholder="Describe a medium of your art"
                          allowClear={true}
                          rows={2}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Col>
                  </FormFieldNew>
              )}
              <FormInputRow gutter={[24, 24]} style={{marginTop: '25px'}}>
                <Col flex={1}>
                  <FormFieldNew
                      required
                      label="Title*"
                      rules={[{ required: true, message: 'Please Input your Title!' }]}
                  >
                    <StyledInputNew
                        onChange={(event) => changeMainObject('title', event.target.value)}
                        required
                        name="title"
                        value={artInfo?.title}
                        allowClear={true}
                        placeholder={'Enter title of artwork'}
                    />
                  </FormFieldNew>
                </Col>
                <Col flex={1}>
                  <FormFieldNew
                      required
                      label="Artist name*"
                      rules={[{ required: true, message: 'Please Input your artist name!' }]}
                  >
                    <StyledInputNew
                        onChange={(event) => changeMainObject('artistName', event.target.value)}
                        required
                        name="artist_name"
                        value={artInfo?.artistName}
                        allowClear={true}
                        placeholder={'Enter Full Artist Name (i.e. "Andy Warhol") '}
                    />
                  </FormFieldNew>
                </Col>
              </FormInputRow>
              <FormInputRow style={{width: '100%'}}>
                <InputWrapContainerLeft>
                  <FormFieldNew
                      required
                      label="Selling price *"
                      rules={[
                        { required: true, message: 'please type price!' },
                        { min: 0.01, message: 'price must be bigger than 0.01' }
                      ]}
                  >
                    <StyledInputNumberNew
                        required
                        defaultValue={1}
                        min={0.1}
                        step={1}
                        value={artInfo?.sellingPrice}
                        max={1000000000}
                        onChange={(value) => changeMainObject('sellingPrice', value)}
                        name="sellingPrice"
                        stringMode
                    />
                  </FormFieldNew>
                  <PrivateCheckbox checked={privateFields.selling_price} value="selling_price" onChange={handleCheckbox}>
                    Set it private
                  </PrivateCheckbox>
                </InputWrapContainerLeft>
                <InputWrapContainerRight >
                  <FormFieldNew
                      required
                      label="Purchase cost*"
                      rules={[
                        { required: true, message: 'please type purchase cost!' },
                        { min: 0.01, message: 'Purchase cost must be bigger than 0.01' }
                      ]}
                  >
                    <StyledInputNumberNew
                        defaultValue={0}
                        min={0}
                        step={1}
                        value={artInfo?.purchasePrice}
                        max={1000000000}
                        onChange={(value) => changeMainObject('purchasePrice', value)}
                        name="purchasePrice"
                        stringMode
                    />
                  </FormFieldNew>
                  <PurchasePrice>
                      This is always private, visible to you only.
                  </PurchasePrice>
                </InputWrapContainerRight>
              </FormInputRow>
              <FormInputRow  style={{width: '100%'}}>
                <InputWrapContainerLeft>
                  <FormFieldNew
                      required
                      label="Current Value Price*"
                      rules={[
                        { required: true, message: 'please type purchase cost!' },
                        { min: 0, message: 'Purchase cost must be bigger than 0.01' }
                      ]}
                  >
                    <StyledInputNumberNew
                        required
                        defaultValue={1}
                        min={0}
                        step={1}
                        value={artInfo?.price}
                        max={1000000000}
                        onChange={(value) => changeMainObject('price', value)}
                        name="price"
                        stringMode
                    />
                  </FormFieldNew>
                  <PurchasePrice>
                    This is always private, visible to you only.
                  </PurchasePrice>
                </InputWrapContainerLeft>
              </FormInputRow>
              <FormField name={'classification'} label={'Classification *'} required
                         style={{marginBottom: '20px'}}
              >
                <Radio.Group defaultValue={'unique'}
                             value={artInfo.classification}
                             onChange={(e) => {
                                 if(e.target.value == 'limited_edition' || e.target.value == 'open_edition') {
                                     changeMainObject('editionNumber', 1);
                                     changeMainObject('editionSize', 1);
                                 }else {
                                     changeMainObject('editionNumber', null);
                                     changeMainObject('editionSize', null);
                                 }
                                 changeMainObject('classification', e.target.value);

                             }}>
                  <Radio value={'unique'}>Unique</Radio>
                  <Radio  value={'limited_edition'}>Limited edition</Radio>
                  <Radio  value={'open_edition'}>Open edition</Radio>
                  <Radio  value={'unknown'}>Unknown</Radio>
                </Radio.Group>
              </FormField>
              {
                (artInfo.classification == 'limited_edition' || artInfo.classification == 'open_edition') &&
                  <FormInputRow gutter={[24, 24]}>
                    <Col flex={1}>
                      <FormFieldNew label="Edition number">
                        <StyledInputNumberNew
                            defaultValue={1}
                            min={1}
                            step={1}
                            value={artInfo?.editionNumber}
                            max={1000000000}
                            onChange={(value) => changeMainObject('editionNumber', value)}
                            name="editionNumber"
                            stringMode
                        />
                      </FormFieldNew>
                    </Col>
                    <Col flex={1}>
                      <FormFieldNew label="Edition size">
                        <StyledInputNumberNew
                            defaultValue={1}
                            min={1}
                            step={1}
                            value={artInfo?.editionSize}
                            max={1000000000}
                            onChange={(value) => changeMainObject('editionSize', value)}
                            name="editionSize"
                            stringMode
                        />
                      </FormFieldNew>
                    </Col>
                  </FormInputRow>
              }
              <FormField name={'hideOwner'} label={'Your owner status *'} required>
                <Radio.Group defaultValue={false}
                             value={artInfo.hideOwner}
                             onChange={(e) => changeMainObject('hideOwner', e.target.value)}>
                  <Radio value={false}>Public</Radio>
                  <Radio value={true}>Anonymous</Radio>
                </Radio.Group>
              </FormField>
              <FormCategoryTitle name="Additional information" />
                <FormFieldNew name="description" label="Art description" style={{marginBottom: '20px'}}>
                    <CustomTextarea
                        onChange={(event) => changeMainObject('description', event.target.value)}
                        value={artInfo?.description}
                        placeholder="Describe a description of your art"
                        allowClear={true}
                        rows={2}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />

                                <PrivateCheckbox
                                    checked={privateFields.description}
                                    value="description"
                                    onChange={handleCheckbox}
                                >
                                    Set it private
                                </PrivateCheckbox>
                            </FormFieldNew>
                            <FormInputRow gutter={[24, 24]}>
                                <Col flex={1}>
                                    <FormFieldNew label="Height">
                                        <StyledInputNew
                                            value={artInfo?.height}
                                            placeholder="Enter height (i.e. 25 1/2 in)"
                                            name="height"
                                            allowClear={true}
                                            onChange={(event) => changeMainObject('height', event.target.value)}
                                        />
                                    </FormFieldNew>
                                    <PrivateCheckbox checked={privateFields.height} value="height"
                                                     onChange={handleCheckbox}>
                                        Set it private
                                    </PrivateCheckbox>
                                </Col>
                                <Col flex={1}>
                                    <FormFieldNew label="Width">
                                        <StyledInputNew
                                            value={artInfo?.width}
                                            placeholder="Enter width (i.e. 14 1/2 in)"
                                            name="weight"
                                            allowClear={true}
                                            onChange={(event) => changeMainObject('width', event.target.value)}
                                        />
                                    </FormFieldNew>
                                    <PrivateCheckbox checked={privateFields.width} value="width"
                                                     onChange={handleCheckbox}>
                                        Set it private
                                    </PrivateCheckbox>
                                </Col>
                            </FormInputRow>
                            <FormInputRow gutter={[24, 24]}>
                                <Col flex={1}>
                                    <FormFieldNew label="Year of creation">
                                        <StyledInputNew
                                            value={artInfo?.year && artInfo?.year.toString()}
                                            placeholder="YYYY"
                                            name="year"
                                            allowClear={true}
                                            onChange={(event) => changeMainObject('year', Number(event.target.value))}
                                        />
                                    </FormFieldNew>
                                    <PrivateCheckbox checked={privateFields.year} value="year"
                                                     onChange={handleCheckbox}>
                                        Set it private
                                    </PrivateCheckbox>
                                </Col>
                                <Col flex={1}>
                                    <FormFieldNew label="Period">
                                        <StyledInputNew
                                            value={artInfo?.period}
                                            placeholder="Type period of work"
                                            name="period"
                                            allowClear
                                            onChange={(event) => changeMainObject('period', event.target.value)}
                                        />
                                    </FormFieldNew>
                                    <PrivateCheckbox checked={privateFields.period} value="period"
                                                     onChange={handleCheckbox}>
                                        Set it private
                                    </PrivateCheckbox>
                                </Col>
                            </FormInputRow>
                            <FormFieldNew name="condition" label="Conditions" style={{marginBottom: '20px'}}>
                                <CustomTextarea
                                    onChange={(event) => changeMainObject('condition', event.target.value)}
                                    value={artInfo?.condition}
                                    placeholder="Describe the condition of the work"
                                    allowClear={true}
                                    rows={2}
                                    autoSize={{minRows: 3, maxRows: 5}}
                                />
                                <PrivateCheckbox checked={privateFields.condition} value="condition"
                                                 onChange={handleCheckbox}>
                                    Set it private
                                </PrivateCheckbox>
                            </FormFieldNew>

                            <FormInputRow gutter={[24, 24]}>
                                <Col flex={1}>
                                    <FormFieldNew label="City/Country">
                                        <Col flex={1}>
                                            <StyledInputNew
                                                value={artInfo?.city}
                                                placeholder="Enter a city/country (i.e. Miami, US)"
                                                name="city"
                                                allowClear={true}
                                                onChange={(event) => changeMainObject('city', event.target.value)}
                                            />
                                        </Col>
                                    </FormFieldNew>
                                    <PrivateCheckbox checked={privateFields.city} value="city"
                                                     onChange={handleCheckbox}>
                                        Set it private
                                    </PrivateCheckbox>
                                </Col>
                                <Col flex={1}>
                                    <FormFieldNew label="Provenance">
                                        <StyledInputNew
                                            value={artInfo?.provenance}
                                            placeholder="Describe how you acquired the work"
                                            name="provenance"
                                            allowClear={true}
                                            onChange={(event) => changeMainObject('provenance', event.target.value)}
                                        />
                                    </FormFieldNew>
                                    <PrivateCheckbox
                                        checked={privateFields.provenance}
                                        value="provenance"
                                        onChange={handleCheckbox}
                                    >
                                        Set it private
                                    </PrivateCheckbox>
                                </Col>
                            </FormInputRow>


                            <UploadDoc
                                setObject={setObject}
                                object={object}
                                setAddingDocument={setAddingDocument}
                                addingDocument={addingDocument}
                            />
                            {object?.length != 8 && (
                                <AddNewDocumentBox>
                                    <AddNewDocument onClick={() => addNewDoc()}>
                                        <PlusOutlined/> Add new document
                                    </AddNewDocument>
                                </AddNewDocumentBox>
                            )}
                            <PrivateNotesBar>
                                <PrivateNotesTitle>
                                    Private notes
                                </PrivateNotesTitle>
                                <PrivateNotesSubTitle>
                                    This block is visible to you only. The information entered as private notes is never
                                    to be public.
                                </PrivateNotesSubTitle>
                                <FormFieldNew name="notes" label="Notes">
                                    <Col flex={1}>
                                        <CustomTextarea
                                            onChange={(event) => changeMainObject('notes', event.target.value)}
                                            value={artInfo?.notes}
                                            placeholder="Write any private notes here. Only you will be able to see this."
                                            allowClear={true}
                                            rows={2}
                                            autoSize={{minRows: 3, maxRows: 5}}
                                        />
                                    </Col>
                                </FormFieldNew>
                            </PrivateNotesBar>
                        </AddNewArtFormWrap>
          </AddNewArtContainer>
        </AddNewArtWrap>
      </FormBasic>
    </Layout>
);
};
