import React, {FC} from "react";
import {TooltipProps} from "antd";

import {TooltipStyled} from "./styled";

export const Tooltip: FC<TooltipProps> = ({children, showArrow, color}) => {
    return (
        <TooltipStyled title="Verified" showArrow={showArrow} color={color}>
            {children}
        </TooltipStyled>
    );
};


Tooltip.defaultProps = {
    showArrow: false,
    title: '',
    color: 'rgba(97, 97, 97, 0.9)'
}