import React from 'react';

const DeleteArtIcon = (props) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.59 7.8525L9 9.4425L7.4025 7.8525L6.345 8.91L7.9425 10.5L6.3525 12.09L7.41 13.1475L9 11.5575L10.59 13.1475L11.6475 12.09L10.0575 10.5L11.6475 8.91L10.59 7.8525ZM11.625 3L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3H11.625ZM4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25H4.5V14.25ZM6 6.75H12V14.25H6V6.75Z" fill="black" fillOpacity="0.54"/>
        </svg>
    );
};

export default DeleteArtIcon;
