import React from 'react';
import { NavLink } from '../Components/Header/styled';
import { useAppSelector } from '../Hooks';
import { RoutePrivateStyled } from './styled';

const useRoutesAuthentificated = () => {
  const { userInfo } = useAppSelector((state) => state.user);
  // TODO change this later to include About and Magazine (get rid of home later)
  if (!userInfo) {
    return [
      {
        name: 'Gallery',
        path: `/gallery`,
        exact: true,
        auth: !userInfo || userInfo
      },
      {
        name: 'Portfolio',
        path: userInfo ? `/portfolio` : `/registration`,
        exact: true,
        auth: !userInfo || userInfo
      },
      {
        name: 'Analytics',
        path: userInfo ? '/analytics' : `/registration`,
        exact: true,
        auth: !userInfo || userInfo
      }
    ].filter((el) => el.auth);
  } else {
    return [
      {
        name: 'Gallery',
        path: `/gallery`,
        exact: true,
        auth: !userInfo || userInfo
      },
      {
        name: 'Portfolio',
        path: userInfo ? `/portfolio` : `/registration`,
        exact: true,
        auth: !userInfo || userInfo
      },
      {
        name: 'Analytics',
        path: userInfo ? '/analytics' : `/registration`,
        exact: true,
        auth: !userInfo || userInfo
      }
    ].filter((el) => el.auth);
  }
};
export const RoutePrivate = () => {
  const routes = useRoutesAuthentificated();
  return (
    <RoutePrivateStyled>
      {routes.map(({ name, path }) => {
        return (
          <NavLink key={name} to={path}>
            {name}
          </NavLink>
        );
      })}
    </RoutePrivateStyled>
  );
};
