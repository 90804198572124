import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { color, devices } from '../../Theme';
import { Typography } from 'antd';
import { StyledButton } from '../Button/styled';

export const NavBar = styled.nav`
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 16px 58px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 0 1px #e0e0e0;
  @media ${devices.laptop} {
    padding: 16px 30px 16px 16px;
  }
  @media ${devices.tablet} {
    padding: 16px 20px 16px 10px;
  }
  @media ${devices.tabletS} {
    padding: 10px 20px 10px 10px;
  }
  @media ${devices.mobile} {
    padding: 10px 10px 10px 10px;
  }
`;
export const NavLogo = styled(Link)`
  @media ${devices.tablet} {
    padding: 0 12px 0;
    border-right: none;
    svg {
      width: 120px;
    }
  }
  @media ${devices.tabletS} {
    margin-right: 10px;
    padding: 0 12px 0 40px;
  }
  @media ${devices.mobile} {
    display: none;
  }
`;
export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media ${devices.mobileL} {
    gap: 7px;
  }
  @media ${devices.mobile} {
    width: 100%;
    &:last-child {
      justify-content: flex-end;
    }
  }
`;

export const Price = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  @media ${devices.mobileL} {
    font-size: 16px;
  }
  @media ${devices.mobile} {
    font-size: 12px;
  }
  @media ${devices.mobileM} {
    font-size: 10px;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  letter-spacing: 0.46px;
  font-size: 16px;
  margin-right: 10px;

  &:hover {
    color: #252525;
  }

  &.active {
    color: ${color.primary};
  }
  @media ${devices.laptop} {
    font-size: 14px;
    margin-right: 7px;
  }
  @media ${devices.tablet} {
    font-size: 12px;
    margin-right: 5px;
  }
  @media ${devices.tabletS} {
    color: ${color.secondary};
    font-size: 16px;
  }
`;
export const NavRoutes = styled.div`
  display: flex;
  @media ${devices.tabletS} {
    display: none;
  }
`;
export const MenuLabel = styled.label`
  background-color: ${color.lightGrey};
  border-radius: 50%;
  position: absolute;
  padding-top: 2px;
  top: 14px;
  left: 8px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  z-index: 1000;
  text-align: center;
  display: none;
  @media ${devices.tabletS} {
    display: block;
  }
  @media ${devices.mobile} {
    top: 14px;
  }
`;

export const Icon = styled.span`
  position: relative;
  background-color: ${(props) => (props.clicked ? 'transparent' : 'black')};
  width: 18px;
  top: 2px;
  height: 2px;
  display: inline-block;
  transition: all 0.3s;

  &::before,
  &::after {
    content: '';
    background-color: black;
    width: 18px;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  &::before {
    top: ${(props) => (props.clicked ? '0' : '-7px')};
    transform: ${(props) => (props.clicked ? 'rotate(135deg)' : 'rotate(0)')};
  }

  &::after {
    top: ${(props) => (props.clicked ? '0' : '7px')};
    transform: ${(props) => (props.clicked ? 'rotate(-135deg)' : 'rotate(0)')};
  }
`;

export const Navigation = styled.nav`
  height: 100vh;
  position: fixed;
  background: rgba(99, 102, 120, 0.95);
  top: 0;
  z-index: 600;
  width: 100%;
  left: ${(props) => (props.clicked ? '0' : '-100%')};
  //opacity: ${(props) => (props.clicked ? '1' : '0')};
  transition: width 0.8s, opacity 0.8s, left 0.8s;
`;

export const List = styled.ul`
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;

export const AddArtBtn = styled(StyledButton)`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @media ${devices.tabletS} {
    padding: 4px 6px 4px 6px;
  }
`;

export const DropdownMenu = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

export const DropdownItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 16px;
`;

export const UserEmail = styled.div`
  color: rgba(0, 0, 0, 0.6);
`;

export const DropdownButton = styled(AddArtBtn)`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background: rgba(25, 118, 210, 0.04);

  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }

  @media ${devices.tabletS} {
    padding: 4px 2px 4px 6px;
  }
`;

export const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  margin-bottom: 8px;

  svg {
    margin-right: 35px;
  }
`;
