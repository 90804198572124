import React, {useEffect, useState} from "react";
import useDebounce from "../../../Hooks/useDebounce";
import {useAppDispatch, useAppSelector} from "../../../Hooks";
import {getUserDetails} from "../../../Redux/features";
import {useGetMyCollectionQuery} from "../../../Redux/query/ArtsAPI";
import {Spin} from "antd";
import {InputCustom} from "../../../Components";

import {GallerySearchBox} from "../../../Components/Gallery/styled";
import {SearchOutlined} from "@ant-design/icons";
import Gallery from "../../../Components/Gallery";

export const MyArts = () => {
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce<string>(search, 500);
    const {userInfo, accessToken} = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (accessToken) {
            dispatch(getUserDetails());
        }
    }, [accessToken, dispatch]);
    const handleSize = () => {
        setSize((prev) => prev + 10);
    };
    const {data, isLoading, isSuccess, isFetching} = useGetMyCollectionQuery(
        {
            id: userInfo?.data?.id,
            page,
            size,
            search: debouncedSearch,
            hidden: false
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            skip: false
        }
    );
    return (
        <>
            {isFetching || (isLoading && <Spin size="large"/>)}
            <GallerySearchBox>
                <InputCustom
                    prefix={<SearchOutlined/>}
                    placeholder={'search'}
                    type={'search'}
                    value={search}
                    allowClear
                    onChange={(e) => setSearch(e.target.value)}
                />
            </GallerySearchBox>
            {data?.data && (
                <Gallery
                    countArts
                    data={data}
                    isSuccess={isSuccess}
                    isLoading={isLoading}
                    handleSize={handleSize}
                    isFetching={isFetching}
                />
            )}
        </>
    );
}
