import React from 'react';

export const InActiveArtsIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M2.81062 2.81L1.39062 4.22L3.66062 6.49C2.61063 8.07 2.00063 9.96 2.00063 12C2.00063 17.52 6.48062 22 12.0006 22C14.0406 22 15.9306 21.39 17.5106 20.34L19.7806 22.61L21.1906 21.2L2.81062 2.81ZM12.0006 20C7.59062 20 4.00063 16.41 4.00063 12C4.00063 10.52 4.41062 9.14 5.12063 7.94L16.0606 18.88C14.8606 19.59 13.4806 20 12.0006 20ZM7.94063 5.12L6.49062 3.66C8.07062 2.61 9.96062 2 12.0006 2C17.5206 2 22.0006 6.48 22.0006 12C22.0006 14.04 21.3906 15.93 20.3406 17.51L18.8806 16.05C19.5906 14.86 20.0006 13.48 20.0006 12C20.0006 7.59 16.4106 4 12.0006 4C10.5206 4 9.14062 4.41 7.94063 5.12Z"
                  fill="currentColor"/>
        </svg>

    );
};
