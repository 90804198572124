// Landing Page
import React, { useEffect, useState } from 'react';
import { FooterContent, FooterRow, CopyRight, FooterLink, FooterLinkList } from './styledCopy';

export const Footer = () => {
  return (
    <FooterContent>
      <FooterRow>
        <CopyRight>&#169; 2023 Provenart. All Rights Reserved.</CopyRight>
        <FooterLinkList>
          <FooterLink>Privacy Policy</FooterLink>
          <FooterLink>Cookie Policy</FooterLink>
          <FooterLink>Terms of Use</FooterLink>
        </FooterLinkList>
      </FooterRow>
    </FooterContent>
  );
};

export default Footer;
