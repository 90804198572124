import styled from 'styled-components';
import {Button, Typography} from 'antd';
import {devices} from "../../Theme";

export const AnalyticsBox = styled.div`
  margin: 0 auto 40px auto;
  max-width: 1390px;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${devices.tablet} {
    flex-wrap: wrap
  }
`;

export const PieChartWrap = styled.div`
  max-width: 680px;
  padding: 0 20px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${devices.laptop} {
    padding: 0 10px 0 0;
  }
  @media ${devices.tablet} {
    max-width: 100%;
    padding: 0;
  }
  // @media ${devices.mobileL} {
  //   max-width: 600px;
  //   width: 600px;
  //   min-width: 600px;
  //   overflow-x: scroll;
  //   padding: 0;
  // }
`;

export const Title = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 34px;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  margin-bottom: 32px;
  @media ${devices.laptop} {
    font-size: 30px;
    line-height: 110%;
  }
  @media ${devices.tabletS} {
    font-size: 24px;
  }
  @media ${devices.mobileL} {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const LineChartBox = styled.div`
  max-width: 617px;
  height: 501px;
  padding: 0 0 0 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${devices.laptop} {
    padding: 0 0 0 10px;
    height: 550px;
  }
  @media ${devices.tablet} {
    max-width: 100%;
    padding: 0;
  }
`;

export const EmptyAnalyticsBox = styled.div`
  max-width: 450px;
  margin: 0 auto;
  padding: 20px 0 28px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const EmptyAnalyticsTitle = styled(Typography)`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #000000;
  margin: 24px auto 12px;
  width: 100%;
`;

export const EmptyAnalyticsP= styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 auto 20px;
  width: 100%;
`;

export const EmptyAnalyticsButton = styled(Button)`
  text-transform: uppercase;
  text-align: center;
  height: 42px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #1976D2;
  letter-spacing: 0.46px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
`;


