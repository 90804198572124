import React from 'react';

export const CheckCircleOutlinedIcon = (props) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2082 6.94801L9.16732 12.9888L5.87648 9.70717L4.58398 10.9997L9.16732 15.583L16.5007 8.24967L15.2082 6.94801ZM11.0007 1.83301C5.94065 1.83301 1.83398 5.93967 1.83398 10.9997C1.83398 16.0597 5.94065 20.1663 11.0007 20.1663C16.0607 20.1663 20.1673 16.0597 20.1673 10.9997C20.1673 5.93967 16.0607 1.83301 11.0007 1.83301ZM11.0007 18.333C6.94898 18.333 3.66732 15.0513 3.66732 10.9997C3.66732 6.94801 6.94898 3.66634 11.0007 3.66634C15.0523 3.66634 18.334 6.94801 18.334 10.9997C18.334 15.0513 15.0523 18.333 11.0007 18.333Z"
                fill="white"/>
        </svg>

    );
};
