import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {Button, Modal} from "antd";

import FilerobotImageEditor, {
    FilerobotImageEditorConfig,
    TABS, TOOLS
} from "react-filerobot-image-editor";
import {IconButton, ModalBox} from "./styled";
import {Doc, DocType} from "../../../Pages/art/View/SingleArt/typed";
import {UploadOutlined} from "@ant-design/icons";
import EditIcon from "../../../Icons/EditIcon";

interface IEditImageModal {
   file: string;
   index: number;
    setDocStringArr?: any;
    object?: Doc;
    setObject?: any;
    changeNameOfFile?: any;
    edit?: boolean;
    fileName?: string;
    newFile?:boolean;
}

export const EditImageModal:FC<IEditImageModal> = ({
    file,
    index,
    setDocStringArr,
    object,
    setObject,
    changeNameOfFile,
    edit= true,
    fileName,
    newFile
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const editorConfig = ['.FIE_text-tool-button', '.FIE_image-tool-button', '.FIE_ellipse-tool-button',
        '.FIE_polygon-tool-button', '.FIE_pen-tool-button', '.FIE_line-tool-button', '.FIE_arrow-tool-button'];

    const openImgEditor = () => {
        setIsModalOpen(true);
        document.body.setAttribute('style', `${document.body.getAttribute('style')} overflow: hidden !important;`);
    };

    const closeImgEditor = () => {
        setIsModalOpen(false);
        document.body.setAttribute('style', ``);
    };

    useEffect(()=>{
        return () => {
            document.body.setAttribute('style', ``);
            // setIsModalOpen(false);
        }
    })

    useEffect(()=>{
        if (isModalOpen) {
            document.body.setAttribute('style', `${document.body.getAttribute('style')} overflow: hidden !important;`);
            setTimeout(()=>{
                editorConfig.forEach((item)=>{
                    const elem = document.querySelector(item);
                    elem.setAttribute('style', `width: 1px; height: 1px; position: absolute; top: -1000%; right: -1000%; padding: 0`);
                })
            },300);
        }
    },[isModalOpen])

    const saveDoc = (editedImageObject: any) => {
        console.log('saved', editedImageObject);
        setDocStringArr(prev=> {
            const newArr = prev;
            newArr[index] = editedImageObject.imageBase64;
            return [...newArr];
        });
        console.log('object', object);
        if(edit){
            setObject(prev=> {
                const newArr = prev;
                const renamedFile = new File([newArr[index].file], editedImageObject.fullName, { type: newArr[index].file.type });
                console.log('renamedFile',prev,newArr[index].file, renamedFile, object.fileList);
                newArr[index].file = renamedFile;

                newArr[index].fileString = editedImageObject.imageBase64;
                return [...newArr];
            })
            changeNameOfFile(index,editedImageObject.fullName, object.fileList);
        }else {
            setObject(prev=> {
                const NewDoc = prev;
                if(newFile){
                    const renamedFile = new File([NewDoc.file], editedImageObject.fullName, { type: NewDoc.file.type });
                    console.log('renamedFile',prev,NewDoc.file, renamedFile, object.fileList);
                    NewDoc.file = renamedFile;
                    changeNameOfFile(index,editedImageObject.fullName, [NewDoc.file]);
                }
                NewDoc.fileName = editedImageObject.fullName;
                NewDoc.fileString = editedImageObject.imageBase64;
                NewDoc.prefixImageString = '';
                return {...NewDoc};
            });

        }
        console.log('final edited object',object);

        closeImgEditor();
    }


    return (
        <>
            <IconButton shape={'round'} onClick={openImgEditor} >
                redact
            </IconButton>
            <Modal
                style={{padding: '0'}}
                open={isModalOpen}
                width={900}
                footer={null}
                closable={false}
            >
                <ModalBox>
                    {
                        isModalOpen &&
                        <FilerobotImageEditor
                            source={file}
                            onSave={(editedImageObject) => saveDoc(editedImageObject)}
                            onClose={closeImgEditor}
                            annotationsCommon={{
                                fill: '#ff0000',
                            }}
                            tabsIds={[TABS.ANNOTATE]}
                            defaultTabId={TABS.ANNOTATE}
                            defaultToolId={'Rect'}
                            previewPixelRatio={1}
                            savingPixelRatio={4}
                            defaultSavedImageName={fileName || object?.file.name}
                        />
                    }
            </ModalBox>
        </Modal>
        </>

    )
}
