import React from 'react';

import {Tabs} from "../../Components/Tabs";
import {items} from "./config";
import {Layout} from "../../Components";
import {FlexCenter} from "../../Components/styled";
import {Title} from "./styles";


export const DashboardPage = () => {

  return (
      <Layout>
          <FlexCenter>
              <Title level={2}>Portfolio</Title>
          </FlexCenter>
        <Tabs items={items} centered/>
      </Layout>
  );
};
