import { Col, Layout, Upload } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { DocumentBox, DocumentWrap } from './styled';
import { DocView } from '../DocView';
import {
  DeleteDocumentBox,
  DeleteDocumentButton,
  FormInputRow, RedactedTitle, RedactTitle
} from '../../../Upload/Documents/styled';
import DeleteIcon from '../../../../Icons/DeleteIcon';
import { DocType } from '../../../../Pages/art/View/SingleArt/typed';
import { useDeleteArtDoc } from '../../../../Pages/art/hooks';
import { EditDocModal } from './EditDocModal';
import { AddNewDocumentBox } from '../../../../Pages/art/Create/styled';
import Button from '../../../Button';
import {  PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { FormField } from '../../../Form/styled';
import { StyledInput } from '../../../Input/styled';
import { acceptedFiles, getBase64 } from '../../../../Utils';
import TextArea from 'antd/lib/input/TextArea';
import { uuid } from '../../../../Utils/RandomID';
import {EditImageModal} from "../../../Upload/EditImageModal";
import {RcFile} from "antd/lib/upload/interface";
import DeleteDocumentIcon from "../../../../Icons/DeleteDocumentIcon";
import RedactedDocIcon from "../../../../Icons/RedactedDocIcon";

interface IDocList {
  object: DocType;
  setObject: Dispatch<SetStateAction<DocType>>;
  newDocs: DocType;
  setNewDocs: Dispatch<SetStateAction<DocType>>;
  refetch: () => void;
}

export const DocumentList: FC<IDocList> = ({ object, setObject, refetch, newDocs, setNewDocs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { deleteAtrDocument } = useDeleteArtDoc();
  const [selectEditDoc, setSelectEditDoc] = useState<number>(null);
  const [docStringArr,setDocStringArr] = useState<string[]>([]);

  const deleteDoc = (index: number, id: string, artId: string) => {
    console.log(id, artId);
    const removeArr = object;
    removeArr.splice(index, 1);
    setObject([...removeArr]);

    deleteAtrDocument({
      id: artId,
      docId: id
    }).unwrap();
  };

  const editDocModal = (index: number) => {
    setSelectEditDoc(index);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeDesc = (id, event) => {
    setNewDocs((prev) => {
      const newArray = [...prev];
      newArray.forEach((el) => {
        if (el.id === id) {
          el.description = event.target.value;
        }
      });
      return newArray;
    });
  };
  const handleChangeTitle = (id, event) => {
    setNewDocs((prev) => {
      const newArray = [...prev];
      newArray.forEach((el) => {
        if (el.id === id) {
          el.title = event.target.value;
        }
      });
      return newArray;
    });
  };

  const handleBeforeUpload = (file, fileList, item,index) => {
    // console.log('Custom parameter:', item, file);
    const splitString = file.type.split('/');

    setNewDocs(prev=> {
      const newArr = prev;
      if(splitString[0] == 'image'){
        newArr[index].contentType = 'image';
        getBase64(file)
            .then((result) => {
              newArr[index].fileString = result as string;
              setDocStringArr((prev) => [...prev, result as string]);
            })
            .catch((err) => {
              console.log(err);
            });
      }
      newArr[index].file = new File([file], file.name, {type: file.type});
      newArr[index].fileName = file.name;
      newArr[index].fileList = fileList;
      return [...newArr];
    })

    return false;
  };

  const handleRemoveFile = (file, index: number) => {
    setNewDocs(prev=> {
      const newArr = prev;
      newArr[index].file = null;
      newArr[index].fileName = '';
      newArr[index].fileList = [];
      return [...newArr];
    });
    const removeArr = docStringArr;
    removeArr.splice(index, 1);
    setDocStringArr([...removeArr]);
  }


  const deleteNewDoc = (index: number) => {
    const removeArr = newDocs;
    removeArr.splice(index, 1);
    setNewDocs([...removeArr]);
  };

  const handleUpload = (e, id) => {
    setNewDocs((prev) => {
      const newArray = [...prev];
      newArray.forEach((el) => {
        if (el.id === id) {
          el.file = e.file;
          el.fileName = e.file.name;
          getBase64(el.file)
            .then((result) => {
              el.fileString = result as string;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
      return newArray;
    });
  };

  const handleUpdateName = (index:number, newName: string, fileList ) => {
    const file = fileList[0];
    const fileContent = file.originFileObj as Blob;
    const newFile = new File([fileContent], newName, { type: file.type }) as RcFile;
    newFile.uid = file.uid as any;

// Update the corresponding UploadFile object
    const updatedFile = {
      ...fileList[index],
      name: newName,
      originFileObj: newFile,
    };
// Update the fileList state
    const updatedFileList = [updatedFile];

    setNewDocs((prev) => {
      const newArr = prev;
      newArr[index].file = new File([newArr[index].file], newName, {type: newArr[index].file.type});
      newArr[index].fileName = newName;
      newArr[index].fileList = updatedFileList;
      return [...newArr];
    });

  };

  const addNewDoc = () => {
    if (newDocs.length + object.length < 8) {
      setNewDocs((prev) => [
        ...prev,
        { id: uuid(), title: '', description: '', file: null, fileString: '', url: '', artId: '', fileList: [], fileName: '', attachmentUrl: '', contentType: '' }
      ]);
    }
  };

  return (
    <Layout style={{ background: 'transparent' }}>
      {object?.map((doc, index) => (
        <DocumentBox key={doc.id}>
          <DocumentWrap>
            <Col flex={1}>
              <DocView doc={doc} />
            </Col>
            <Col flex={1}>
              <DeleteDocumentBox>
                <DeleteDocumentButton onClick={() => deleteDoc(index, doc.id, doc.artId)}>
                  <DeleteDocumentIcon />
                </DeleteDocumentButton>
              </DeleteDocumentBox>
              {
                doc?.redacted ?
                    <DeleteDocumentBox>
                      <RedactedDocIcon />
                      <RedactedTitle >
                        redacted
                      </RedactedTitle>
                    </DeleteDocumentBox>
                    :
                    <DeleteDocumentBox>
                      <RedactTitle onClick={() => editDocModal(index)}>
                        redact
                      </RedactTitle>
                    </DeleteDocumentBox>
              }

            </Col>
          </DocumentWrap>
        </DocumentBox>
      ))}
      {newDocs.map((item, index) => (
        <DocumentBox key={item.id} style={{}}>
          <FormInputRow>
            <Col flex={1}>
              <FormField label="Document title">
                <StyledInput
                  onChange={(e) => handleChangeTitle(item.id, e)}
                  value={item.title}
                  placeholder={'Title of your document'}
                />
              </FormField>
            </Col>
            <Col flex={1}>
              <DeleteDocumentBox>
                <DeleteDocumentButton onClick={() => deleteNewDoc(index)}>
                  <DeleteIcon />
                </DeleteDocumentButton>
              </DeleteDocumentBox>
            </Col>
          </FormInputRow>
          <FormField label="Document description">
            <TextArea
              onChange={(e) => handleChangeDesc(item.id, e)}
              rows={2}
              value={item.description}
              placeholder={'Describe the uploaded document'}
            />
          </FormField>
          <Upload
            fileList={item.fileList}
            beforeUpload={(file, fileList) => handleBeforeUpload(file, fileList, item, index)}
            accept={acceptedFiles}
            onRemove={(file)=> handleRemoveFile(file, index)}
            onChange={(e) => handleUpload(e, item.id)}
            maxCount={1}
          >
            <Button shape={'round'} icon={<UploadOutlined />}>
              Select File
            </Button>
          </Upload>
          {
              item.contentType == 'image' && item.fileList?.length > 0 && docStringArr[index]?.length > 0 &&
              <EditImageModal
                  file={docStringArr[index]}
                  index={index}
                  object={item}
                  setDocStringArr={setDocStringArr}
                  setObject={setNewDocs}
                  changeNameOfFile={handleUpdateName}
                  edit={true}
              />
          }
        </DocumentBox>
      ))}
      {(newDocs.length + object.length) !== 8 && (
        <AddNewDocumentBox>
          <Button onClick={() => addNewDoc()} shape={'round'} icon={<PlusOutlined />}>
            Add new document
          </Button>
        </AddNewDocumentBox>
      )}
      <EditDocModal
        setObject={setObject}
        refetch={refetch}
        open={isModalOpen}
        doc={object[selectEditDoc]}
        onCancel={handleCancel}
        indexOfDoc={selectEditDoc}
      />
    </Layout>
  );
};
