import { color, devices } from '../../../../Theme';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { Typography } from 'antd';
import { VerifiedIcon } from '../../../../Icons';

const ArtTextType = variant({
  prop: 'type',
  variants: {
    time: {
      fontSize: '16px',
      lineHeight: '22px',
      color: color.grey
    },
    title: {
      fontSize: '24px',
      lineHeight: '22px',
      fontWeight: 600,
      color: color.dark
    },
    name: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 500,
      color: color.dark
    },
    description: {
      fontSize: '16px',
      lineHeight: '22px',
      color: color.dark
    },
    label: {
      fontSize: '16px',
      display: 'inline-flex',
      alignItems: 'center',
      lineHeight: '22px',
      color: color.grey,
      marginRight: '4px'
    },
    price: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 500,
      color: color.green
    }
  }
});

export const ArtMainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media ${devices.tablet} {
    flex-wrap: wrap;
  }
`;

export const IconButtonBox = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 27px;
`;

export const VerifiedIconBox = styled(VerifiedIcon)`
  cursor: pointer;
  margin-left: 4px;
`;

export const WrapRowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

export const WrapInfo = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const ArtMedium = styled.div`
  width: fit-content;
  background: #ede7f6;
  border-radius: 16px;
  padding: 7px 10px;
  letter-spacing: 0.16px;
  color: #673ab7;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
`;

export const WrapRowInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ArtSticky = styled.div`
  position: sticky;
  top: 80px;
  width: 100%;
  max-width: 610px;
  margin-right: 30px;
  @media ${devices.laptop} {
    top: 0;
    max-width: 500px;
    position: relative;
  }
  @media ${devices.tablet} {
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

export const BoxProfile = styled.div`
  width: 100%;
  max-width: 600px;
  @media ${devices.tablet} {
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

export const ArtPrice = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  padding-right: 25px;
`;

export const ArtTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 34px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin-bottom: 16px;

  @media ${devices.tablet} {
    font-size: 28px;
    line-height: 30px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ArtDescription = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
`;

export const ArtStandartText = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;

export const ArtText = styled(Typography)`
  color: ${color.grey};
  ${ArtTextType}
`;
export const ArtCategory = styled.div`
  text-transform: Capitalize;
  color: ${color.secondary};
  background: ${color.brand};
  border-radius: 30px;
  padding: 4px 18px;
  position: absolute;
  left: 16px;
  top: 24px;
`;

export const ArtItem = styled.div`
  display: flex;
  align-items: center;
`;
export const ArtSignature = styled.div`
  color: ${color.brand};
  display: flex;
  align-items: center;
`;

export const EditArtBlock = styled.div`
  color: ${color.brand};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 35px;
`;
