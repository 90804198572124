
import {Modal, Typography} from "antd";
import styled from "styled-components";
import {color, devices} from "../../../../Theme";


export const CustomModal = styled(Modal)`
  
  .ant-modal-body {
    padding: 24px;
    @media ${devices.mobileL} {
      padding: 15px 10px;
    }
  }
  
`;

export const ModalBox = styled.div`
  padding: 24px 5px 16px;
  height: 420px;
  @media ${devices.tablet} {
    height: 520px;
  }
  @media ${devices.mobileL} {
    padding: 10px 0 5px;
  }
`;

export const ModalTitleWrap = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media ${devices.mobileL} {
    padding-bottom: 10px;
  }
`;

export const ModalTitle = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding-right: 15px;
  @media ${devices.tablet} {
    font-size: 20px;
    line-height: 22px;
  }
  @media ${devices.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const CloseModalIconWrap = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const ReviewWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  @media ${devices.tablet} {
    flex-wrap:wrap;
  }
`;

export const ReviewList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 530px;
  height: 330px;
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
  @media ${devices.tablet} {
    height: 250px;
    max-height: 250px;
  }
  
`;

export const ReviewListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;
  height: fit-content;
  @media ${devices.mobileL} {
    padding-top: 10px;
  }
`;

export const ReviewListAvatar = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background: #BDBDBD;
  border-radius: 64px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
`;
export const ReviewListItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 8px;
`;

export const ReviewListItemMoreInfo = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 8px;
`;

export const ReviewerName = styled(Typography)`
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const ReviewerDate = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const ReviewerText = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  hyphens: auto;
  word-wrap: break-word;
  overflow-x: hidden;
`;

export const ReviewStatistic = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
`;


export const ReviewStatisticText = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
`;

export const ReviewStatisticWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
`;

export const ReviewStatisticItem = styled.div`
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    margin-right: 15px;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  width: 96px;
  height: 4px;
  margin: 0 13px 3px 17px;
  background: ${color.grey};
  @media ${devices.mobileL} {
    width: 40px;
    margin: 0 10px 3px 13px;
  }
`;
export const ProgressBarPercents = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.87);
  max-width: 96px;
  height: 4px;
  @media ${devices.mobileL} {
    max-width: 40px;
  }
`;

export const ReviewStatisticCount = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
`;
