export const color = {
    primary: '#1976D2',
    primaryLight: 'rgba(25, 118, 210, 0.5)',
    primaryLight4p: 'rgba(25, 118, 210, 0.04)',
    primaryLight50p: 'rgba(25, 118, 210, 0.5)',
    secondary: '#FFFFFF',
    dark: 'rgba(0, 0, 0, 0.87)',
    violet: '#5e27ae',
    lightGrey: '#F2F3F4',
    grey: '#9E9E9E',
    green: '#0B8235',
    brand: '#1976D2',
    cardBg: '#FAFAFA'
};

export const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobile: '475px',
    mobileL: '625px',
    tabletS: '768px',
    tablet: '1024px',
    laptop: '1200px',
    laptopL: '1440px',
    desktop: '2560px'
};

export const devices = {
    mobileS: `(max-width: ${sizes.mobileS})`,
    mobileM: `(max-width: ${sizes.mobileM})`,
    mobile: `(max-width: ${sizes.mobile})`,
    mobileL: `(max-width: ${sizes.mobileL})`,
    tabletS: `(max-width: ${sizes.tabletS})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    laptopL: `(max-width: ${sizes.laptopL})`,
    desktop: `(max-width: ${sizes.desktop})`
};
