import React from 'react';

export const KeyboardDownArrowRed = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 10.8332L14.325 9.65816L10.5 13.4748L6.675 9.65816L5.5 10.8332L10.5 15.8332L15.5 10.8332Z"
        fill="#ED6C02"
      />
      <path
        d="M15.5 5.34163L14.325 4.16663L10.5 7.98329L6.675 4.16663L5.5 5.34163L10.5 10.3416L15.5 5.34163Z"
        fill="#ED6C02"
      />
    </svg>
  );
};
