import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { color } from '../../Theme';

export const SwiperCustom = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    color: ${color.dark};

    :after {
      font-size: 17px!important;
    }
  }
`;
export const CarouselSlide = styled(SwiperSlide)`
  height: 489px;
  border-radius: 10px !important;
  overflow: hidden;

  img {
    height: 489px;
    border-radius: 10px !important;
    overflow: hidden;
  }
`;

export const CarouselSlideSmall = styled(SwiperSlide)`
  width: 25%;
  height: 100%;
  opacity: 0.8;
  border-radius: 10px !important;
  overflow: hidden;
  border: 2px solid #fff;
  
  &.swiper-slide-thumb-active {
    opacity: 0.8;
    border: 2px solid ${color.brand};
  }

  img {
    height: 90px;
    border-radius: 10px !important;
    overflow: hidden;
  }
`;

export const RemoveSlide = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(25, 118, 210, 0.3);
  width: 44px;
  height: 44px;
  border-radius: 50%;

  .anticon-delete {
    font-size: 20px;
    color: ${color.dark};
  }
`;
