import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import userReducer from '../slices/userSlice';
import {artsAPI} from '../query/ArtsAPI';
import {userAPI} from '../query/user/userAPI';
import {notificationAPI} from '../query/notifications/notificationApi';
import {analyticsAPI} from '../analytics/analyticsAPI';
import {reviewsAPI} from "../reviews/reviewsAPI";
import {ownershipAPI} from "../ownership/ownershipAPI";
import {chatsAPI} from "../query/chats/chatsAPI";

export const store = configureStore({
    reducer: {
        user: userReducer,
        [artsAPI.reducerPath]: artsAPI.reducer,
        [userAPI.reducerPath]: userAPI.reducer,
        [notificationAPI.reducerPath]: notificationAPI.reducer,
        [analyticsAPI.reducerPath]: analyticsAPI.reducer,
        [reviewsAPI.reducerPath]: reviewsAPI.reducer,
        [ownershipAPI.reducerPath]: ownershipAPI.reducer,
        [chatsAPI.reducerPath]: chatsAPI.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            artsAPI.middleware,
            userAPI.middleware,
            notificationAPI.middleware,
            analyticsAPI.middleware,
            reviewsAPI.middleware,
            ownershipAPI.middleware,
            chatsAPI.middleware
        )
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
