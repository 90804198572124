import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { InputCustom } from '../Components';
import { InputPassword } from '../Components/Input/styled';

export const signInConfig = [
  {
    name: 'email',
    rules: [
      {
        required: true,
        message: 'Please Input your Email!'
      }
      // {
      //     pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
      //     message: 'Invalid email'
      // },
    ],
    placeholder: 'Email',
    prefix: <UserOutlined />,
    Component: InputCustom,
    size: 'md'
  },
  {
    name: 'password',
    rules: [{ required: true, message: 'Please Input your Password!' }],
    placeholder: 'Password',
    type: 'password',
    prefix: <KeyOutlined />,
    Component: InputPassword
  }
];
