import {devices} from "../../../Theme";
import styled from "styled-components";
import {ChatOutlinedIcon} from "../../../Icons/ChatOutlinedIcon";
import {Typography} from "antd";


export const NotificationBox = styled.div`
  min-width: 400px;
  display: none;
  position: fixed;
  padding: 16px;
  right: 40px;
  top: 64px;
  border-radius: 4px;
  z-index: 99;
  background: #FFFFFF;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  &.is-active {
    display: block;
    //overflow: scroll;
    min-height: 20%;
    max-height: 80%;
  }

  @media ${devices.tabletS} {
    min-width: 320px;
    padding: 15px 10px;
    right: 12px;
    top: 86px;
  }
  @media ${devices.mobileL} {
    width: 320px;
    padding: 10px 8px;
    right: 12px;
    top: 66px;
  }
`;

export const NotificationButton = styled(ChatOutlinedIcon)`
  cursor: pointer;
  min-width: 35px;
  width: 35px;
  @media ${devices.laptop} {
    min-width: 28px;
    width: 28px;
  }
  @media ${devices.mobile} {
    min-width: 23px;
    width: 23px;
  }
`;

export const List = styled.div`
  color: rgba(0, 0, 0, 0.87);
  //overflow: hidden;
  width: 100%;
`

export const Time = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
`

export const MessageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const MessageItem = styled.div`
  cursor: pointer;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: relative;

  @media ${devices.mobileL} {
    padding: 12px 5px;
    max-width: 300px;
  }

  &.sent {
    background: red;
    color: coral;
  }

  &.is-not-read {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const MessageDescNot = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
