import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { color, devices } from '../../../Theme';
import { Space, Typography } from 'antd';
import { StyledButtonSection } from '../../../Components/Button/styled';

export const FooterRow = styled.nav`
  display: flex;
  flex-wrap: wrap;
`;

export const FooterContent = styled.div`
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  background-color: black;
  z-index: 3;
  margin-top: 20px;
`;

export const CopyRight = styled(Typography)`
  color: white;
  margin-left: 75px;
  margin-right: 50px;
  @media ${devices.laptop} {
    margin-left: 35px;
  }

  @media ${devices.tablet} {
    flex-basis: 100%;
    align-self: center;
    margin-bottom: 20px;
  }
`;

export const FooterLink = styled(Typography)`
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  /* padding: 5px 10px; */
  margin-left: 30px;
  font-size: 14px;

  @media ${devices.tablet} {
    flex-basis: 100%;
    align-self: center;
  }
`;

export const FooterLinkList = styled.nav`
  display: flex;
  flex-direction: row;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    align-self: center;
  }
`;
