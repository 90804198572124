import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.provenart.io/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.accessToken;
      console.log(token);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      localStorage.setItem('userToken', JSON.stringify(token));
      return headers;
    }
  }),
  tagTypes: ['Arts'],
  endpoints: (build) => ({
    setUser: build.mutation({
      query: (data) => ({
        url: 'sign_in',
        method: 'POST',
        body: { user: data }
      })
    }),
    registerUser: build.mutation({
      query: (data) => ({
        url: 'sign_up',
        method: 'POST',
        body: { user: data }
      })
    })
  })
});

export const { useSetUserMutation, useRegisterUserMutation } = userAPI;
