import React, { FC } from 'react';
import { AuthStyled } from './styled';
import { ILoginTyped } from './typed';
import { FormWrapper } from '../../Form/styled';

export const AuthLayout: FC<ILoginTyped> = ({ children }) => {
  return (
    <AuthStyled>
      <FormWrapper>{children}</FormWrapper>
    </AuthStyled>
  );
};
