import {Typography} from "antd";
import {color} from "../../../Theme";
import styled from "styled-components";


export const UploadTitle = styled(Typography)`
  color: ${color.primary};
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin: 8px auto 0;
  text-decoration: underline;
  width: 100%;
`;

export const UploadText = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.17px;
  margin: 8px auto 0;
  width: 100%;
  
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const AddNewImageWrap = styled.div`
  width: 100%;
  max-width: 610px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const AddNewImageTitle = styled(Typography)`
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 16px;
  margin-right: 10px;
`;

export const AddNewImageBlock = styled.div`
  width: fit-content;
  display: flex;
`;
