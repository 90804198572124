import React from 'react';

export const CreateArtConfig = {
  title: {
    details: {
      label: 'Title',
      name: 'title',
      rules: [{ required: true, message: 'Please Input your title!' }]
    },
    field: {
      name: 'email',
      placeholder: 'Type a title of your art',
      allowClear: true
    }
  },
  series_number: {
    name: 'series',
    placeholder: 'Type series number',
    allowClear: true
  },
  price: {
    name: 'Price',
    // rules: [{required: true, message: 'Please type your price!'}],
    placeholder: 'Price',
    allowClear: true
  },
  edition_number: {
    name: 'Edition number',
    placeholder: 'e.g edition number',
    allowClear: true
  },
  height: {
    name: 'Height',
    placeholder: 'Choose height',
    allowClear: true
  },
  weight: {
    name: 'Weight',
    placeholder: 'Choose weight',
    allowClear: true
  },
  conditions: {
    name: 'Conditions',
    placeholder: 'Decribe a condition of your art',
    allowClear: true,
    rows: 2,
    autoSize: { minRows: 3, maxRows: 5 }
  },
  year: {
    name: 'Year of creation',
    placeholder: 'Choose year',
    allowClear: true
  },
  city: {
    name: 'city',
    placeholder: 'City/Country',
    allowClear: true
  },
  provenance: {
    name: 'Provenance',
    placeholder: 'e.g provenance',
    allowClear: true
  },
  period: {
    name: 'Period',
    placeholder: 'Type period',
    allowClear: true
  },
  medium_other: {
    name: 'Other',
    placeholder: 'Decribe a condition of your art'
  },
  decription: {
    name: 'Art decription',
    placeholder: 'Decribe a condition of your art',
    allowClear: true
  },
  private_notes: {
    name: 'Private notes',
    placeholder: 'Decribe a condition of your art',
    allowClear: true
  },
  username: {
    name: 'Username'
  }
};

export const CreateArtFormItemConfig = {
  images: {
    rules: [{ required: true, message: 'Please upload at least one image!' }]
  },
  title: {
    label: 'Title',
    name: 'title',
    rules: [{ required: true, message: 'Please Input your title!' }]
  },
  classification: {
    name: 'classification',
    label: 'Classification',
    rules: [{ required: true, message: 'Please check classification!' }]
  },
  height: {
    label: 'Height',
    name: 'height'
  },
  weight: {
    label: 'Weight',
    name: 'weight'
  },
  condition: {
    label: 'Condition',
    name: 'condition'
  },
  price: {
    label: 'Price',
    name: 'price'
  },
  edition_number: {
    label: 'Edition number',
    name: 'edition_number'
  },
  year: {
    label: 'Year',
    name: 'year'
  },
  city: {
    label: 'Choose country or city',
    name: 'city'
  },
  provenance: {
    label: 'Provenance',
    name: 'provenance'
  },
  period: {
    label: 'Period',
    name: 'period'
  }
};
