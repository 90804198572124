import React from 'react';

export const EmptyGalleryIcon = (props) => {
  return (
    <svg
      width="208"
      height="223"
      viewBox="0 0 208 223"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="208" height="223" rx="10" fill="#F3F3F3" />
      <path
        d="M95.7035 125.037H111.703C113.118 125.037 114.474 124.475 115.475 123.475C116.475 122.474 117.037 121.118 117.037 119.703V103.703C117.037 102.289 116.475 100.932 115.475 99.9322C114.474 98.932 113.118 98.3701 111.703 98.3701H95.7035C94.289 98.3701 92.9324 98.932 91.9322 99.9322C90.932 100.932 90.3701 102.289 90.3701 103.703V119.703C90.3701 121.118 90.932 122.474 91.9322 123.475C92.9324 124.475 94.289 125.037 95.7035 125.037ZM95.7035 125.037L107.071 112.531C107.321 112.257 107.625 112.038 107.963 111.888C108.302 111.737 108.668 111.659 109.039 111.659C109.41 111.658 109.776 111.734 110.116 111.883C110.455 112.032 110.76 112.25 111.01 112.523L116.383 118.383C116.803 118.843 117.037 119.442 117.037 120.063C117.037 121.382 116.513 122.647 115.58 123.58C114.647 124.513 113.382 125.037 112.063 125.037L95.7035 125.037ZM102.37 107.037C102.37 108.878 100.878 110.37 99.0368 110.37C97.1958 110.37 95.7035 108.878 95.7035 107.037C95.7035 105.196 97.1958 103.703 99.0368 103.703C100.878 103.703 102.37 105.196 102.37 107.037Z"
        stroke="#ABA9AB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
