import styled from 'styled-components';
import { Modal, Space, Typography } from 'antd';
import Box from '../../Box';
import Button from '../../Button';
import { color } from '../../../Theme';

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
`;

export const Modaltitle = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 18px;
`;
export const ModalDesc = styled(Typography)`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
`;

export const ModalBox = styled(Box)`
  margin-bottom: 32px;
  max-width: 350px;
`;
export const ModalButtonGroup = styled.div`
  margin: 25px auto 0;
  display: flex;
  max-width: 220px;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonCancel = styled(Button)`
  background: transparent;
  border: 1px solid ${color.brand};
  text-transform: uppercase;
  color: ${color.brand};
`;

export const ButtonSubmit = styled(Button)`
  background: ${color.brand};
  //border: 1px solid ${color.brand};
  text-transform: uppercase;
  color: #fff;
`;
