import React, { useEffect, useState } from 'react';
import { Layout } from '../../../../Components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetArtsByIdQuery } from '../../../../Redux/query/ArtsAPI';
import Button from '../../../../Components/Button';
import { useAppSelector } from '../../../../Hooks';
import { Space, Spin } from 'antd';
import { Helmet } from 'react-helmet';

import {
  ArtDescription,
  ArtItem,
  ArtMainContainer,
  ArtMedium,
  ArtPrice,
  ArtSignature,
  ArtStandartText,
  ArtSticky,
  ArtText,
  ArtTitle,
  BoxProfile,
  EditArtBlock,
  IconButtonBox,
  WrapInfo,
  WrapRowInfo,
  WrapRowStart
} from './styled';
import { CarouselSlide, SwiperCustom } from '../../../../Components/Carousel/styled';
import { Navigation } from 'swiper';
import EditIcon from '../../../../Icons/EditIcon';
import { DeleteDeactivateMenu } from '../../../../Components/Gallery/Art/DeleteDeactivateMenu';

import CheckIcon from '../../../../Icons/CheckIcon';
import { Reviews } from '../../../../Components/Gallery/Art/Reviews';
import Chat from '../../../../Components/Chat';
import { Art } from './typed';
import { useGetArtDocuments } from '../../hooks';
import { DocView } from '../../../../Components/Gallery/Art/DocView';
import { useCreateChatMutation} from '../../../../Redux/query/chats/chatsAPI';
import { TransferBuy } from '../../../../Components/Gallery/Art/TransferBuy';
import { ArtInfoDetails } from '../../../../Components/Gallery/Art/ArtInfoDetails';

export const SingleArt = () => {
  const params = useParams();

  const { data, isFetching, refetch, isLoading } = useGetArtsByIdQuery({
    id: params.id
  });

  useEffect(() => {
    refetch();
  }, []);

  // console.log(data?.data)
  const artData: Art = data?.data.attributes;

  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any[]>([]);
  const { dataDocuments, refetchDocuments, isFetchingDocuments, isLoadingDocuments } =
    useGetArtDocuments(data?.data?.id);
  const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const refetchDoc = queryParams.get('refetchDoc');

  useEffect(() => {
    if (refetchDoc) {
      setTimeout(() => {
        refetchDocuments();
      }, 1200);
    }
  }, [refetchDoc]);

  const { userInfo } = useAppSelector((state) => state.user);
  const date = new Date(data && data.data.attributes?.createdAt);
  const time = date.toLocaleDateString();

  useEffect(() => {
    if (dataDocuments?.length && !isFetchingDocuments && !isLoadingDocuments) {
      const documentsToAdd = dataDocuments?.map((doc, i) => (
        <Space size={[24, 24]} direction={'vertical'} key={i}>
          {!!doc?.attributes?.url && <DocView doc={doc.attributes} />}
        </Space>
      ));
      // console.log('documentsToAdd',documentsToAdd);
      setDocuments([...documentsToAdd]);
    }
  }, [dataDocuments, isFetchingDocuments, isLoadingDocuments]);

  const [createChat, { data: data2, isSuccess, isLoading: isLoadingChat }] =
    useCreateChatMutation();

  const [externalID, setExternalID] = useState(data2?.data?.attributes?.externalId);
  const ownerID = artData?.ownerId;
  const ownershipHistoryID = artData?.ownershipHistoryId;
  const handleOpenMessenger = () => {
    if (userInfo) {
      setIsChatVisible(!isChatVisible);
      if (!isChatVisible) {
        createChat({
          ownership_history_id: ownershipHistoryID,
          user2_id: ownerID,
          title: 'chat'
        });
      }
    } else {
      navigate('/registration');
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setExternalID(data2?.data?.attributes?.externalId);
    }
    console.log(externalID, '2');
  }, [isSuccess]);
  
  return (
    <>
    <Helmet> 
        <title>Provenart</title> 
        <meta name="description" content="Provenart" /> 
        <meta property="og:title" content={artData?.title && artData?.artistName ? `${artData?.title} | ${artData?.artistName} on Provenart` : "Provenart"} /> 
        <meta property="og:image" content={data?.data.attributes.images.data[0].attributes.url} /> 
      </Helmet> 
    <Layout type={'art'}>
      {(isFetching || isLoading) && <Spin size="large" />}
      {data?.data && (
     
   
        <ArtMainContainer>
          <ArtSticky>
            <ArtText type={'label'}>
              {artData?.seriesNumber ? <>Series: artData?.seriesNumber</> : ''}
            </ArtText>
            <SwiperCustom
              navigation={true}
              modules={[Navigation]}
              spaceBetween={50}
              slidesPerView={1}>
              {data.data.attributes.images.data.map((img, i) => (
                <CarouselSlide key={i} className="swiper-slide">
                  <img
                    src={img.attributes.url}
                    // loading={'lazy'}
                    style={{ width: '100%', objectFit: 'contain' }}
                    alt={img.attributes.filename}
                  />
                </CarouselSlide>
              ))}
            </SwiperCustom>
          </ArtSticky>
          <BoxProfile>
            {artData?.ownerId == userInfo?.data?.id && (
              <EditArtBlock>
                <IconButtonBox onClick={() => navigate(`/editArt/${data?.data?.attributes.slug}`)}>
                  <EditIcon />
                </IconButtonBox>
                <DeleteDeactivateMenu
                  isHidden={artData?.isHidden}
                  artId={data?.data?.id}
                  title={artData?.title}
                />
              </EditArtBlock>
            )}
            <WrapRowInfo>
              <ArtMedium>{artData?.medium?.replace(/_/g, '-')}</ArtMedium>
              {artData?.sellingPrice && (
                <ArtPrice>{`$${artData.sellingPrice.toLocaleString()}`}</ArtPrice>
              )}
            </WrapRowInfo>
            <WrapRowStart>
              <ArtStandartText>
                {artData?.artistName} - {time}{' '}
              </ArtStandartText>
            </WrapRowStart>

            <WrapInfo>
              <ArtTitle>{artData?.title}</ArtTitle>
              {artData?.description && <ArtDescription>{artData?.description}</ArtDescription>}
              <TransferBuy data={data} refetch={refetch} />
              {artData?.availableForChat && artData?.ownerId != userInfo?.data?.id && (
                <Button primary={'true'} onClick={handleOpenMessenger}>
                  Send Message
                </Button>
              )}
            </WrapInfo>
            <ArtInfoDetails ardData={artData} />
            {artData?.ownerId == userInfo?.data?.id && artData?.notes?.length > 0 && (
              <WrapInfo>
                <ArtText type={'name'}>Private notes</ArtText>
                <ArtText type={'description'}>{artData?.notes}</ArtText>
              </WrapInfo>
            )}
            {artData?.medium == 'other' && (
              <WrapInfo>
                <ArtText type={'name'}>Medium info</ArtText>
                <ArtText type={'description'}>{artData?.mediumOtherValue}</ArtText>
              </WrapInfo>
            )}
            <WrapInfo size={[24, 24]} direction={'vertical'}>
              {documents?.length > 0 && !isFetchingDocuments && !isLoadingDocuments && (
                <>
                  <ArtText type={'name'}>Documents</ArtText>
                  <ArtItem>
                    {artData?.signature && (
                      <ArtSignature>
                        <CheckIcon />
                        Signature
                      </ArtSignature>
                    )}
                  </ArtItem>
                  {documents}
                </>
              )}
            </WrapInfo>
            <Reviews data={data} />
          </BoxProfile>
          <Chat
            externalID={externalID}
            visible={isChatVisible}
            isLoading={isLoadingChat}
            closePopup={handleOpenMessenger}
            ownerID={ownerID}
          />
        </ArtMainContainer>
    
      )}
    </Layout>
    </>
  );
};
