import React, { useEffect, useState } from 'react';
import {
  DropdownItem,
  DropdownLink,
  DropdownMenu,
  Icon,
  List,
  Menu,
  MenuLabel,
  NavBar,
  Navigation,
  NavLogo,
  NavRoutes,
  UserEmail,
  DropDown,
  NavigationButtons,
  SignUpBtn,
  LogInBtn
} from './styled';
import { Dropdown, message } from 'antd';
import Logo from '../../Icons/Logo';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { getUserDetails } from '../../Redux/features';
import { RoutePrivate } from '../../Routes';
import { logout } from '../../Redux/slices';

import { ArrowDownIcon, LinesIcon, ProfileIcon, SupportOutlinedIcon } from '../../Icons';
import { SupportAgentOutlinedIcon } from '../../Icons/SupportAgentOutlined';
import { PlusLeftIcon } from '../../Icons/PlusLeftIcon';
import { Notification } from '../Notification';
import { ChatNotify } from '../Chat/Notifications';

export const LandingPageHeader = () => {
  const [click, setClick] = useState(false);
  // indicates what's been clicked on
  const handleClick = () => setClick(!click);
  // user info
  const { userInfo, accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // if (accessToken) {
    // get the user information when the access token is changed
    dispatch(getUserDetails());
    // }
  }, [accessToken, dispatch]);

  const menu = (
    <DropdownMenu>
      {/* if user sign in, show name + email TODO change this in Header */}
      <DropdownItem>
        <DropdownLink to={'/registration'}>Sign up</DropdownLink>
        <DropdownLink to={'/login'}>Log in</DropdownLink>
      </DropdownItem>

      <DropdownItem>
        <DropdownLink to={'/help'}>
          <SupportAgentOutlinedIcon /> Help
        </DropdownLink>
        <DropdownLink to={'/privacy'}>
          <SupportOutlinedIcon />
          Privacy Policy
        </DropdownLink>
      </DropdownItem>
    </DropdownMenu>
  );
  return (
    <NavBar>
      {/* make this dependent on what page we're at!! if landing, home, else gallery */}
      {userInfo ? (
        <NavLogo onClick={() => navigate('/gallery')}>
          {/* website logo (Provenart) */}
          <Logo />
        </NavLogo>
      ) : (
        <NavLogo onClick={() => navigate('/home')}>
          {/* website logo (Provenart) */}
          <Logo />
        </NavLogo>
      )}
      <Menu>
        <NavRoutes>
          <RoutePrivate />
        </NavRoutes>
      </Menu>
      <Menu>
        <Navigation clicked={click}>
          <List>
            {/* TODO change routing for Gallery dependent on sign in or not */}
            <RoutePrivate />
          </List>
        </Navigation>
        <LogInBtn
          primary="true"
          // icon={<PlusLeftIcon />}
          onClick={() => navigate('/login')}>
          Login
        </LogInBtn>
        <SignUpBtn
          primary="true"
          // icon={<PlusLeftIcon />}
          onClick={() => navigate('/registration')}>
          Sign Up
        </SignUpBtn>
      </Menu>
    </NavBar>
  );
};
