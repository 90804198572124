import { color } from '../../../Theme';
import styled from 'styled-components';
import { FC } from 'react';
import { Row, RowProps, Typography } from 'antd';

export const DocumentBox = styled.div`
  margin-bottom: 24px;
  border-top: 1px solid ${color.dark};
  border-bottom: 1px solid ${color.dark};
  padding: 22px 0 16px;
`;
export const DeleteDocumentBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DeleteDocumentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const RedactTitle = styled(Typography)`
  font-size: 15px;
  color: #1976D2;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const RedactedTitle = styled(Typography)`
  font-size: 15px;
  color: #1976D2;
  letter-spacing: 0.46px;
  text-transform: uppercase;
`;

export const FormInputRow: FC<RowProps> = styled(Row)`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CategoryTitle = styled(Typography.Paragraph)`
  margin-bottom: 5px;
  font-size: 16px;
`;
