import React from 'react';

export const ChatOutlinedIcon = (props) => {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.83464 5.83366H29.168V23.3337H7.54089L5.83464 25.0399V5.83366ZM5.83464 2.91699C4.23047 2.91699 2.93255 4.22949 2.93255 5.83366L2.91797 32.0837L8.7513 26.2503H29.168C30.7721 26.2503 32.0846 24.9378 32.0846 23.3337V5.83366C32.0846 4.22949 30.7721 2.91699 29.168 2.91699H5.83464ZM8.7513 17.5003H20.418V20.417H8.7513V17.5003ZM8.7513 13.1253H26.2513V16.042H8.7513V13.1253ZM8.7513 8.75033H26.2513V11.667H8.7513V8.75033Z"
                fill="#1976D2"/>
        </svg>
    );
};
