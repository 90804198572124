import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ArrowDownOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Input, Upload, UploadFile} from 'antd';
import type {UploadProps} from 'antd/es/upload/interface';
import {StyledInput, StyledInputNew} from '../../Input/styled';
import {FormField, FormFieldNew} from '../../Form/styled';
import {uuid} from '../../../Utils/RandomID';
import {DocType} from '../../../Pages/art/View/SingleArt/typed';
import {acceptedFiles, getBase64} from '../../../Utils';
import {CategoryTitle, DeleteDocumentBox, DeleteDocumentButton, DocumentBox, FormInputRow} from './styled';
import DeleteIcon from '../../../Icons/DeleteIcon';
import {EditImageModal} from "../EditImageModal";
import {RcFile} from 'antd/lib/upload/interface';
import {CustomTextarea} from "../../Form/FormTextarea/styled";
import DeleteDocumentIcon from "../../../Icons/DeleteDocumentIcon";

interface IDoc {
  object: DocType;
  setObject: Dispatch<SetStateAction<DocType>>;
  edit?: boolean;
  addingDocument?: boolean;
  setAddingDocument?: any;
}

export const UploadDoc: React.FC<IDoc> = ({
  object,
  setObject,
  edit = false,
  addingDocument,
  setAddingDocument
}) => {
  const [docStringArr,setDocStringArr] = useState<string[]>([]);

  const { TextArea } = Input;

  const handleChangeDesc = (id, event) => {
    setObject((prev) => {
      const newArray = [...prev];
      newArray.forEach((el) => {
        if (el.id === id) {
          el.description = event.target.value;
        }
      });
      return newArray;
    });
  };
  const handleChangeTitle = (id, event) => {
    setObject((prev) => {
      const newArray = [...prev];
      newArray.forEach((el) => {
        if (el.id === id) {
          el.title = event.target.value;
        }
      });
      return newArray;
    });
  };

  const handleUpload = (e, id) => {
    setObject((prev) => {
      const newArray = [...prev];
      newArray.forEach((el) => {
        if (el.id === id) {
          el.file = e.file;
          el.fileName = e.file.name;
          getBase64(el.file)
            .then((result) => {
              // console.log('result',result);
              el.fileString = result as string;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
      return newArray;
    });
  };

  useEffect(() => {
    if (object.length < 8 && addingDocument) {
      setObject((prev) => [
        ...prev,
        { id: uuid(), title: '', description: '', file: null, fileString: '', url: '', artId: '', fileName: '', fileList: [] }
      ]);
      setAddingDocument(false);
    }
  }, [addingDocument]);


  const handleRemoveFile = (file, index: number) => {
    setObject(prev=> {
      const newArr = prev;
      newArr[index].file = null;
      newArr[index].fileName = '';
      newArr[index].fileList = [];
      return [...newArr];
    });
    const removeArr = docStringArr;
    removeArr.splice(index, 1);
    setDocStringArr([...removeArr]);
  }

  const handleBeforeUpload = (file, fileList, item,index) => {
    console.log('Custom parameter:', item, file);
    const splitString = file.type.split('/');

      setObject(prev=> {
        const newArr = prev;
        if(splitString[0] == 'image'){
          newArr[index].contentType = 'image';
        getBase64(file)
            .then((result) => {
              newArr[index].fileString = result as string;
              setDocStringArr((prev) => [...prev, result as string]);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        newArr[index].file = new File([file], file.name, {type: file.type});
        newArr[index].fileName = file.name;
        newArr[index].fileList = fileList;
        return [...newArr];
      })

    return false;
  };

  const deleteDoc = (index: number) => {
    const removeArr = object;
    removeArr.splice(index, 1);
    setObject([...removeArr]);
  };

  useEffect(()=>{
    console.log('object',object);
  },[object]);

  const handleUpdateName = (index:number, newName: string, fileList ) => {
    const file = fileList[0];
     const fileContent = file.originFileObj as Blob;
    const newFile = new File([fileContent], newName, { type: file.type }) as RcFile;
    // newFile.lastModifiedDate = file.lastModifiedDate as any;
    newFile.uid = file.uid as any;

// Update the corresponding UploadFile object
    const updatedFile = {
      ...fileList[index],
      name: newName,
      originFileObj: newFile,
    };
// Update the fileList state
    const updatedFileList = [updatedFile];

    // console.log('updatedFileList',updatedFileList);

    setObject(prev=> {
      const newArr = prev;
      newArr[index].file = new File([newArr[index].file], newName, {type: newArr[index].file.type});
      newArr[index].fileName = newName;
      newArr[index].fileList = updatedFileList;
      return [...newArr];
    })

  };

  return (
    <div style={{ marginBottom: '24px', marginTop: '20px' }}>
      <FormField>
        <CategoryTitle>Documents</CategoryTitle>
      </FormField>
      {object.map((item, index) => (
        <DocumentBox key={item.id} style={{}}>
          <FormInputRow>
            <Col flex={1}>
              <FormFieldNew label="Document title">
                <StyledInputNew
                  allowClear={true}
                  onChange={(e) => handleChangeTitle(item.id, e)}
                  value={item.title}
                  placeholder='Title of your document'
                />
              </FormFieldNew>
            </Col>
            {(index != 0 || edit) && (
              <Col flex={1}>
                <DeleteDocumentBox>
                  <DeleteDocumentButton onClick={() => deleteDoc(index)}>
                    <DeleteDocumentIcon />
                  </DeleteDocumentButton>
                </DeleteDocumentBox>
              </Col>
            )}
          </FormInputRow>
          <FormFieldNew label="Document description">
            <CustomTextarea
              onChange={(e) => handleChangeDesc(item.id, e)}
              rows={2}
              value={item.description}
              placeholder={'Describe the uploaded document'}
              autoSize={{ minRows: 2, maxRows: 2 }}
            />
          </FormFieldNew>
          {item?.url ?
            <a href={item?.url} rel="noreferrer" target="_blank">
              <ArrowDownOutlined />
              {item?.title}
            </a>
           :
              <>
                <Upload
                    fileList={item.fileList}
                    accept={acceptedFiles}
                    onRemove={(file)=> handleRemoveFile(file, index)}
                    beforeUpload={(file, fileList) => handleBeforeUpload(file, fileList, item, index)}
                    onChange={(e) => handleUpload(e, item.id)}
                    maxCount={1}
                >
                  <Button shape={'round'} icon={<UploadOutlined />}>
                    Select File
                  </Button>
                </Upload>
                {
                    item.contentType == 'image' && item.fileList?.length > 0 && docStringArr[index]?.length > 0 &&
                    <EditImageModal
                        file={docStringArr[index]}
                        index={index}
                        object={item}
                        setDocStringArr={setDocStringArr}
                        setObject={setObject}
                        changeNameOfFile={handleUpdateName}
                    />
                }
              </>
          }
        </DocumentBox>
      ))}
    </div>
  );
};
