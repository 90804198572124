import {
  ArtistBox,
  ArtistDesc,
  ArtistFooterBox,
  ArtistTag,
  ArtistTitle,
  ArtPrice,
  Card,
  GalleryCard,
  GalleryCardInfo,
  GalleryFooter,
  GalleryImg,
  GalleryItem,
  LikeCount,
  LikeIconBox
} from './styled';
import { useNavigate } from 'react-router-dom';
import React, { FC, useRef, useState } from 'react';
import moment from 'moment';
import { useLikeArtMutation } from '../../../Redux/query/ArtsAPI';
import { useAppSelector } from '../../../Hooks';

interface GalleryCardProps {
  item: any;
  favorite?: boolean;
}

export const GalleryCardItem: FC<GalleryCardProps> = ({ item }) => {
  const data = item?.attributes;
  const tag = data?.medium?.replace(/_/g, ' ');
  const { accessToken } = useAppSelector((state) => state.user);
  const [isLike, setIsLike] = useState(accessToken && data?.liked);
  const [counter, setCounter] = useState(data?.likesCount);
  const [useLike] = useLikeArtMutation();
  const redirect = useNavigate();
  const ref = useRef(null);
  const handleLike = (e) => {
    e.stopPropagation();
    if (accessToken) {
      setIsLike(!isLike);
      if (!isLike) {
        setCounter((prev) => prev + 1);
      } else {
        setCounter((prev) => prev !== 0 && prev - 1);
      }
      useLike({ like: { art_id: e.target.id } });
    } else {
      redirect('/login');
    }
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/art/${item.attributes.slug}`);
  };

  return (
    <Card onClick={handleNavigate} ref={ref} key={item?.id}>
      <GalleryCard>
        <GalleryImg
          src={data?.images.data[0]?.attributes.url}
          alt={data?.images.data[0]?.attributes.filename}
        />
        <GalleryCardInfo>
          <GalleryItem direction={'vertical'} size={[8, 8]}>
            <ArtistBox>
              {/* {data?.artistName} • {moment(data?.createdAt).format('D MMM YYYY')} */}
              {data?.artistName}
            </ArtistBox>
            <ArtistTitle>{data?.title}</ArtistTitle>
            {data?.description && (
              <ArtistDesc>
                {data.description.length < 130
                  ? data.description
                  : data?.description.slice(0, 130) + '...'}
              </ArtistDesc>
            )}
          </GalleryItem>
          <GalleryFooter>
            {data?.sellingPrice && <ArtPrice>{'$' + data?.sellingPrice.toLocaleString()}</ArtPrice>}
            <ArtistFooterBox>
              <ArtistTag>{tag}</ArtistTag>
              <ArtistFooterBox>
                <LikeCount>{counter}</LikeCount>
                <LikeIconBox id={item?.id} onClick={handleLike} isLiked={isLike} />
              </ArtistFooterBox>
            </ArtistFooterBox>
          </GalleryFooter>
        </GalleryCardInfo>
      </GalleryCard>
    </Card>
  );
};