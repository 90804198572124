import styled from 'styled-components';
import {color, devices} from '../../../../Theme';
import {Modal, Space, Typography} from 'antd';
import Button from '../../../Button';

export const DocumentBox = styled.div`
  margin-bottom: 24px;
  border-top: 1px solid ${color.dark};
  border-bottom: 1px solid ${color.dark};
  padding: 14px 0;
`;

export const DocumentWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const IconButtonBox = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
`;

export const ModalDocumentBox = styled.div`
  margin-bottom: 20px;
  padding: 20px 15px;
  @media ${devices.tablet} {
    padding: 15px 8px;
  }
`;

export const CustomModal = styled(Modal)`
  @media ${devices.tablet} {
   padding: 20px;
  }
  @media ${devices.mobileL} {
    padding: 10px;
  }
`;

export const ModalEditTitle = styled(Typography)`
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  @media ${devices.tablet} {
    font-weight: 500;
    font-size: 18px;
  }
`;

export const ModalButtonGroup = styled(Space)`
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ButtonCancel = styled(Button)`
  background: transparent;
  border: transparent;
  color: ${color.grey};
  margin-left: 10px;
`;
