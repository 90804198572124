import styled from 'styled-components';
import { Modal, Space, Typography } from 'antd';
import Box from '../../Box';
import Button from '../../Button';
import {color, devices} from '../../../Theme';

export const CustomModal = styled(Modal)`
  @media ${devices.tablet} {
    padding: 10px;
  }
  .ant-modal-body {
    @media ${devices.mobile} {
      padding: 15px 10px;
    }
  }
  .ant-modal-content {
    border-radius: 10px;
  }
`;

export const Modaltitle = styled(Typography)`
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  @media ${devices.tablet} {
    font-size: 20px;
  }
`;
export const ModalDesc = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.15px;
  margin: 0 auto 15px;
  @media ${devices.mobileL} {
    font-size: 13px;
  }
`;

export const ModalBox = styled.div`
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto 32px;
`;
export const ModalButtonGroup = styled.div`
  margin: 25px auto 0;
  display: flex;
  max-width: 260px;
  width: 100%;
  justify-content: space-between;
`;
export const ButtonCancel = styled(Button)`
  background: transparent;
  border: 1px solid ${color.brand};
  text-transform: uppercase;
  color: ${color.brand};
`;

export const ButtonSubmit = styled(Button)`
  background: ${color.brand};
  //border: 1px solid ${color.brand};
  text-transform: uppercase;
  color: #fff;
`;

export const InputsContainer = styled.div`
  margin: 0 auto;
  max-width: 320px;
  width: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ${color.grey};
`;

export const CloseModalIconWrap = styled.div`
  padding: 5px;
  cursor: pointer;
`;
