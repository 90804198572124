import React, {FC} from 'react';
import {ContentWrapper, Footer, LoaderBox, MessageBox, MessageInput, MessageWrapper, Text, Time} from './styled';
import {Box, BoxMessage, HeaderBox, UserAvatar, UserBox} from "../ChatBox/styled";
import {Input, Spin} from "antd";
import Button from "../../../../Components/Button";
import {IMessageProps} from "./typed";
import {getAuth} from "firebase/auth";
import moment from "moment";

export const Messages: FC<IMessageProps> = ({
                                                messages,
                                                name,
                                                img,
                                                onSubmit,
                                                value,
                                                refInput,
                                                onChange,
                                                onClick,
                                                isLoading
                                            }) => {
    const auth = getAuth()
    const date = messages.length && new Date(messages[messages?.length - 1]?.timestamp?.seconds * 1000 + messages[messages?.length - 1]?.timestamp?.nanoseconds / 1000000)
    return (
        <BoxMessage>
            <HeaderBox>
                <UserBox>
                    <UserAvatar
                        src={img}></UserAvatar>
                    <h1>{name}</h1>
                </UserBox>
            </HeaderBox>
            <ContentWrapper>
                <MessageWrapper>
                    <Time>{moment(date).format('Do MMM h:mm')}</Time>
                    <MessageBox>
                        {isLoading ? <LoaderBox><Spin/></LoaderBox> : null}
                        {messages &&
                            messages?.map((message) => {
                                return (
                                    <Text key={message} message={message}
                                          className={`${message?.uid === auth?.currentUser?.uid ? "sent" : "received"}`}>
                                        {message.text}
                                    </Text>
                                )
                            })}

                    </MessageBox>
                </MessageWrapper>
                <Footer>
                    <form onSubmit={onSubmit}>
                        <MessageInput size={'small'} value={value} ref={refInput}
                               placeholder={'Message...'} onChange={onChange}/>
                        <Button primary={'true'} onClick={onClick}>Send</Button>
                    </form>
                </Footer>
            </ContentWrapper>
        </BoxMessage>
    );
};
