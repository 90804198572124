import {
    InfoColName,
    InfoColValue,
    InfoDetails,
    InfoDetailsCol, InfoDetailsColFirst,
    InfoDetailsWrap,
    InfoSubTitle,
    InfoTitle
} from "./styled";
import React, {FC} from "react";
import {Art} from "../../../../Pages/art/View/SingleArt/typed";

interface ArtInfoDetailsProps {
    ardData: Art;
}

export const ArtInfoDetails:FC<ArtInfoDetailsProps> = ({ardData}) => {
    return (
        <InfoDetails>
            <InfoTitle>Additional information</InfoTitle>
            <InfoSubTitle>Details:</InfoSubTitle>
            <InfoDetailsWrap>
                <InfoDetailsColFirst>
                    {ardData.classification &&  <InfoColName>• Classification:</InfoColName>}
                    {ardData.editionNumber && <InfoColName>• Edition number:</InfoColName> }
                    {ardData.editionSize && <InfoColName>• Edition size:</InfoColName> }
                    {ardData.height &&<InfoColName>• Height:</InfoColName> }
                    {ardData.width &&  <InfoColName>• Width:</InfoColName>}
                    {ardData.year && <InfoColName>• Year of creation:</InfoColName>}
                    {ardData.city && <InfoColName>• City/Country:</InfoColName>}
                    {ardData.provenance && <InfoColName>• Provenance:</InfoColName>}
                    {ardData.period && <InfoColName>• Period:</InfoColName>}
                    {ardData.condition && <InfoColName>• Conditions:</InfoColName>}
                </InfoDetailsColFirst>
                <InfoDetailsCol>
                    {ardData.classification &&   <InfoColValue>{ardData.classification.replace(/_/g, '-')}</InfoColValue> }
                    {ardData.editionNumber &&  <InfoColValue> {ardData.editionNumber}</InfoColValue>}
                    {ardData.editionSize &&  <InfoColValue> {ardData.editionSize}</InfoColValue>}
                    {ardData.height &&  <InfoColValue>{ardData.height}</InfoColValue>}
                    {ardData.width && <InfoColValue> {ardData.width}</InfoColValue>}
                    {ardData.year &&<InfoColValue> {ardData.year}</InfoColValue>}
                    {ardData.city &&  <InfoColValue> {ardData.city }</InfoColValue>}
                    {ardData.provenance && <InfoColValue>{ardData.provenance} </InfoColValue>}
                    {ardData.period && <InfoColValue>{ardData.period}</InfoColValue>}
                    {ardData.condition && <InfoColValue>{ardData.condition}</InfoColValue>}
                </InfoDetailsCol>
            </InfoDetailsWrap>
        </InfoDetails>
    )
}
