import React, {useState} from 'react';
import Gallery from '../../../../Components/Gallery';
import {useGetFavoriteArtsQuery} from "../../../../Redux/query/ArtsAPI";
import {GallerySearchBox, Title} from "../../../../Components/Gallery/styled";
import {InputCustom} from "../../../../Components";
import {SearchOutlined} from "@ant-design/icons";
import useDebounce from "../../../../Hooks/useDebounce";
import { useAppSelector } from '../../../../Hooks';
import { useNavigate } from 'react-router-dom';

export const FavoriteArts = () => {
    const {userInfo} = useAppSelector((state) => state.user);
    const navigate = useNavigate();
    if (!userInfo){
        navigate('/registration');
    }
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(8);
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce<string>(search, 500);



    const {data, isLoading, isSuccess, isFetching} = useGetFavoriteArtsQuery(
        {
            page,
            size,
            search: debouncedSearch,
            filter: true
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            skip: false
        }
    );
    const handleSize = () => {
        setSize((prev) => prev + 8);
    };
    return (
        <>
            <GallerySearchBox>
                <Title level={4}>Total Favorites ({data?.data?.length})</Title>
                <InputCustom
                    prefix={<SearchOutlined/>}
                    placeholder={'Search...'}
                    value={search}
                    type={'search'}
                    allowClear
                    onChange={(e) => setSearch(e.target.value)}/>
            </GallerySearchBox>
            <Gallery
                favorite
                countArts
                data={data}
                isSuccess={isSuccess}
                isLoading={isLoading}
                handleSize={handleSize}
                isFetching={isFetching}/>
        </>
    );
};
