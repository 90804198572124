import React, {Dispatch, FC, useRef, useState} from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import "./styles.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import {useDeleteArtImageMutation} from "../../../Redux/query/ArtsAPI";
import {CarouselSlide, CarouselSlideSmall, RemoveSlide, SwiperCustom} from "../../Carousel/styled";
import {DeleteOutlined} from "@ant-design/icons";
import DeleteDocumentIcon from "../../../Icons/DeleteDocumentIcon";


interface ICarousel {
    list: any;
    thumbUrl?: File | Blob | MediaSource;
    setImageArr?: Dispatch<React.SetStateAction<Array<string>>>;
    setStringImageArr?: Dispatch<React.SetStateAction<Array<string>>>;
    edit?: boolean;
    artId?: string;
}

export const GalleryCarousel: FC<ICarousel> = ({
   list,
   setImageArr,
   edit,
   artId,
   setStringImageArr
}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [deleteImg] = useDeleteArtImageMutation();
    const handleRemove = (index) => {
        if (edit) {
            deleteImg({
                id: artId,
                imgId: list[index].id
            }).unwrap();
        }
        setImageArr((prev) => prev.filter((x, i) => i !== index));
        setStringImageArr((prev) => prev.filter((x, i) => i !== index));
    };


    return (
        <>
            <SwiperCustom
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {list.map((item, index) => (
                    <CarouselSlide key={index}>
                        <RemoveSlide onClick={() => handleRemove(index)}>
                            <DeleteDocumentIcon />
                        </RemoveSlide>
                        <img
                            src={
                                item?.attributes?.url ? item?.attributes?.url : URL.createObjectURL(item?.originFileObj)
                            }
                            style={{ width: '100%', objectFit: 'contain' }}
                            alt={item?.attributes?.filename}
                        />
                    </CarouselSlide>
                ))
                }
            </SwiperCustom>
            <Swiper
                style={{marginTop: '15px'}}
                onSwiper={setThumbsSwiper}
                // loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {
                    list.map((item, index) => (
                        <CarouselSlideSmall key={index}>
                            <img
                                src={
                                    item?.attributes?.url ? item?.attributes?.url : URL.createObjectURL(item?.originFileObj)
                                }
                                style={{ width: '100%', objectFit: 'contain' }}
                                alt={item?.attributes?.filename}
                            />
                        </CarouselSlideSmall>
                    ))
                }
            </Swiper>
        </>
    );
}
