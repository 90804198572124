import {
  useDeleteArtDocumentsMutation,
  useGetArtDocumentsQuery
} from '../../../Redux/query/ArtsAPI';

export const useGetArtDocuments = (id: string) => {
  // if(!id) return {};
  const { data, refetch, isLoading, isFetching } = useGetArtDocumentsQuery({ id: id });
  return {
    dataDocuments: data?.data,
    refetchDocuments: refetch,
    isLoadingDocuments: isLoading,
    isFetchingDocuments: isFetching
  };
};

export const useDeleteArtDoc = () => {
  const [deleteAtrDocument, { isSuccess, isLoading, isError }] = useDeleteArtDocumentsMutation();

  return {
    deleteAtrDocument,
    isDocDeleteSuccess: isSuccess,
    isDocDeleteLoading: isLoading,
    isDocDeleteError: isError
  };
};
