import React, { useState} from 'react';
import {useGetFinanceQuery, useGetTotalQuery} from '../../../Redux/analytics/analyticsAPI';
import {Cell} from './config';
import {TableBody, TableBox, TableFooter, TableTitle, TableValue, TableWrap} from './styled';
import {Pagination, PaginationProps} from 'antd';

export const FinanceTable = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const { data, isFetching, isLoading } = useGetFinanceQuery({
    page: page,
    size: size
  });
  const total = data?.meta?.total;
  const { data: totalValue } = useGetTotalQuery({});
  const source =
    data?.data?.length &&
    data?.data?.map(({ attributes }) => ({
      ...attributes,
      gainLoss:
        (attributes.purchasePrice <= attributes.sellingPrice ? 'up' : 'do') +
        `${attributes.gainLossDecimal}` +
        ` (${attributes.gainLossPercentage ? attributes.gainLossPercentage : '0'}%)`
    }));
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setSize(pageSize);
  };
  const changePageHandler: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };


    return (
        <TableWrap>
            <TableBox>
                <TableBody
                    dataSource={source}
                    columns={Cell}
                    showSorterTooltip={false}
                    pagination={false}
                    loading={isLoading || isFetching}
                    title={() => <TableTitle>History</TableTitle>}
                />
                <TableFooter>
                    <TableValue>Current Total Value: $ {totalValue?.total && totalValue?.total}</TableValue>
                    <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={page}
                        pageSizeOptions={[10, 30, 50]}
                        onChange={changePageHandler}
                        total={total}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                    />
                </TableFooter>
            </TableBox>
        </TableWrap>
    );
};
