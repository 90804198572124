import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { store } from './Redux/store';
import { Helmet } from 'react-helmet';
import img from './Img/provenart-logo.jpeg';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <>
    <Helmet>
      <title>Provenart</title>
      <meta name="description" content="Provenart" />
      <meta
        property="og:title"
        content="Provenart is democratizing art for all. Buy, sell, and organize your collection for free"
      />
      <meta property="og:image" content={img} />
    </Helmet>

    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </>
);
