import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  forgotPassword,
  getUserDetails,
  registerCode,
  resetPassword,
  sendVerifyCode
} from '../features';
import { ICode, ICodeForgot, INewCode } from '../../Models/IUser';

const accessToken =
  localStorage.getItem('userToken') != ('undefined' || undefined)
    ? JSON.parse(localStorage.getItem('userToken'))
    : undefined;
const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
  accessToken
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.accessToken = action.payload.data?.data?.attributes?.accessToken;
      localStorage.setItem(
        'userToken',
        JSON.stringify(state.accessToken ? state.accessToken : null)
      );
      state.error = action.payload.error?.data?.message;
    },
    registration: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.error = action.payload.error?.data?.email;
    },
    logout: (state) => {
      localStorage.removeItem('userToken');
      state = initialState;
    }
  },
  extraReducers: {
    [registerCode.pending.type]: (state) => {
      state.loading = true;
    },
    [registerCode.fulfilled.type]: (state, action: PayloadAction<ICode>) => {
      state.loading = false;
      state.success = true;
      state.userInfo = action.payload;
    },
    [registerCode.rejected.type]: (state) => {
      state.loading = false;
    },
    [getUserDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
    },
    [getUserDetails.rejected.type]: (state) => {
      state.loading = false;
    },
    [forgotPassword.pending.type]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled.type]: (state, action: PayloadAction<ICodeForgot>) => {
      state.loading = false;
      state.success = true;
      state.userInfo = action.payload;
    },
    [forgotPassword.rejected.type]: (state) => {
      state.loading = false;
      state.success = false;
    },
    [sendVerifyCode.pending.type]: (state) => {
      state.loading = true;
    },
    [sendVerifyCode.fulfilled.type]: (state, action: PayloadAction<ICodeForgot>) => {
      state.loading = false;
      state.success = true;
      state.userInfo = action.payload;
    },
    [sendVerifyCode.rejected.type]: (state) => {
      state.loading = false;
      state.success = false;
    },
    [resetPassword.pending.type]: (state) => {
      state.loading = true;
    },
    [resetPassword.fulfilled.type]: (state, action: PayloadAction<INewCode>) => {
      state.loading = false;
      state.success = true;
      state.userInfo = action.payload;
    },
    [resetPassword.rejected.type]: (state) => {
      state.loading = false;
      state.success = false;
    }
  }
});
export const { login, registration, logout } = userSlice.actions;
export default userSlice.reducer;
