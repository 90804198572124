import React from 'react';
import { Form } from 'antd';
import { InputPassword } from '../../Components/Input/styled';
import { KeyOutlined } from '@ant-design/icons';
import Button from '../../Components/Button';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../Redux/features';
import { AuthLayout } from '../../Components/Layout/Auth';
import { FormBasic } from '../../Components';

export const NewPassword = ({ user }: any) => {
  const { loading, error, success } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const submitForm = (data) => {
    const output = {
      ...data,
      email: user.email
    };
    dispatch(resetPassword(output));
    if (success) navigate('/login');
  };
  return (
    <AuthLayout>
      <FormBasic
        name="basic"
        title={'New password'}
        logo
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={submitForm}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            { min: 8, message: 'Password must be minimum 8 characters.' }
          ]}
        >
          <InputPassword size={'md'} placeholder="New password" prefix={<KeyOutlined />} />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            { min: 8, message: 'Password must be minimum 8 characters.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              }
            })
          ]}
        >
          <InputPassword size={'md'} placeholder="Confirm Password" prefix={<KeyOutlined />} />
        </Form.Item>
        <Button shape="circle" block primary="true" htmlType="submit">
          {loading ? 'loading...' : 'Confirm'}
        </Button>
      </FormBasic>
    </AuthLayout>
  );
};
