import React, { useEffect } from 'react';
import { Layout } from '../../Components';
import {
  AnalyticsBox,
  EmptyAnalyticsBox,
  EmptyAnalyticsButton,
  EmptyAnalyticsP,
  EmptyAnalyticsTitle,
  LineChartBox,
  PieChartWrap,
  Title
} from './styled';
import { PieChartComponent } from './PieChart';
import { LineChartComponent } from './LineChart';
import { useAppSelector } from '../../Hooks';
import { useGetTotalQuery } from '../../Redux/analytics/analyticsAPI';
import { FinanceTable } from './Table';
import { ArrowRight, EmptyAnalytics } from '../../Icons';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

export const Analytics = () => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.user);
  const {
    data: totalData,
    isLoading,
    isFetching
  } = useGetTotalQuery(
    {
      id: userInfo?.data?.id
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      skip: false
    }
  );

  return (
    <Layout>
      <Title>Analytics</Title>
      <>
        {(isFetching || isLoading) && <Spin size="large" />}
        {!isFetching && !isLoading && (
          <>
            {totalData?.was_active && totalData?.arts_count != 0 ? (
              <>
                <AnalyticsBox>
                  {totalData?.arts_count != 0 && (
                      <PieChartComponent totalObj={totalData} />
                  )}
                  {totalData?.was_active && (
                      <LineChartComponent totalObj={totalData} />
                  )}
                </AnalyticsBox>
                {totalData?.was_active && <FinanceTable />}
              </>
            ) : (
              <EmptyAnalyticsBox>
                <EmptyAnalytics />
                <EmptyAnalyticsTitle>No data available</EmptyAnalyticsTitle>
                <EmptyAnalyticsP>
                  You have no artwork purchased to display the statistics. Upload or trade art to
                  view the analytics details
                </EmptyAnalyticsP>
                <EmptyAnalyticsButton ghoast onClick={() => navigate('/gallery')}>
                  go to gallery <ArrowRight style={{ marginLeft: '12px' }} />
                </EmptyAnalyticsButton>
              </EmptyAnalyticsBox>
            )}
          </>
        )}
      </>
    </Layout>
  );
};
