type Endpoints = {
    [key: string]: string;
};

export const endpoints: Endpoints = {
    arts: 'arts',
    user: 'user',
    dashboard: 'dashboard',
    chats: 'chats'
};
