import styled from 'styled-components';
import { Table, TableProps, Typography } from 'antd';
import {devices} from "../../../Theme";


export const TableWrap = styled.div`
  padding: 24px;
  border: 1px solid #e8eaf6;
  border-radius: 12px;
  @media ${devices.laptop} {
    padding: 15px 10px;
  }
  @media ${devices.mobileL} {
    max-width: 100%;
    overflow-x: scroll;
  }
`;
export const TableBox = styled.div`
  @media ${devices.mobileL} {
    max-width: 700px;
    min-width: 700px;
  }
`;
export const DecimalBox = styled.div`
  display: flex;
  align-items: center;
`;
export const TableTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  @media ${devices.laptop} {
    font-size: 16px;
  }
  @media ${devices.mobileL} {
    font-size: 14px;
  }
`;

export const TableValue = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  @media ${devices.laptop} {
    font-size: 16px;
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 0;
  @media ${devices.mobileL} {
    flex-wrap: wrap;
  }
`;

export const TableBody = styled(Table)`
  thead {
    th {
      background: white;
    }
  };
  & .ant-table-row {
    &:nth-child(even) {
      background: white;
    };
    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.04);
    };
  };
  & .ant-table-column-sort {
    background: none;
  },
  & .ant-table-tbody > tr > td {
    padding: 15px;
    @media ${devices.mobileL} {
      padding: 12px 8px;
      font-size: 12px;
    }
      @media ${devices.mobile} {
        padding: 10px 6px;
        font-size: 11px;
      }
  }
  .ant-table-thead > tr > th {
    padding: 15px;
    @media ${devices.mobileL} {
      padding: 12px 8px;
    }
  }
`
