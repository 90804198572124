import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { Form, message, Modal } from 'antd';
import { signUpConfig } from '../../Configs/SignUpConfig';
import Button from '../../Components/Button';
import { AuthLink } from '../../Components/Layout/Auth/styled';
import { AuthLayout } from '../../Components/Layout/Auth';
import { InputPassword } from '../../Components/Input/styled';
import { KeyOutlined } from '@ant-design/icons';
import { FormBasic } from '../../Components';
import { Link, useNavigate } from 'react-router-dom';
import { registration } from '../../Redux/slices';
import { useRegisterUserMutation } from '../../Redux/query/user/userAPI';

export const RegisterScreen = () => {
  const { error } = useAppSelector((state) => state.user);
  const [setRegistration, { isSuccess, isLoading, isError }] = useRegisterUserMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isError && error) {
      message.error(`Email or username ${error}`);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      message.info('Confirmation email is sent');
      showModal();
    }
  }, [isSuccess]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    navigate('/login');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate('/login');
  };

  const submitForm = async (data) => {
    const userData = await setRegistration(data);
    console.log(userData);
    dispatch(registration(userData));
  };
  return (
    <AuthLayout>
      <FormBasic
        title={'Create account'}
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        logo
        onFinish={submitForm}
      >
        {signUpConfig.map(({ name, rules, placeholder, prefix, Component, size }) => {
          return (
            <Form.Item key={name} name={name} rules={rules}>
              <Component size={size} placeholder={placeholder} prefix={prefix} />
            </Form.Item>
          );
        })}

        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            { min: 8, message: 'Password must be minimum 8 characters.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              }
            })
          ]}
        >
          <InputPassword size={'md'} placeholder="Confirm Password" prefix={<KeyOutlined />} />
        </Form.Item>
        <Button shape="circle" block primary="true" htmlType="submit">
          {isLoading ? 'loading...' : 'Create account'}
        </Button>
        <AuthLink>
          Already have an account? <Link to={'/login'}>Log in</Link>
        </AuthLink>
      </FormBasic>
      <Modal title="Success!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Confirmation email is sent!</p>
      </Modal>
    </AuthLayout>
  );
};
