import {Typography} from "antd";
import {devices} from "../../Theme";
import styled from "styled-components";


export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  @media ${devices.laptop} {
    font-size: 18px;
  }
  @media ${devices.mobileL} {
    margin-bottom: 20px;
  }
`;
