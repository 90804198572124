import styled from 'styled-components';
import { Form, Upload } from 'antd';
import { color, devices } from '../../Theme';

export const StickyUpload = styled(Form.Item)`
  .ant-row {
    position: sticky;
    top: 80px;
  }

  @media ${devices.laptop} {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
  @media ${devices.mobileL} {
    max-width: 320px;
  }
`;
export const UploadStyled = styled(Upload)`
  
  position: relative;
  &.ant-upload.ant-upload-select-picture-card > .ant-upload {
    width: 100% !important;
  }

  img {
    width: 100%;
  }

  &.add-btn-holder {
    position: absolute;
    width: fit-content;
    top: -5px;
    right: 100px;

    .ant-upload.ant-upload-select-picture-card {
      height: auto;
      border: none;
      width: inherit;
      background: none;
      margin: 0;

      
      
      article {
        margin: 0;
      }
    }
    .ant-upload.ant-upload-select-picture-card {
      @media ${devices.tablet} {
        height: 400px;
      }
    }
    

    .ant-upload.ant-upload-select-picture-card > .ant-upload {
      height: auto;
      width: inherit;
      display: inherit;
    }
  }
`;

export const UploadCount = styled.div`
  display: flex;
  flex: 1;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.14px;
`;
export const UploadBtn = styled.div`
  width: fit-content;
  color: ${color.brand};
  text-decoration: underline;
  
  
  article {
    color: ${color.brand};
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.15px;
  }
`;
export const UploadText = styled.div`
  color: ${color.brand};
  font-size: 16px;
`;
