import React from 'react';

const CheckIcon = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.7156 3.375H20.8432C20.5807 3.375 20.3316 3.49554 20.1709 3.70179L9.12716 17.692L3.83163 10.9821C3.75152 10.8804 3.64941 10.7982 3.53297 10.7416C3.41653 10.685 3.28877 10.6555 3.15931 10.6554H1.28698C1.10752 10.6554 1.00841 10.8616 1.11823 11.0009L8.45484 20.2955C8.7977 20.7295 9.45663 20.7295 9.80216 20.2955L22.8843 3.71786C22.9941 3.58125 22.895 3.375 22.7156 3.375Z"
                fill="#274AC8"
            />
        </svg>
    );
};

export default CheckIcon;
