import React from 'react';

const DeleteDocumentIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.766 8.725L9.99935 10.4917L8.22435 8.725L7.04935 9.9L8.82435 11.6667L7.05768 13.4333L8.23268 14.6083L9.99935 12.8417L11.766 14.6083L12.941 13.4333L11.1743 11.6667L12.941 9.9L11.766 8.725ZM12.916 3.33333L12.0827 2.5H7.91602L7.08268 3.33333H4.16602V5H15.8327V3.33333H12.916ZM4.99935 15.8333C4.99935 16.75 5.74935 17.5 6.66602 17.5H13.3327C14.2493 17.5 14.9993 16.75 14.9993 15.8333V5.83333H4.99935V15.8333ZM6.66602 7.5H13.3327V15.8333H6.66602V7.5Z" fill="#1976D2"/>
        </svg>
    );
};

export default DeleteDocumentIcon;
