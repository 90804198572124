// Landing Page
import React, { useEffect, useState } from 'react';
import {
  DocumentArtContent,
  SectionOneImage,
  NavItem,
  NavList,
  RightColumn,
  ContentOnLeft,
  BodyText,
  HeaderOne,
  ButtonNonMobile,
  ButtonMobile,
  RightColumnBlock,
  RightColumnText,
  HR
} from './styledCopy';
import buyImage from '../../../Img/buy.jpg';
import sellImage from '../../../Img/sell.jpeg';
import analyticsImage from '../../../Img/analytics.jpeg';
import documentImage from '../../../Img/document.jpeg';
import { useNavigate } from 'react-router-dom';
export const DocumentArtSection = () => {
  const [activeButton, setActiveButton] = useState('Browse Artwork');
  const navigate = useNavigate();
  return (
    <DocumentArtContent>
      {/* <ContentOnLeftContainer> */}
      <ContentOnLeft>
        <HeaderOne>Document Your Art With Provenart</HeaderOne>
        <BodyText>
          Organize your portfolio in a breeze when you upload your art for public or private
          display. Keep your receipts and other provenance documentation stored on Provenart.
        </BodyText>
        <ButtonNonMobile primary="true" onClick={() => navigate('/registration')}>
          Upload Your Art
        </ButtonNonMobile>
      </ContentOnLeft>
      {/* </ContentOnLeftContainer> */}
      <RightColumn>
        <RightColumnBlock>
          <RightColumnText>Organize your receipts and provenance documentation</RightColumnText>
          <HR></HR>
        </RightColumnBlock>
        <RightColumnBlock>
          <RightColumnText>Securely stored in the cloud</RightColumnText>
          <HR></HR>
        </RightColumnBlock>
        <RightColumnBlock>
          <RightColumnText>Easily share a link to your artwork collection</RightColumnText>
          <HR></HR>
        </RightColumnBlock>
        <RightColumnBlock>
          <RightColumnText>
            Perfect for easy access, record keeping, and will and estate planning
          </RightColumnText>
          <HR></HR>
        </RightColumnBlock>
        <ButtonMobile primary="true" onClick={() => navigate('/registration')}>
          Upload Your Art
        </ButtonMobile>
      </RightColumn>
    </DocumentArtContent>
  );
};
