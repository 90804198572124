import styled from 'styled-components';
import { Space } from 'antd';
import { devices } from '../Theme';

export const RoutePrivateStyled = styled(Space)`
  @media ${devices.tabletS} {
    display: flex;
    flex-direction: column;
  }
`;
