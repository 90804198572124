import React from 'react';
import { FormCustom } from './styled';
import { FormCustomProps } from './typed';
import Logo from '../../../Icons/Logo';
import { FormItem, FormLogo, FormTitle, NavLogo } from '../styled';

export const FormBasic = ({
  name,
  title,
  onFinish,
  layout,
  scrollToFirstError,
  children,
  logo
}: FormCustomProps) => {
  return (
    <FormCustom
      name={name}
      onFinish={onFinish}
      layout={layout}
      scrollToFirstError={scrollToFirstError}
    >
      {logo && (
        <NavLogo to={'/gallery'}>
          <Logo />
        </NavLogo>
      )}
      {title && (
        <FormItem>
          <FormTitle>{title}</FormTitle>
        </FormItem>
      )}
      {children}
    </FormCustom>
  );
};
