import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { color, devices } from '../../../Theme';
import { Space, Typography } from 'antd';
import { StyledButtonSection } from '../../../Components/Button/styled';

export const DocumentArtContent = styled.nav`
  margin-top: 0px;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  /* Enable vertical scroll if necessary */
  @media ${devices.laptop} {
    margin-top: 67px;
  }
  @media ${devices.tabletS} {
    flex-direction: column;
    margin-top: 50px;
  }
  @media ${devices.mobile} {
    height: auto;
    flex-direction: column;
  }
`;

export const ContentOnLeft = styled.div`
  object-fit: cover;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${devices.tablet} {
    height: calc(80vh - 20px);
    max-width: 100%;
    width: 100%;
  }

  @media ${devices.tabletS} {
    order: 1;
    height: calc(50vh - 20px);
    max-width: 100%;
    width: 100%;
  }

  @media ${devices.mobile} {
    order: 1;
  }
`;

export const SectionOneText = styled.div`
  padding-left: 35px;
  max-width: 50%;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scroll if necessary */

  @media ${devices.mobile} {
    height: calc(80vh - 20px);
  }
`;

export const SectionOneImage = styled.img`
  width: 100%;
  height: calc(70vh - 20px);
  object-fit: cover;
  /* Enable vertical scroll if necessary */

  @media ${devices.mobile} {
    height: 5px; /* Adjust the height as needed */
    width: 100%;
    object-fit: cover;
  }

  @media ${devices.laptop} {
    height: calc(60vh - 20px); /* Adjust the height as needed */
    max-width: 100%;
  }

  @media ${devices.tablet} {
    height: calc(60vh - 20px); /* Adjust the height as needed */
    max-width: 100%;
  }
  @media ${devices.tabletS} {
    height: calc(60vh - 20px); /* Adjust the height as needed */
    max-width: 100%;
  }
`;

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  overflow-y: auto; /* Enable vertical scroll if necessary */
  z-index: 1;
  margin-top: 50px;
  @media ${devices.laptop} {
    margin-top: 5px;
  }

  @media ${devices.tabletS} {
    order: 2;
    overflow-y: auto;
  }
`;

export const RightColumnBlock = styled.div`
  margin-bottom: 40px;
  margin-top: 30px;
  margin-left: 40px;
  @media ${devices.laptop} {
    margin-left: 80px;
  }

  @media ${devices.tabletS} {
    margin-left: 40px;
  }
  @media ${devices.mobile} {
    margin-left: 25px;
  }
`;

export const RightColumnText = styled(Typography)`
  margin-bottom: 20px;
  font-size: 25px;
  color: rgba(32, 24, 77, 0.85);
`;

export const HR = styled.div`
  border: none;
  border-top: 1px solid #ccc;
`;

export const NavItem = styled(Space)`
  padding: 20px;
  color: white;

  text-decoration: ${(props) => (props.isActive ? 'underline' : 'none')};

  @media ${devices.tablet} {
    font-size: 15px;
    padding: 10px;
  }

  @media ${devices.mobile} {
    font-size: 15px;
    padding: 10px;
  }
`;

export const NavList = styled.nav`
  padding-left: 87px;

  @media ${devices.laptop} {
    padding-left: 30px;
  }

  @media ${devices.tablet} {
    padding-left: 18px;
  }

  @media ${devices.tabletS} {
    padding-top: 20px;
    padding-left: 30px;
  }

  @media ${devices.mobile} {
    padding-top: 20px;
    padding-left: 15px;
  }
`;

export const BodyText = styled(Typography)`
  font-size: 25px;
  margin-top: 40px;
  margin-left: 100px;
  color: rgba(32, 24, 77, 0.85);
  width: 600px;
  line-height: 1.5;
  font-style: italic;
  max-width: 500px;

  @media ${devices.laptop} {
    margin-left: 50px;
    margin-top: 30px;
    max-width: 400px;
  }
  @media ${devices.tablet} {
    margin-left: 28px;
    width: 300px;
  }

  @media ${devices.tabletS} {
    margin-left: 40px;
    width: 400px;
  }
  @media ${devices.mobile} {
    margin-left: 25px;
    width: 300px;
  }
`;

export const HeaderOne = styled(Typography)`
  font-size: 38px;
  margin-top: 70px;
  margin-left: 100px;
  line-height: 1.5;
  font-weight: normal;
  color: rgba(32, 24, 77, 0.85);

  @media ${devices.laptop} {
    margin-left: 50px;
    margin-top: 30px;
    font-size: 30px;
  }

  @media ${devices.tablet} {
    margin-left: 27px;
    margin-top: 30px;
    font-size: 30px;
  }
  @media ${devices.tabletS} {
    margin-left: 40px;
    margin-top: 0px;
  }
  @media ${devices.mobile} {
    margin-left: 25px;
  }
`;

export const ButtonNonMobile = styled(StyledButtonSection)`
  align-items: center;
  display: block;
  text-align: center;
  width: 350px;
  margin-top: 100px;
  margin-right: 50px;
  margin-left: 90px;

  @media ${devices.laptop} {
    margin-left: 50px;
  }

  @media ${devices.tablet} {
    margin-left: 30px;
    margin-top: 50px;
    width: 300px;
  }

  @media ${devices.tabletS} {
    display: none;
    margin-bottom: 20px;
    margin-left: 40px;
    width: 300px;
  }

  @media ${devices.mobile} {
    margin-bottom: 20px;
    margin-left: 25px;
  }
`;

export const ButtonMobile = styled(StyledButtonSection)`
  align-items: center;
  display: none;
  text-align: center;
  width: 350px;
  margin-top: 100px;
  margin-right: 50px;
  margin-left: 90px;

  @media ${devices.laptop} {
    margin-left: 50px;
  }

  @media ${devices.tablet} {
    margin-left: 30px;
    margin-top: 50px;
    width: 300px;
  }

  @media ${devices.tabletS} {
    display: block;
    margin-bottom: 20px;
    margin-left: 40px;
    width: 300px;
  }

  @media ${devices.mobile} {
    margin-bottom: 20px;
    margin-left: 25px;
  }
`;
