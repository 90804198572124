import React, { useRef, useState } from 'react';
import { FormDesc, FormItem, FormSendLink, FormTitle } from '../../Components/Form/styled';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Form, message, Space } from 'antd';
import Button from '../../Components/Button';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { forgotPassword, sendVerifyCode } from '../../Redux/features';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../Components/Layout/Auth';
import { FormBasic } from '../../Components';

export const CodeScreen = ({ user }: any) => {
  const { success } = useAppSelector((state) => state.user);
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [result, setResult] = useState(null);
  const handleOnChange = (res) => {
    setResult(result);
    if (res.length == 5) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  const dispatch = useAppDispatch();
  const submitForm = (data) => {
    const output = {
      ...data,
      email: user.email
    };
    dispatch(sendVerifyCode(output));
    if (success) {
      navigate('/newpswd');
    } else {
      AuthInputRef.current?.clear();
      message.error('check your code please');
    }
  };
  const sendCode = () => {
    dispatch(forgotPassword({ email: user.email }));
  };

  return (
    <AuthLayout>
      <FormBasic
        name="basic"
        logo
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={submitForm}
      >
        <FormItem>
          <Space size={[12, 12]} direction={'vertical'} wrap>
            <FormTitle>Restore password</FormTitle>
            <FormDesc>Enter the code, which we sent to:</FormDesc>
            <FormDesc>{user?.email}</FormDesc>
          </Space>
        </FormItem>
        <Form.Item name="reset_code" className="AuthCodeWrapper">
          <AuthCode
            containerClassName={'AuthCodeWrapper'}
            inputClassName={'AuthInput input'}
            allowedCharacters={'numeric'}
            autoFocus={true}
            length={5}
            onChange={handleOnChange}
            ref={AuthInputRef}
          />
        </Form.Item>
        <FormSendLink onClick={sendCode}>Send again</FormSendLink>
        <Button shape="circle" disabled={isDisabled} block primary="true" htmlType="submit">
          send
        </Button>
      </FormBasic>
    </AuthLayout>
  );
};
