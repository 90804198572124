import styled from "styled-components";
import {Typography} from "antd";
import {ExpandMoreIcon} from "../../../../Icons";
import {devices} from "../../../../Theme";

export const Wrapper = styled.div`
  max-width: 1074px;
  width: 100%;
  height: 630px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  @media ${devices.tablet} {
    height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
  }
  @media ${devices.mobileL} {
    border-radius: unset;
    border: none;
    box-shadow: none;
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
`
export const Box = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  @media ${devices.tablet} {
    max-width: 350px;
  }
  @media ${devices.tabletS} {
    max-width: 250px;
  }
  @media ${devices.mobileL} {
    max-width: 90px;
  }
`;

export const BoxMessage = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
 width: 100%;
`;


export const Chat = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const MessagesBox = styled.div`
  overflow-y: scroll;
  height: 90%;
`

export const Title = styled(Typography)`
  font-size: 24px;
  padding: 24px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-start;
  @media ${devices.tablet} {
    font-size: 20px;
    padding: 20px;
  }
  @media ${devices.tabletS} {
    font-size: 18px;
    padding: 18px;
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    padding: 17px 10px;
  }
`

export const List = styled.div`
  color: rgba(0, 0, 0, 0.87);
  padding: 16px;
  overflow: hidden;
  @media ${devices.tabletS} {
    padding: 10px 5px;
  }
`

export const Message = styled.div`
  cursor: pointer;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: relative;

  @media ${devices.tabletS} {
    padding: 12px 5px;
    margin-bottom: 10px;
  }
  @media ${devices.mobileL} {
    flex-wrap: wrap;
  }

  &.sent {
    background: red;
    color: coral;
  }

  &.is-not-read {
    background: rgba(0, 0, 0, 0.04);
  }
`

export const MessageImg = styled.img`
  display: flex;
  flex-shrink: 0;
  width: 90px;
  height: 64px;
  object-fit: cover;
  margin-right: 16px;
  @media ${devices.tabletS} {
    width: 70px;
    height: 54px;
    margin-right: 10px;
  }
`

export const MessageUser = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`

export const MessageDesc = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${devices.tabletS} {
    font-size: 13px;
    line-height: 15px;
  }
`

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;

  @media ${devices.tabletS} {
    padding: 12px;
  }
  @media ${devices.mobileL} {
    padding: 9px;
  }
`

export const UserBox = styled.div`
  display: flex;
  align-items: center;
`

export const New = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 8px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.46px;
`

export const UserAvatar = styled.img`
  display: flex;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 16px;
  @media ${devices.tablet} {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
  @media ${devices.tablet} {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
`

export const ExpandIcon = styled(ExpandMoreIcon)`
  position: absolute;
  left: auto;
  right: 16px;
  top: 20%;
  height: 48px;
  @media ${devices.tablet} {
    right: 8px;
  }
  @media ${devices.mobileL} {
    display: none;
  }
`
export const FlexBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media ${devices.mobileL} {
    width: 100%;
    margin-top: 10px;
    margin-left: 5px;
  }
`
