import { ArtItem, ArtText } from '../../../../Pages/art/View/SingleArt/styled';
import { ArrowDownOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { IDocView } from '../../../../Pages/art/View/SingleArt/typed';

export const DocView: FC<IDocView> = ({ doc }) => {
  return (
    <>
      <ArtItem>
        {doc.title && (
          <>
            <ArtText type={'label'}>Title</ArtText>
            <ArtText type={'name'}>{doc.title}</ArtText>
          </>
        )}
      </ArtItem>
      <ArtItem>
        {doc.description && (
          <>
            <ArtText type={'label'}>Description</ArtText>
            <ArtText type={'description'}>{doc.description}</ArtText>
          </>
        )}
      </ArtItem>
       <ArtItem>
        <ArtText type={'label'}>File</ArtText>
        <a href={doc.url} rel="noreferrer" target="_blank">
          <ArrowDownOutlined />
          {doc.fileName || doc.title || 'Document'}
        </a>
      </ArtItem>
    </>
  );
};
