import React from 'react';

const DeleteIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.27137 8.99861L15.7167 1.31579C15.8247 1.18811 15.7339 0.994141 15.5669 0.994141H13.6075C13.4921 0.994141 13.3816 1.0457 13.3055 1.1341L7.98968 7.47137L2.67383 1.1341C2.60017 1.0457 2.48968 0.994141 2.37182 0.994141H0.412446C0.245481 0.994141 0.154633 1.18811 0.262669 1.31579L6.70798 8.99861L0.262669 16.6814C0.238468 16.7099 0.222942 16.7447 0.217934 16.7817C0.212926 16.8187 0.218646 16.8564 0.234416 16.8903C0.250186 16.9241 0.275343 16.9528 0.3069 16.9728C0.338457 16.9928 0.375088 17.0033 0.412446 17.0031H2.37182C2.48722 17.0031 2.59771 16.9515 2.67383 16.8631L7.98968 10.5258L13.3055 16.8631C13.3792 16.9515 13.4897 17.0031 13.6075 17.0031H15.5669C15.7339 17.0031 15.8247 16.8091 15.7167 16.6814L9.27137 8.99861Z"
        fill="#303030"
      />
    </svg>
  );
};

export default DeleteIcon;
