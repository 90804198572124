import React, {useState} from "react";

import {
    TableArrowLeft,
    TableArrowRight,
    TableBody,
    TableBox,
    TableFooter,
    TableItem,
    TableItemName,
    TableItemSwitch,
    TableRows,
    TableTitle,
    TableTotal
} from "./styled";

import {Select, Spin, Switch} from "antd";
import {useChangeOwnershipSettingsMutation, useGetOwnershipSettingsQuery} from "../../../Redux/ownership/ownershipAPI";
import RightPaginationArrow from "../../../Icons/RightPaginationArrow";
import {LoaderBox} from "../../../Components/Chat/styled";

export const Transfers = () => {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

    const {data, isFetching, isLoading} = useGetOwnershipSettingsQuery({
        page: page,
        size: size
    }, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        skip: false
    });

    const [changeOwnerShip] = useChangeOwnershipSettingsMutation();

    // console.log(data);

    const onChange = (checked, id) => {
        changeOwnerShip({
            id: id,
            body: {
                ownership_settings: {
                    hide_owner: checked
                }
            }
        }).unwrap();
    };
    const handleChangeRowsCount = (value) => {
        setSize(Number(value));
    }


    return (
        <TableBox>
            <TableTitle>Ownership Settings</TableTitle>
            {(isFetching || isLoading) && <LoaderBox><Spin size="large"/></LoaderBox>}
            {
                (!isFetching && !isLoading) &&
                <>
                    <TableBody>
                        {
                            data?.data?.map((tableItem, index) =>
                                <TableItem key={tableItem.id}
                                           style={{background: index % 2 == 0 ? '#fff' : 'rgba(0, 0, 0, 0.04)'}}>
                                    <TableItemName>
                                        {tableItem.attributes.artTitle}
                                    </TableItemName>
                                    <TableItemSwitch>
                                        <Switch defaultChecked={tableItem.attributes.hideOwner}
                                                onChange={(checked) => onChange(checked, tableItem.id)}/>
                                    </TableItemSwitch>
                                </TableItem>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRows>
                            Rows per page:
                            <Select
                                defaultValue="10"
                                style={{
                                    width: 65,
                                    border: 'none',
                                    padding: '0 2px',
                                }}
                                onChange={handleChangeRowsCount}
                                options={[
                                    {
                                        value: '10',
                                        label: '10',
                                    },
                                    {
                                        value: '15',
                                        label: '15',
                                    },
                                    {
                                        value: '20',
                                        label: '20',
                                    }
                                ]}
                            />
                        </TableRows>
                        <TableTotal>
                            {(page * size) - size + 1}-{page * size} of {data.meta.total}
                        </TableTotal>
                        <TableArrowLeft onClick={() => {
                            if (page != 1) {
                                setPage((prev) => prev - 1)
                            }
                        }
                        }>
                            <RightPaginationArrow/>
                        </TableArrowLeft>

                        <TableArrowRight onClick={() => {
                            if (page * size < data.meta.total)
                                setPage((prev) => prev + 1)
                        }}>
                            <RightPaginationArrow/>
                        </TableArrowRight>

                    </TableFooter>
                </>
            }
        </TableBox>
    )
}
