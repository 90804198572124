import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Chat,
    ExpandIcon,
    FlexBox,
    List,
    Message,
    MessageDesc,
    MessageImg,
    MessagesBox,
    Title,
    Wrapper
} from "./styled";
import {Layout} from "../../../../Components";
import {Messages} from "../Message";
import {useGetChatsQuery} from "../../../../Redux/query/chats/chatsAPI";
import {addDoc, collection, doc, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../../Components/Chat/firebase";
import {Divider, InputRef} from "antd";
import {getAuth, signInAnonymously} from "firebase/auth";
import {updateDoc} from "@firebase/firestore";
import {NavigateButton} from "../../../../Components/Button/NavigateButton";
import {useAppSelector} from "../../../../Hooks";

export const ChatBox = () => {
    const {userInfo} = useAppSelector((state) => state.user);
    const {data: chats} = useGetChatsQuery({})
    const chatTxt = useRef<InputRef>(null);
    const [messages, setMessages] = useState<Array<any>>([]);
    const [input, setInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingConversations, setIsLoadingConversations] = useState<boolean>(false)
    const [item, setItem] = useState<number>(0)

    const externalId = chats?.data[item]?.attributes?.externalId
    const name = chats?.data[item]?.attributes?.user2Name
    const img = chats?.data[item]?.attributes?.artImageUrl
    const auth = getAuth();

    const [conversationsList, setConversationsList] = useState([])
    const [chatsID, setChatsID] = useState([]);

    signInAnonymously(auth)
        .then(() => {
            console.log('');
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });

    const sendMessage = async (e) => {
        e.preventDefault()
        if (input === '') {
            return
        }
        const date = new Date();
        const {uid} = auth.currentUser;
        await addDoc(collection(db, `conversations/${externalId}/messages`), {
            externalId: externalId,
            text: input,
            isRead: false,
            ownerID: userInfo?.data?.id,
            createdAt: date,
            updatedAt: date,
            uid,
            timestamp: date,
        })
        const lastMessUpdate = doc(db, 'conversations', externalId)
        const data = {
            timestamp: date,
            lastMessage: {
                externalId: externalId,
                text: input,
                ownerID: userInfo?.data?.id,
                uid,
                timestamp: date
            },
            isRead: false,
        }

        await updateDoc(lastMessUpdate, data).then(r => console.log(r))
        setInput('')
    }

    useEffect(() => {
        if (chats?.data?.length) {
            const chatsIDs = chats?.data?.map(el => el?.id);
            setChatsID([...chatsIDs]);
            setIsLoadingConversations(true);
        }
    }, [chats])

    useEffect(() => {
        const q = query(collection(db, `conversations/${externalId}/messages`), orderBy('timestamp'));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = [];
            querySnapshot.forEach((doc) => {
                messages.push({...doc.data(), id: doc.id});
            });
            setIsLoading(false)
            setMessages(messages);
        });

        return () => unsubscribe()
    }, [externalId, item]);

    const m = messages?.filter(el => el.ownerID !== userInfo?.data?.id)

    useEffect(() => {
        let conversations = []
        if (chatsID?.length) {
            const queryConversations = query(collection(db, `conversations`), where('externalChatId', 'in', chatsID));
            const snapshotUnreadConversations = onSnapshot(queryConversations, (querySnapshot) => {
                conversations = [];
                querySnapshot.forEach((doc) => {
                    conversations.push({...doc.data(), id: doc.id});
                });
                setConversationsList(conversations)
            });
            return () => snapshotUnreadConversations()
        }
    }, [chatsID])

    const handleID = async (id) => {
        setItem(id)
        setInput('')
        m.map(async el => {
            const lastMessUpdate = doc(db, `conversations/${externalId}/messages/${el.id}`)
            await updateDoc(lastMessUpdate, {isRead: true}).then(r => console.log(r))
        })
        const chat = conversationsList.find(el => el.id === externalId)
        if (chat.lastMessage.ownerID !== userInfo?.data?.id) {
            const conversations = doc(db, `conversations/${externalId}`)
            await updateDoc(conversations, {isRead: true}).then(r => console.log(r))
        }
    }
    return (
        <Layout>
            <NavigateButton/>
            <Wrapper>
                <Chat>
                    <Box>
                        <Title>
                            Messages
                        </Title>
                        <MessagesBox>
                            {/*<New>{incomingLength?.length > 0 && 'New'}</New>*/}
                            <List>
                                {conversationsList?.map((el, index) => (
                                    <React.Fragment key={el.id}>
                                        <Message onClick={() => (handleID(index))}
                                                 className={el?.lastMessage?.ownerID !== userInfo?.data?.id && !el?.isRead ? 'is-not-read' : ''}>
                                            <MessageImg
                                                src={el?.imageUrl}/>
                                            <FlexBox>
                                                <div>
                                                    <MessageDesc>
                                                        {el?.lastMessage?.text}
                                                    </MessageDesc>
                                                </div>
                                            </FlexBox>
                                            <ExpandIcon/>
                                        </Message>
                                    </React.Fragment>
                                ))}
                            </List>
                        </MessagesBox>
                    </Box>
                    <Messages name={name} img={img} refInput={chatTxt} value={input} isLoading={isLoading}
                              messages={messages.length > 0 && messages}
                              onChange={(e) => setInput(e.target.value)} onSubmit={sendMessage}
                              onClick={sendMessage}/>
                </Chat>
            </Wrapper>
        </Layout>
    );
};

