import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from '../../../Img/authBg.jpg';
import { color, devices } from '../../../Theme';

export const AuthStyled = styled.div`
  background: url(${img}) no-repeat;
  background-size: cover;
  padding: 0 16px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AuthLink = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 117px;
  color: ${color.dark};
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  text-align: center;

  span {
    margin-left: 4px;
    cursor: pointer;
    font-weight: 700;
  }

  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
  }
`;
