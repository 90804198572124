import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { color, devices } from '../../../Theme';
import { Space, Typography } from 'antd';
import { StyledButtonSection } from '../../../Components/Button/styled';

export const Container = styled.div`
  margin-top: 50px;
  background-color: black;
`;

export const HeaderOne = styled(Typography)`
  font-size: 38px;
  margin-top: 25px;
  margin-left: 90px;
  line-height: 1.5;
  font-weight: normal;
  top: 100%;
  color: white;

  @media ${devices.laptop} {
    margin-left: 40px;
  }

  @media ${devices.tablet} {
    margin-left: 25px;
  }

  @media ${devices.tabletS} {
    margin-left: 35px;
  }
  @media ${devices.mobile} {
    margin-left: 25px;
  }
`;

export const ItemGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: 30px;
  @media ${devices.laptop} {
    margin-left: -30px;
  }
  @media ${devices.tablet} {
    margin-left: 10px;
  }
  @media ${devices.mobile} {
    margin-left: -10px;
  }
`;

export const Item = styled.div`
  width: 22%;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 40px;
  text-align: center;
  background-color: black;
  @media ${devices.laptop} {
    margin-left: 60px;
  }

  @media ${devices.tablet} {
    margin-left: 20px;
  }

  @media ${devices.mobile} {
    width: 30%;
    margin-left: 35px;
  }
`;

export const ItemImage = styled.img`
  display: block;
  width: 80%;
  height: 80%;
  max-width: 100%;
  background-color: black;
  align-self: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
  }
`;

export const ItemText = styled(Typography)`
  flex: 1 0 20%;
  margin-top: 30px;
  margin-right: 70px;
  text-align: center;
  color: white;

  @media ${devices.tablet} {
    margin-top: 10px;
    margin-right: 15px;
  }

  @media ${devices.mobile} {
    margin-right: 17px;
  }
`;

export const Button = styled(StyledButtonSection)`
  align-items: center;
  display: block;
  text-align: center;
  width: 350px;
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 90px;
  margin-bottom: 20px;

  @media ${devices.laptop} {
    margin-left: 50px;
  }

  @media ${devices.tablet} {
    margin-left: 25px;
  }

  @media ${devices.tabletS} {
    margin-left: 35px;
    width: 300px;
  }

  @media ${devices.mobile} {
    margin-left: 25px;
  }
`;
