import React, { useState } from 'react';
import './Assets/App.css';
import { Route, Routes } from 'react-router-dom';
import {
  CodeScreen,
  CreateArtObject,
  EmailScreen,
  GalleryPage,
  LoginScreen,
  NewPassword,
  RegisterScreen,
  SingleArt
} from './Pages';
import { EditArtObject } from './Pages/art/Edit';
import { DashboardPage } from './Pages/dashboard';
import { Analytics } from './Pages/Analytics';
import { Home } from './Pages/Home';
import ChatRoom from './Pages/ChatRoom';
import { useAppSelector } from './Hooks';
import { ProtectedRoute } from './Utils/ProtectedRoute';
// Import the functions you need from the SDKs you need

const App = () => {
  const [user, setUser] = useState(null);
  const { userInfo } = useAppSelector((state) => state.user);
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/registration" element={<RegisterScreen />} />
          <Route path="/email" element={<EmailScreen setUser={setUser} />} />
          <Route path="/code" element={<CodeScreen user={user} />} />
          <Route path="/newpswd" element={<NewPassword user={user} />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route
            path="/art"
            element={
              <ProtectedRoute user={userInfo}>
                <CreateArtObject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/portfolio"
            element={
              <ProtectedRoute user={userInfo}>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute user={userInfo}>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route path="/home" element={<Home />} />
          {/* path for the initially loaded page!!  */}
          (if (userInfo) {<Route path="*" element={<Home />} />} else
          <Route path="*" element={<GalleryPage />} />)
          <Route path="art/:id" element={<SingleArt />} />
          <Route
            path="editArt/:id"
            element={
              <ProtectedRoute user={userInfo}>
                <EditArtObject />
              </ProtectedRoute>
            }
          />
          <Route
            path="chatroom"
            element={
              <ProtectedRoute user={userInfo}>
                <ChatRoom />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default App;
