import {Button, ButtonProps} from "antd";
import styled from "styled-components";
import {FC} from "react";

export const NavigateButtonStyled: FC<ButtonProps> = styled(Button)`
  display: flex;
  align-items: center;
  background: none;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;

  svg {
    margin-right: 4px;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
`