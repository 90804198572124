import {ArtItem, ArtText, VerifiedIconBox} from "../../../../Pages/art/View/SingleArt/styled";

import {
    PreviousOwnerAvatarContainer,
    PreviousOwnerAvatarImg,
    PreviousOwnerContainer,
    PreviousOwnerInfoContainer,
    PreviousOwnerJoined,
    PreviousOwnerName,
    RateBox, ReviewsCount,
    StarsContainer,
    TextRate
} from "../PreviousOwnersList/styled";
import {PreviousOwnersList} from "../PreviousOwnersList";
import React, {FC, useState} from "react";
import {useAppSelector} from "../../../../Hooks";
import StarRatings from 'react-star-ratings';
import {ReviewsListModal} from "../ReviewsListModal";
import {OwnerBox} from "./styled";
import {useListReviewsQuery} from "../../../../Redux/reviews/reviewsAPI";
import {getMonthYear} from "../../../../Utils/DateFormat";
import {Tooltip} from "../../../Tooltip";


interface IReviews {
    data: any;
}

export const Reviews:FC<IReviews> = ({data}) => {
    const { userInfo } = useAppSelector((state) => state.user);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<{id: string; name: string, hidden: boolean}>(
        {id: '', name: '', hidden: false});

    const {data: ownerData} = useListReviewsQuery({
        userId: data?.data?.attributes.ownerId
    })
    // console.log('ownerData',ownerData);

    return (
        <>
            <ArtText type={'name'}>Owners info:</ArtText>
            <ArtItem>
                <OwnerBox>
                    <ArtText type={'label'}  style={{paddingBottom: '15px'}}>Current owner:</ArtText>

                    <PreviousOwnerContainer>
                        <PreviousOwnerAvatarContainer>
                            <PreviousOwnerAvatarImg>
                                {
                                    data?.data?.attributes.ownerId == userInfo?.data?.id ?
                                        'Y' : (data?.data?.attributes.hideOwner ? 'A' :
                                                data?.data?.attributes.ownerName.slice(0,1).toLocaleUpperCase()
                                        )
                                }
                            </PreviousOwnerAvatarImg>
                            <PreviousOwnerInfoContainer>
                                <PreviousOwnerName>
                                    {
                                        data?.data?.attributes.ownerId == userInfo?.data?.id ?
                                            'You' : (data?.data?.attributes.hideOwner ? 'Anonymous' :
                                                    data?.data?.attributes.ownerName
                                            )
                                    }
                                    {data?.data?.attributes.ownerVerified &&
                                        <Tooltip title="Verified">
                                            <VerifiedIconBox/>
                                        </Tooltip>
                                    }
                                </PreviousOwnerName>
                                <PreviousOwnerJoined>
                                    Joined in {getMonthYear(data?.data?.attributes?.ownerCreatedAt)}
                                </PreviousOwnerJoined>
                            </PreviousOwnerInfoContainer>
                        </PreviousOwnerAvatarContainer>
                        <RateBox>
                            {
                                ownerData?.meta.total > 0 &&
                                <StarsContainer>
                                    <>
                                        <StarRatings
                                            rating={ownerData?.meta.rateScore}
                                            isSelectable={false}
                                            starDimension={'20px'}
                                            starRatedColor='#FFB400'
                                            starHoverColor='#FFB400'
                                            starEmptyColor='rgba(0, 0, 0, 0.23)'
                                            numberOfStars={5}
                                            name='rating'
                                            starSpacing={'1px'}
                                            svgIconPath='M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z'
                                            svgIconViewBox={'0 0 20 20'}
                                        />
                                        <TextRate>
                                            {ownerData?.meta.rateScore}
                                        </TextRate>
                                    </>
                                    {
                                        ( data?.data?.attributes.ownerId == userInfo?.data?.id || !data?.data?.attributes.hideOwner ) &&
                                        <ReviewsCount
                                            onClick={() => {
                                                if(ownerData?.meta.total != 0)
                                                {setIsModalOpen(true);
                                                    setSelectedUser(
                                                        {id: data?.data?.attributes.ownerId,
                                                            name: data?.data?.attributes.ownerName,
                                                            hidden: data?.data?.attributes.hideOwner
                                                        })}
                                            }}
                                            style={{
                                                textDecoration: ownerData?.meta.total == 0 ? 'none': 'underline',
                                                cursor: ownerData?.meta.total == 0 ? 'default': 'pointer'
                                            }}
                                        >
                                            ({ownerData?.meta.total} reviews)
                                        </ReviewsCount>
                                    }
                                </StarsContainer>
                            }

                        </RateBox>
                    </PreviousOwnerContainer>
                </OwnerBox>

            </ArtItem>
            {
                data?.data?.id.length > 0 &&
                <PreviousOwnersList
                    artId={data?.data?.id}
                    currentUserId={userInfo?.data?.id}
                />
            }
            <ReviewsListModal
                artId={data?.data?.id}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                user={selectedUser}
            />
        </>

    )
}
