import styled from "styled-components";
import {devices} from "../Theme";
import {Typography} from "antd";

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  @media ${devices.mobileL} {
    font-size: 14px;
  }
`;

export const TableTitle = styled(Typography)`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  @media ${devices.tablet} {
    margin-bottom: 24px;
  }
  @media ${devices.mobileL} {
    margin-bottom: 20px;
  }
`;

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`
