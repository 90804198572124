import React, { useEffect } from 'react';
import { FormField, FormForgot } from '../../Components/Form/styled';
import { AuthLink } from '../../Components/Layout/Auth/styled';
import { message } from 'antd';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import Button from '../../Components/Button';
import { signInConfig } from '../../Configs/SignInConfig';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthLayout } from '../../Components/Layout/Auth';
import { FormBasic } from '../../Components';
import { useSetUserMutation } from '../../Redux/query/user/userAPI';
import { login } from '../../Redux/slices';

export const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('email_confirmed') == 'true') {
      message.info('Email has been verified. please login again');
    }
  }, []);

  const { error, loading } = useAppSelector((state) => state.user);
  const [setUser, { isSuccess, isError }] = useSetUserMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate('/gallery');
    }
    if (isError && error) {
      message.warning(error);
    }
  }, [navigate, isError, isSuccess, error]);

  const submitForm = async (data) => {
    const userData = await setUser(data);
    dispatch(login(userData));
    console.log(data.status);
  };
  return (
    <AuthLayout>
      <FormBasic
        name="basic"
        title={'Log in'}
        initialValues={{ remember: true }}
        logo
        layout="vertical"
        onFinish={submitForm}>
        {signInConfig.map(({ name, rules, placeholder, prefix, Component, size }) => {
          return (
            <FormField key={name} name={name} rules={rules}>
              <Component size={size} placeholder={placeholder} prefix={prefix} />
            </FormField>
          );
        })}
        <FormForgot to={'/email'}>Forgot password?</FormForgot>
        <Button shape="circle" block primary="true" htmlType="submit">
          {loading ? 'loading...' : 'Login'}
        </Button>
        <AuthLink>
          Don’t have an account? <Link to={'/registration'}>Create account</Link>
        </AuthLink>
      </FormBasic>
    </AuthLayout>
  );
};
