import {Typography} from "antd";
import {color, devices} from "../../../../Theme";
import styled from "styled-components";

export const PreviousOwnerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
`;
export const PreviousOwnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
  @media ${devices.mobileL} {
    flex-wrap: wrap;
  }
`;

export const PreviousOwnerAvatarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  @media ${devices.mobileL} {
    margin-right: 20px;
    margin-bottom: 10px;
  }
`;

export const PreviousOwnerAvatarImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BDBDBD;
  color: #fff;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  @media ${devices.mobileL} {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
`;

export const PreviousOwnerInfoContainer = styled.div`
 
`;

export const LeaveRequestBox = styled.div`
    display: flex;
  justify-content: flex-end;
`;


export const PreviousOwnerName = styled(Typography)`
  color: ${color.primary};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-bottom: 3px;
  @media ${devices.mobileL} {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 2px;
  }
`;

export const PreviousOwnerJoined = styled(Typography)`
  color: #757575;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  @media ${devices.mobileL} {
    font-size: 11px;
    line-height: 13px;
  }
`;

export const RateBox = styled.div`
    display: flex;
  @media ${devices.mobileL} {
    flex-wrap:wrap;
  }
`;

export const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    cursor: pointer;
    margin-right: 2px;
  }
`;

export const TextRate = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.1px;
  margin: 0 5px 0 4px;
`;

export const ReviewsCount = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.1px;
`;

export const ModalBox = styled.div`
  padding: 24px 16px 16px;
`;

export const ModalTitleWrap = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${color.grey};
`;

export const ModalTitle = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding-right: 15px;
`;

export const CloseModalIconWrap = styled.div`
  padding: 5px;
  cursor: pointer;
`;
