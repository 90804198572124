import {ButtonProps} from 'antd';
import React, {FC} from 'react';
import {NavigateButtonStyled} from "./styled";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon} from '../../../Icons';

export const NavigateButton: FC<ButtonProps> = ({icon, title, type}) => {
    const navigate = useNavigate();
    const handleNavigateBack = () => {
        navigate(-1)
    }
    return (
        <div>
            <NavigateButtonStyled type={type} onClick={handleNavigateBack} icon={icon}>{title}</NavigateButtonStyled>
        </div>
    );
};

NavigateButton.defaultProps = {
    title: 'back',
    type: 'link',
    icon: <ArrowLeftIcon/>
}