import React from 'react';

export const VerifiedIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#1976D2" fillOpacity="0.3"/>
            <path
                d="M8.72797 11.1661L7.18131 9.61276L6.19464 10.6061L8.72797 13.1461L13.6213 8.23943L12.6346 7.24609L8.72797 11.1661Z"
                fill="#1976D2"/>
            <path
                d="M17.3346 9.99333L15.708 8.13333L15.9346 5.67333L13.528 5.12667L12.268 3L10.0013 3.97333L7.73464 3L6.47464 5.12667L4.06797 5.66667L4.29464 8.13333L2.66797 9.99333L4.29464 11.8533L4.06797 14.32L6.47464 14.8667L7.73464 17L10.0013 16.02L12.268 16.9933L13.528 14.8667L15.9346 14.32L15.708 11.86L17.3346 9.99333ZM14.7013 10.98L14.328 11.4133L14.3813 11.98L14.5013 13.28L12.6746 13.6933L12.3813 14.1867L11.7213 15.3067L10.5346 14.7933L10.0013 14.5667L9.47464 14.7933L8.28797 15.3067L7.62797 14.1933L7.33464 13.7L5.50797 13.2867L5.62797 11.98L5.6813 11.4133L5.30797 10.98L4.44797 10L5.30797 9.01333L5.6813 8.58L5.6213 8.00667L5.5013 6.71333L7.32797 6.3L7.6213 5.80667L8.2813 4.68667L9.46797 5.2L10.0013 5.42667L10.528 5.2L11.7146 4.68667L12.3746 5.80667L12.668 6.3L14.4946 6.71333L14.3746 8.01333L14.3213 8.58L14.6946 9.01333L15.5546 9.99333L14.7013 10.98Z"
                fill="#1976D2"/>
        </svg>

    );
};