import styled from 'styled-components';
import { devices } from '../../Theme';
import { Typography } from 'antd';
import { StyledButtonSection } from '../../Components/Button/styled';

export const GalleryCardsGrid = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  margin-left: 30px;

  @media ${devices.laptop} {
    margin-left: 30px;
  }

  @media ${devices.tablet} {
    margin-left: -35px;
  }

  @media ${devices.tabletS} {
    margin-left: 25px;
  }

  @media ${devices.mobileL} {
    justify-content: center;
    margin-left: -5px;
  }

  @media ${devices.mobile} {
    margin-left: 15px;
  }
`;

export const Header = styled.nav`
  /* position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  padding: 16px 58px; */
  /* background: white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 0 0 1px #e0e0e0; */
  /* @media ${devices.laptop} {
    padding: 16px 30px 16px 16px;
  }
  @media ${devices.tablet} {
    padding: 16px 20px 16px 10px;
  }
  @media ${devices.tabletS} {
    padding: 10px 20px 10px 10px;
  }
  @media ${devices.mobile} {
    padding: 10px 10px 10px 10px;
  } */
`;

export const HeaderOne = styled(Typography)`
  font-size: 38px;
  margin-top: 25px;
  margin-left: 90px;
  line-height: 1.5;
  font-weight: normal;
  top: 100%;
  color: black;

  @media ${devices.laptop} {
    margin-left: 40px;
  }

  @media ${devices.tablet} {
    margin-left: 25px;
  }

  @media ${devices.tabletS} {
    margin-left: 35px;
  }
  @media ${devices.mobile} {
    margin-left: 25px;
  }
`;

export const ArrowsLaptop = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${devices.tablet} {
    margin-right: 250px;
  }
  @media ${devices.tabletS} {
    margin-right: 0px;
  }
`;

export const Title = styled(Typography)`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  @media ${devices.tablet} {
    font-size: 18px;
  }
  @media ${devices.tabletS} {
    font-size: 16px;
  }
  @media ${devices.mobileL} {
    font-size: 15px;
  }
`;

export const ArrowButtonLeftLaptop = styled.div`
  font-size: 45px;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 100%;
  width: 60px;
  margin-top: 15px;
  margin-right: 100px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.6);
  @media ${devices.laptop} {
    display: flex;
  }
`;

export const ArrowButtonRightLaptop = styled.div`
  position: absolute;

  font-size: 45px;
  margin-left: 100px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 100%;
  width: 60px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.6);

  @media ${devices.laptop} {
    display: flex;
  }
`;

export const ArrowButtonLeftNonLaptop = styled.div`
  position: absolute;
  top: 110%;
  left: 0%;
  font-size: 45px;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 100%;
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.6);
  @media ${devices.laptop} {
    display: none;
    left: 85%;
    top: 70%;
  }
  @media ${devices.tablet} {
    left: 60%;
    top: 70%;
  }

  @media ${devices.tabletS} {
    top: 92%;
    left: 65%;
  }

  @media ${devices.mobile} {
    top: 108%;
    left: 60%;
  }
`;

export const ArrowButtonRightNonLaptop = styled.div`
  position: absolute;
  top: 110%;
  right: 0%;
  font-size: 45px;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 100%;
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.6);

  @media ${devices.laptop} {
    right: 2%;
    top: 70%;
    display: none;
  }

  @media ${devices.tablet} {
    right: 23%;
    top: 70%;
  }
  @media ${devices.tabletS} {
    top: 92%;
    right: 8%;
  }
  @media ${devices.mobile} {
    top: 108%;
  }
`;

export const Button = styled(StyledButtonSection)`
  align-items: center;
  /* text-transform: uppercase; */
  display: block;
  text-align: center;
  width: 300px;
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 90px;

  @media ${devices.laptop} {
    margin-left: 50px;
  }

  @media ${devices.tablet} {
    margin-left: 25px;
  }

  @media ${devices.tabletS} {
    margin-left: 35px;
    width: 200px;
  }

  @media ${devices.mobile} {
    width: 300px;
    margin-left: 25px;
  }
`;
