// Landing Page
import React from 'react';
import { LandingPageLayout } from '../../Components';
import { SectionOne } from './SectionOne';
import { ArtistSection } from './ArtistSection';
import { DocumentArtSection } from './DocumentArtSection';
import { Tabs } from '../../Components/Tabs';
import { items } from '../Gallery/Main/config';
import { AllArtLandingPage } from '../Gallery/View/AllArts';
import { Footer } from './Footer';
export const Home = () => {
  return (
    <LandingPageLayout>
      <SectionOne></SectionOne>
      <AllArtLandingPage />
      <ArtistSection />
      <DocumentArtSection />
      <Footer />
    </LandingPageLayout>
  );
};
