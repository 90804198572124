import {
    ArtLink,
    Card,
    CardImg,
    CardInfo,
    CardInfoWrap,
    CardInner, CartArtTitle,
    NotificationGrid,
    NotificationTitleArt,
    PurchaseStatus
} from './styled';
import React, {FC} from 'react';
import {INotification} from './typed';
import Button from '../Button';
import {Space} from "antd";
import moment from "moment";
import {CheckCircleOutlinedIcon, ExpandIcon, InfoOutlinedIcon, WarningAmberOutlinedIcon} from "../../Icons";
import {Link} from "react-router-dom";

interface INotificationCard {
    item: INotification;
    handleCancel: (id: string, idNot: string) => void;
    handleAccept: (id: string, idNot: string) => void;
}

export const NotificationCard: FC<INotificationCard> = ({item, handleCancel, handleAccept}) => {
    return (
        <NotificationGrid key={item.id}>
            {item.attributes.kind == 'pending_request' && (
                <Card>
                    <CardInner to={`/art/${item.attributes.artSlug}`}>
                        <CardImg src={item?.attributes?.artImage} alt=""/>
                        <Space size={[8, 8]} direction={'vertical'}>
                            <div>
                                <CartArtTitle>{item.attributes.artTitle}</CartArtTitle>
                            </div>
                            <div>
                                <CardInfo>{item.attributes.buyerName} • {moment(item.attributes.createdAt).format('h:mm, D, MMM  YYYY ')}
                                    / {item.attributes.price}$</CardInfo>
                            </div>
                        </Space>
                        <ExpandIcon/>
                    </CardInner>
                    <NotificationTitleArt>
                    </NotificationTitleArt>
                    <Space size={[8, 8]}>
                        <Button onClick={() => handleCancel(item.attributes.transferRequestId, item.id)}>
                            Decline
                        </Button>
                        <Button
                            primary="true"
                            onClick={() => handleAccept(item.attributes.transferRequestId, item.id)}
                        >
                            Confirm
                        </Button>
                    </Space>
                </Card>
            )}
            {item.attributes.kind == 'request_created' && (
                <Card>
                    <CardInner to={`/art/${item.attributes.artSlug}`}>
                        <CardImg src={item?.attributes?.artImage} alt=""/>
                        <Space size={[8, 8]} direction={'vertical'}>
                            <div>
                                <CartArtTitle>{item.attributes.artTitle}</CartArtTitle>
                            </div>
                            <div>
                                <CardInfo>{item.attributes.buyerName} • {moment(item.attributes.createdAt).format('h:mm, D, MMM  YYYY ')}
                                    / {item.attributes.price}$</CardInfo>
                            </div>
                        </Space>
                        <ExpandIcon/>
                    </CardInner>
                    <PurchaseStatus>
                        <CheckCircleOutlinedIcon/> Has been transferred successfully!
                    </PurchaseStatus>
                    {' '}

                </Card>
            )}
            {item.attributes.kind == 'buying_request_created' && (
                <Card>
                    <CardInner to={`/art/${item.attributes.artSlug}`}>
                        <CardImg src={item?.attributes?.artImage} alt=""/>
                        <Space size={[8, 8]} direction={'vertical'}>
                            <div>
                                <CartArtTitle>{item.attributes.artTitle}</CartArtTitle>
                            </div>
                            <div>
                                <CardInfo>{item.attributes.buyerName} • {moment(item.attributes.createdAt).format('h:mm, D, MMM  YYYY ')}
                                    / {item.attributes.price}$</CardInfo>
                            </div>
                        </Space>
                        <ExpandIcon/>
                    </CardInner>
                    <PurchaseStatus type={'info'}>
                        <InfoOutlinedIcon/> Purchase request sent
                    </PurchaseStatus>
                </Card>
            )}
            {item.attributes.kind == 'request_approved' && (
                <Card>
                    <CardInner to={`/art/${item.attributes.artSlug}`}>
                        <CardImg src={item?.attributes?.artImage} alt=""/>
                        <Space size={[8, 8]} direction={'vertical'}>
                            <div>
                                <CartArtTitle>{item.attributes.artTitle}</CartArtTitle>
                            </div>
                            <div>
                                <CardInfo>{item.attributes.buyerName} • {moment(item.attributes.createdAt).format('h:mm, D, MMM  YYYY ')}
                                    / {item.attributes.price}$</CardInfo>
                            </div>
                        </Space>
                        <ExpandIcon/>
                    </CardInner>
                    <CardInfoWrap>
                        <PurchaseStatus type={'success'}>
                            <CheckCircleOutlinedIcon/> Purchase request confirmed
                        </PurchaseStatus>
                        <ArtLink to={`/art/${item.attributes.artSlug}`}>go to art</ArtLink>
                    </CardInfoWrap>

                </Card>
            )}
            {item.attributes.kind == 'request_declined' && (
                <Card>
                    <CardInner to={`/art/${item.attributes.artSlug}`}>
                        <CardImg src={item?.attributes?.artImage} alt=""/>
                        <Space size={[8, 8]} direction={'vertical'}>
                            <div>
                                <CartArtTitle>{item.attributes.artTitle}</CartArtTitle>
                            </div>
                            <div>
                                <CardInfo>{item.attributes.buyerName} • {moment(item.attributes.createdAt).format('h:mm, D, MMM  YYYY ')}
                                    / {item.attributes.price}$</CardInfo>
                            </div>
                        </Space>
                        <ExpandIcon/>
                    </CardInner>
                    <PurchaseStatus type={'rejected'}>
                        <WarningAmberOutlinedIcon/> Purchase request declined
                    </PurchaseStatus>
                </Card>
            )}
            {item.attributes.kind == 'request_canceled' && (
                <Card>
                    <CardInner to={`/art/${item.attributes.artSlug}`}>
                        <CardImg src={item?.attributes?.artImage} alt=""/>
                        <Space size={[8, 8]} direction={'vertical'}>
                            <div>
                                <CartArtTitle>{item.attributes.artTitle}</CartArtTitle>
                            </div>
                            <div>
                                <CardInfo>{item.attributes.buyerName} • {moment(item.attributes.createdAt).format('h:mm, D, MMM  YYYY ')}
                                    / {item.attributes.price}$</CardInfo>
                            </div>
                        </Space>
                        <ExpandIcon/>
                    </CardInner>
                    <PurchaseStatus type={'rejected'}>
                        <WarningAmberOutlinedIcon/> Purchase request rejected
                    </PurchaseStatus>
                </Card>
            )}
        </NotificationGrid>
    );
};
