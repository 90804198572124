import { Button } from 'antd';
import styled from 'styled-components';
import { color, devices } from '../../Theme';

export const StyledButton = styled(Button)`
  color: ${(props) => (props.primary ? 'white' : color.primary)};
  border-radius: 4px;
  border: 1px solid ${(props) => (props.primary ? color.brand : color.primaryLight50p)};
  height: 42px;
  background-color: ${(props) => (props.primary ? color.brand : 'white')};
  font-weight: 500;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${color.brand};
    color: ${(props) => (props.primary ? 'white' : color.primary)};
    background: ${(props) => (props.primary ? '#1565C0' : color.primaryLight4p)};
  }

  @media ${devices.tablet} {
    font-size: 12px;
    height: 30px;
  }
`;

export const StyledButtonCurve = styled(Button)`
  color: ${(props) => (props.primary ? 'white' : color.primary)};
  border-radius: 4px;
  border: 1px solid ${(props) => (props.primary ? color.brand : color.primaryLight50p)};
  height: 42px;
  background-color: ${(props) => (props.primary ? color.brand : 'white')};
  font-weight: 500;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${color.brand};
    color: ${(props) => (props.primary ? 'white' : color.primary)};
    background: ${(props) => (props.primary ? '#1565C0' : color.primaryLight4p)};
  }

  @media ${devices.tablet} {
    font-size: 12px;
    height: 30px;
  }
`;

export const StyledButtonCurveLogIn = styled(Button)`
  border-radius: 30px;
  border: 0px solid ${(props) => (props.primary ? color.brand : color.primaryLight50p)};
  height: 50px;
  font-weight: 500;
  text-align: center;
  color: #20184d;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    border: 0px solid ${(props) => (props.primary ? color.brand : color.primaryLight50p)};
    color: #20184d;
    background-color: #fafafa;
  }

  @media ${devices.tablet} {
    font-size: 12px;
    height: 30px;
  }
`;

export const StyledButtonCurveSignUp = styled(Button)`
  color: ${(props) => (props.primary ? 'white' : color.primary)};
  border-radius: 30px;
  border: 1px solid #6951ff;
  height: 50px;
  font-weight: 500;
  text-align: center;
  color: #6951ff;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    background: #a799ff;
    border: 1px solid #6951ff;
    color: #20184d;
  }

  @media ${devices.tablet} {
    font-size: 12px;
    height: 30px;
  }
`;

export const StyledButtonSection = styled(Button)`
  border-radius: 2px;
  border: 2px solid #6951ff;
  height: 50px;
  font-weight: 500;
  text-align: center;
  color: white;
  background: black;
  font-size: 22px;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    background: #a799ff;
    border: 1px solid #6951ff;
    color: #20184d;
  }
`;
