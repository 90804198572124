import styled from 'styled-components';
import { Typography } from 'antd';
import {devices} from "../../../Theme";

export const CategoryTitle = styled(Typography.Paragraph)`
  margin-bottom: 22px;
  font-size: 18px;
  @media ${devices.mobileL} {
    margin-bottom: 15px;
    font-size: 14px;
  }
`;
