import React, { FC } from 'react';
import { FormField } from '../styled';
import { FormCategoryTitleProps } from './typed';
import { CategoryTitle } from './styled';

export const FormCategoryTitle: FC<FormCategoryTitleProps> = ({ name }) => {
  return (
    <FormField key={name}>
      <CategoryTitle>{name}</CategoryTitle>
    </FormField>
  );
};
