import React, { FC } from 'react';
import { MainContainer, MainContainerLandingPage } from './styled';
import { LayoutTypes } from './typed';
import { Header } from '../Header';
import { LandingPageHeader } from '../LandingPageHeader';

export const Layout: FC<LayoutTypes> = ({ children, type }) => {
  return (
    <>
      <Header />
      <MainContainer type={type}>{children}</MainContainer>
    </>
  );
};

export const LandingPageLayout: FC<LayoutTypes> = ({ children, type }) => {
  return (
    <>
      <LandingPageHeader />
      <MainContainerLandingPage type={type}>{children}</MainContainerLandingPage>
    </>
  );
};
