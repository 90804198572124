import {DiagramWrap, FilterBox, FilterPrice, FilterTime, LineChartBox, SpinBox, TotalPrice} from './styled';
import { TotalBox, TotalTitle } from '../PieChart/styled';
import React, { FC, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Radio, Spin, Statistic, Switch } from 'antd';
import { useGetLineChartQuery } from '../../../Redux/analytics/analyticsAPI';
import { Total } from '../types';
import CountUp from 'react-countup';

interface ILineChartComponent {
  totalObj: Total;
}

export const LineChartComponent: FC<ILineChartComponent> = ({ totalObj }) => {
  const [timeType, setTimeType] = useState<string>('day');
  const [priceType, setPriceType] = useState<boolean>(true);
  const [lineChartData, setLineChartData] = useState([]);

  const { data, isLoading, isFetching } = useGetLineChartQuery({
    scope: timeType,
    price_type: priceType ? 'current_value' : 'purchase_price'
  });

  useEffect(() => {
    if (data?.data) {
      const arr = [];
      for (const key in data.data) {
        arr.push({
          name: key,
          total: data.data[key]
        });
      }
      setLineChartData(arr);
    }
  }, [data]);

  const options = [
    {
      label: 'days',
      value: 'day'
    },
    {
      label: 'months',
      value: 'month'
    },
    {
      label: 'years',
      value: 'year'
    }
  ];
  const formatter = (value) => <CountUp end={value} separator="," />;
  return (
    <LineChartBox>
      <DiagramWrap>
        <TotalBox>
          <TotalTitle> Total Artworks: ({totalObj?.arts_count})</TotalTitle>
          {totalObj?.total && (
              <TotalPrice>
                {' '}
                Total: ${' '}
                <Statistic
                    value={priceType ? totalObj?.total : totalObj?.total_purchase_amount}
                    formatter={formatter}
                />
              </TotalPrice>
          )}
        </TotalBox>
        <FilterBox>
          <FilterPrice>
            {' '}
            Purchase price{' '}
            <Switch
                style={{ margin: '0 5px' }}
                checked={priceType}
                onChange={() => setPriceType((prev) => !prev)}
            />{' '}
            Current value{' '}
          </FilterPrice>
          <FilterTime>
            <Radio.Group
                style={{ borderRadius: '4px' }}
                options={options}
                onChange={(e) => setTimeType(e.target.value)}
                value={timeType}
                optionType="button"
            />
          </FilterTime>
        </FilterBox>
        {(isLoading || isFetching) && (
            <SpinBox>
              <Spin tip="Loading" size="small" />
            </SpinBox>
        )}
        {!isLoading && !isFetching && lineChartData?.length && (
            <ResponsiveContainer width="100%" height={358}>
              <LineChart width={541} height={330} data={lineChartData}>
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <XAxis style={{ fontSize: '13px' }} dataKey="name" />
                <YAxis style={{ fontSize: '13px' }} />
                <Tooltip />
                <Line type="monotone" dataKey="total" stroke="#3949AB" />
              </LineChart>
            </ResponsiveContainer>
        )}
      </DiagramWrap>

    </LineChartBox>
  );
};
