import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';
import { DashboardUrlBuilder } from '../query/urlBuilder/Dashboard';

export const analyticsAPI = createApi({
    reducerPath: 'analyticsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.provenart.io/api/v1/',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.accessToken;
            // if (token) {
            headers.set('authorization', `Bearer ${token}`);
            // }
            return headers;
        }
    }),
    tagTypes: ['Analytics'],
    endpoints: (build) => ({
        getPieChart: build.query({
            query: () => ({
                url: 'dashboard/pie_chart',
                method: 'GET',
            }),
        }),
        getTotal: build.query({
            query: () => ({
                url: 'dashboard/total',
                method: 'GET',
            }),
        }),
        getLineChart: build.query({
            query: ({scope, price_type}) => ({
                url: `dashboard/line_chart?scope=${scope}&price_type=${price_type}`,
                method: 'GET',
            }),
        }),
        getFinance: build.query({
            query: (arg) => ({
                url: DashboardUrlBuilder.getDashboard(arg),
                method: 'GET'
            })
        }),
    })
});

export const {
    useGetPieChartQuery,
    useGetTotalQuery,
    useGetFinanceQuery,
    useGetLineChartQuery,
} = analyticsAPI;
