import { LoadingOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';
import { UploadBtn, UploadCount, UploadStyled } from '../styled';
import { message } from 'antd';
import { getBase64 } from '../../../Utils';
import {AddImageIcon} from "../../../Icons";
import {AddNewImageBlock, AddNewImageTitle, AddNewImageWrap, UploadContainer, UploadText, UploadTitle} from "./styled";


const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  const isLt2M = file.size / 4024 / 4024 < 2;
  return false;
};

interface ImageType {
  imageArr?: string[];
  setImageArr: any;
  setStringImageArr: any;
}

export const UploadComponent: React.FC<ImageType> = ({
  imageArr,
  setImageArr,
  setStringImageArr
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    const fileSize = info.file.size / 4024 / 4024 < 2;
    if (!fileSize) {
      message.error('Image must smaller than 4MB!');
    } else {
      setLoading(true);
      getBase64(info.file)
        .then((result) => {
          setStringImageArr((prev) => [...prev, result]);
        })
        .catch((err) => {
          console.log(err);
        });
      setImageArr(info.fileList);
      setLoading(false);
    }
  };

  const uploadButton = (
    <UploadContainer>
      {loading ? <LoadingOutlined /> : <AddImageIcon />}
      <UploadTitle>Click to upload</UploadTitle>
      <UploadText>SVG, PNG, JPG or GIF (max. 4MB)</UploadText>
      <UploadText>The first image will be a cover photo</UploadText>
    </UploadContainer>
  );
  const uploadButtonType = (
    <UploadBtn>
      <UploadText>Add more </UploadText>
    </UploadBtn>
  );

  return (
      <>
        <AddNewImageWrap>
          <AddNewImageTitle>
            Images
          </AddNewImageTitle>
          <AddNewImageBlock>
            <UploadCount>{imageArr.length.toString()} out of 10</UploadCount>
          </AddNewImageBlock>
        </AddNewImageWrap>
        <UploadStyled
            listType="picture-card"
            className={imageArr.length > 0 ? 'add-btn-holder' : ''}
            multiple
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            fileList={imageArr}
            maxCount={10}
            accept={'.jpg, .jpeg, .png, .gif'}
        >
          {imageArr.length > 0 ? uploadButtonType : uploadButton}
        </UploadStyled>
      </>

  );
};
