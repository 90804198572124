import styled from 'styled-components';
import { color, devices } from '../../Theme';
import { Form, Radio } from 'antd';
import { Link } from 'react-router-dom';

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 55px 45px;
  margin: 40px auto;
  background-color: white;
  border-radius: 20px;

  button {
    height: 50px;
  }
`;

export const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  @media ${devices.tablet} {
    padding: 0 12px 0;
    svg {
      width: 120px;
    }
  }
`;

export const FormLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;
export const FormItem = styled.div`
  margin-bottom: 38px;
  text-align: center;
`;
export const FormTitle = styled.div`
  text-align: center;
  color: ${color.dark};
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
`;
export const FormDesc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.grey};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  p {
    margin-left: 4px;
    color: ${color.dark};
  }
`;

export const FormSendLink = styled.div`
  color: ${color.grey};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
`;
export const FormForgot = styled(Link)`
  display: flex;
  justify-content: center;
  color: ${color.dark};
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  text-align: center;
  margin-bottom: 92px;
`;

export const FormField = styled(Form.Item)`
  position: relative;
  margin-bottom: 8px;

  @media ${devices.mobileL} {
    margin-bottom: 25px;
  }

  label {
    color: ${color.grey};

    &::before {
      content: ''!important;
      color: ${color.grey} !important;
    }
  }

  .ant-checkbox-wrapper {
    margin-top: 11px;
    color: ${color.dark};
  }

  textarea {
    border-radius: 30px;
    padding: 10px 0 10px 12px;
  }

  .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    right: 16px;
    top: 16px;
  }
`;

export const FormFieldNew = styled(Form.Item)`
  position: relative;
  margin-bottom: 7px;
  

  label {
    color: rgba(0, 0, 0, 0.6);

    &::before {
      content: ''!important;
      color: ${color.grey} !important;
    }
  }
  .ant-form-item-label {
    height: 22px;
    position: absolute;
    top: -17px;
    left: 15px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 4px;
    background: #fff;
    width: fit-content;
    z-index: 5;
    display: block;
    overflow: visible;
  }
  .ant-form-item-control {
    position: relative;
    z-index: 4;
  }
  input {
   padding: 0;
    height: 18px;
  }

  .ant-checkbox-wrapper {
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.6);
  }

  textarea {
    border-radius: 4px;
    padding: 10px 0 10px 12px;
  }

  .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    right: 16px;
    top: 16px;
  }
`;

export const FormMediumTag = styled(Radio.Button)`
  border-radius: 30px !important;
  background: ${color.lightGrey};
  color: rgba(0, 0, 0, 0.87) !important;

  & label {
    @media ${devices.tablet} {
      font-size: 12px;
    }
  }
  
  &.ant-radio-button-wrapper-checked {
    background: ${color.brand};
    color: #fff;

    span {
      color: #fff;
    }
  }
`;
