import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from '../store';

export const ownershipAPI = createApi({
    reducerPath: 'ownershipAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.provenart.io/api/v1/',
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).user.accessToken;
            // if (token) {
            headers.set('authorization', `Bearer ${token}`);
            // }
            return headers;
        }
    }),
    tagTypes: ['ownership'],
    endpoints: (build) => ({
        getOwnershipSettings: build.query({
            query: ({page, size}) => ({
                url: `ownership_settings?page[number]=${page}&page[size]=${size}`,
                method: 'GET',
            }),
            providesTags: ['ownership']
        }),
        changeOwnershipSettings: build.mutation({
            query: ({id, body}) => ({
                url: `ownership_settings/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['ownership']
        }),
    })
});

export const {
    useGetOwnershipSettingsQuery,
    useChangeOwnershipSettingsMutation
} = ownershipAPI;
