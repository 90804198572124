import React from 'react';
import {ChatBox} from "./components/ChatBox";

const ChatRoom = () => {
    return (
        <div>
            <ChatBox/>
        </div>
    );
};

export default ChatRoom;
