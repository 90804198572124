import React, { Dispatch, FC } from 'react';
import 'swiper/css';
import { CarouselSlide, RemoveSlide, SwiperCustom } from './styled';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteArtImageMutation } from '../../Redux/query/ArtsAPI';

interface ICarousel {
  list: any;
  thumbUrl?: File | Blob | MediaSource;
  setImageArr?: Dispatch<React.SetStateAction<Array<string>>>;
  setStringImageArr?: Dispatch<React.SetStateAction<Array<string>>>;
  edit?: boolean;
  artId?: string;
}

const CarouselComponent: FC<ICarousel> = ({
  list,
  setImageArr,
  edit,
  artId,
  setStringImageArr
}) => {
  const [deleteImg] = useDeleteArtImageMutation();
  const handleRemove = (index) => {
    if (edit) {
      deleteImg({
        id: artId,
        imgId: list[index].id
      }).unwrap();
    }
    setImageArr((prev) => prev.filter((x, i) => i !== index));
    setStringImageArr((prev) => prev.filter((x, i) => i !== index));
  };

  const renderedList = list.map((item, index) => (
    <CarouselSlide key={index}>
      <RemoveSlide onClick={() => handleRemove(index)}>
        <DeleteOutlined id={item?.originFileObj?.name || item?.attributes?.filename} />
      </RemoveSlide>
      <img
        src={
          item?.attributes?.url ? item?.attributes?.url : URL.createObjectURL(item?.originFileObj)
        }
        style={{ width: '100%', objectFit: 'contain' }}
        alt={item?.attributes?.filename}
      />
    </CarouselSlide>
  ));

  return (
    <SwiperCustom
      {...list}
      navigation={true}
      modules={[Navigation]}
      slidesPerView={1}
      onSlidesLengthChange={(swiper) => swiper.slideNext()}
    >
      {renderedList}
    </SwiperCustom>
  );
};

export default CarouselComponent;
