import styled from 'styled-components';
import {color, devices} from '../../Theme';
import {Button, Typography} from 'antd';
import {NotificationIcon} from '../../Icons/NotificationIcon';
import {variant} from 'styled-system';
import {Link} from "react-router-dom";

export const NotificationBox = styled.div`
  min-width: 400px;
  display: none;
  position: fixed;
  padding: 16px;
  right: 40px;
  top: 64px;
  border-radius: 4px;
  z-index: 99;
  background: #FFFFFF;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  &.is-active {
    display: block;
    overflow: scroll;
    min-height: 20%;
    max-height: 80%;
  }

  @media ${devices.tabletS} {
    min-width: 320px;
    padding: 15px 10px;
    right: 12px;
    top: 86px;
  }
  @media ${devices.mobileL} {
    width: 320px;
    padding: 10px 8px;
    right: 12px;
    top: 66px;
  }
`;

export const NotificationHeader = styled.div`
  margin-bottom: 24px;
  padding: 16px 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  @media ${devices.tablet} {
    margin-bottom: 20px;
    padding: 10px 0 16px;
  }
  @media ${devices.mobileL} {
    margin-bottom: 15px;
    padding: 5px 0 12px;
  }
`
export const NotificationGrid = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 24px;
    margin: 24px 0;
  }

  &.is-active {
    filter: blur(3px);
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 8px;
  background: none;
  border: none;
  border-radius: 10px;
  padding: 4px 12px;
`;
export const NotificationTitle = styled(Typography)`
  color: ${color.dark};
  margin: 0;
  font-size: 24px;
  @media ${devices.tablet} {
    font-size: 20px;
  }
  @media ${devices.mobileL} {
    font-size: 16px;
  }
`;

export const NotificationNew = styled(Typography)`
  color: ${color.dark};
  margin-bottom: 24px;
  font-size: 13px;
  text-transform: uppercase;
`;

export const NotificationTitleArt = styled(Typography)`
  color: ${color.brand};
  margin: 4px 0 6px;
`;

export const DotActive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  width: 20px;
  height: 20px;
  right: -10px;
  top: -12px;
  border-radius: 64px;
  background: ${color.brand};
  color: rgba(255, 255, 255, 1);

  @media ${devices.tabletS} {
    width: 16px;
    height: 16px;
    right: -4px;
    top: -2px;
    font-size: 9px;
  }
`;
export const NotificationButton = styled(NotificationIcon)`
  cursor: pointer;
  min-width: 35px;
  width: 35px;
  @media ${devices.laptop} {
    min-width: 28px;
    width: 28px;
  }
  @media ${devices.mobile} {
    min-width: 23px;
    width: 23px;
  }
`;

export const NotificationButtonWrap = styled.div`
  position: relative;
  margin-right: 16px;
  @media ${devices.tablet} {
    margin-right: 5px;
  }
`;

export const NotificationText = styled(Typography)`
  margin: 0 7px 0;
  font-size: 15px;
  line-height: 17px;
  @media ${devices.tabletS} {
    font-size: 14px;
    line-height: 16px;
  }
  @media ${devices.mobileM} {
    font-size: 12px;
    line-height: 14px;
  }
`;


export const Card = styled.div`
  width: 100%;
  padding: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);

  @media ${devices.mobileL} {
    padding: 12px 5px;
  }
`

export const CardInner = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media ${devices.mobileL} {
    margin-bottom: 10px;
  }
`

export const CartArtTitle = styled(Typography)`
  font-size: 20px;
  line-height: 22px;
  @media ${devices.tabletS} {
    font-size: 16px;
    line-height: 18px;
  }
  @media ${devices.mobileM} {
    font-size: 15px;
    line-height: 16px;
  }
`;

export const CardImg = styled.img`
  width: 90px;
  height: 64px;
  margin-right: 16px;
  @media ${devices.mobileL} {
    width: 70px;
    height: 54px;
    margin-right: 10px;
  }
`

export const CardInfo = styled.div`
  margin-right: 16px;
  letter-spacing: 0.17px;
  line-height: 143%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  width: 225px;
  @media ${devices.mobileL} {
    margin-right: 10px;
    font-size: 13px;
    width: 190px;
  }
`


const PurchaseStatusType = variant({
    prop: 'type',
    variants: {
        success: {
            background: 'rgba(46, 125, 50, 1)',
        },
        info: {
            background: 'rgba(2, 136, 209, 1)',
        },
        rejected: {
            background: 'rgba(237, 108, 2, 1)',
        }
    }
});

export const CardInfoWrap = styled.div`
  display: flex; 
  justify-content: space-between;
`;

export const ArtLink = styled(Link)`
  border-radius: 4px;
  background: rgba(46, 125, 50, 1);
  color: rgba(255, 255, 255, 1);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin-left: 4px;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`


export const PurchaseStatus = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: rgba(46, 125, 50, 1);
  color: rgba(255, 255, 255, 1);
  padding: 8px;
  font-weight: 500;
  font-size: 16px;

  @media ${devices.mobileL} {
    font-size: 14px;
    padding: 6px;
  }

  ${PurchaseStatusType}
  svg {
    margin-right: 4px;
  }
`


export const LoaderBox = styled.div`
  min-height: 80%;
`
