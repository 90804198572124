export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = '';

    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load something...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result as string;
      // console.log(baseURL);
      resolve(baseURL);
    };
  });
};

export const acceptedFiles = '.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf, .jpg,.jpeg, .png,';
