import {endpoints} from '../../../constant';

export const artUrlBuilder = {
    getArtsByName: function ({
                                 page = 1,
                                 size,
                                 search = '',
                                 filter,
                             }: {
        page?: number;
        size?: number;
        search?: string;
        filter?: boolean;
    } = {}): string {
        return `${endpoints.arts}?page[number]=${page}&page[size]=${size}${
            search
                ? `${endpoints.arts}?sort[created_at]=asc&filter[title]=${search}&filter[name]=${search}`
                : ''
        }`;
    },
    getFavoriteArts: function ({
                                   page = 1,
                                   size,
                                   search = '',
                                   filter,
                               }: {
        page?: number;
        size?: number;
        search?: string;
        filter?: boolean;
    } = {}): string {
        return `${endpoints.arts}?page[number]=${page}&page[size]=${size}&filter[favorite]=${filter}${
            search
                ? `${endpoints.arts}?sort[created_at]=asc&filter[title]=${search}&filter[name]=${search}`
                : ''
        }`;
    },
    getArtsById: function ({
       id
   }: {
        id?: string;
    } = {}): string {
        return `${endpoints.arts}/${id}`;
    },
    getMyCollection: function ({
       id,
       page = 1,
       size,
       search = '',
       hidden = false
   }: {
        id?: string;
        page?: number;
        size?: number;
        search?: string;
        hidden?: boolean;
    } = {}): string {
        return `${endpoints.arts}/user_collection/${id}?filter[hidden]=${hidden}&page[number]=${page}&page[size]=${size}${
            search
                ? `${endpoints.arts}?sort[created_at]=asc&filter[title]=${search}&filter[name]=${search}`
                : ''
        }`;
    },
    sendArtToUser: function ({
                                 id
                             }: {
        id?: string;
    } = {}): string {
        return `${endpoints.user}/get_user/${id}`;
    },
    getArtNotifications: function () {
        return '/transfer_requests';
    },
    acceptArt: function ({
                             id
                         }: {
        id?: string;
    } = {}): string {
        return `transfer_requests/${id}/accept`;
    },
    declineArt: function ({
                              id
                          }: {
        id?: string;
    } = {}): string {
        return `transfer_requests/${id}/decline`;
    },
    cancelArt: function ({
                             id
                         }: {
        id?: string;
    } = {}): string {
        return `transfer_requests/${id}/cancel`;
    },
    likeArt: function (): string {
        return `like/`;
    }
};
