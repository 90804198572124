import styled from 'styled-components';
import {devices} from '../../Theme';
import {Typography} from "antd";

export const GalleryCardsGrid = styled.div`
  max-width: 1440px;
  margin: 0 0 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media ${devices.mobileL} {
    justify-content: center;
  }
`;
export const GallerySearchBox = styled.div`
  margin: 0 auto 32px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const GalleryBtnMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  @media ${devices.tablet} {
    font-size: 18px;
  }
  @media ${devices.tabletS} {
    font-size: 16px;
   
  }
  @media ${devices.mobileL} {
    font-size: 15px;
    
  }
`;
