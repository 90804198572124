import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {artUrlBuilder} from './urlBuilder/Art';
import {RootState} from '../store';

interface IArtSearch {
    page?: number;
    size?: number;
    search?: string;
    filter?: boolean;
    hidden?: boolean;
    id?: string;
}

interface ISingleArt {
    id: string;
}

interface ILikeArt {
    like: {
        art_id: string;
    }
}

export const artsAPI = createApi({
  reducerPath: 'artsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.provenart.io/api/v1/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.accessToken;
      // if (token) {
      headers.set('authorization', `Bearer ${token}`);
      // }
      return headers;
    }
  }),
  tagTypes: ['Arts'],
  endpoints: (build) => ({
    createArt: build.mutation({
      query: (body) => ({
        url: 'arts',
        method: 'POST',
        body
      })
    }),
    createArtDocuments: build.mutation({
      query: (props) => {
        const { body, id } = props;
        return {
          url: `arts/${id}/documents`,
          method: 'POST',
          body
        };
      }
    }),
    deleteArt: build.mutation({
      query: (props) => {
        const { id } = props;
        return {
          url: `arts/${id}`,
          method: 'DELETE'
        };
      }
    }),
    deleteArtDocuments: build.mutation({
      query: (props) => {
        const { docId, id } = props;
        return {
          url: `arts/${id}/documents/${docId}`,
          method: 'DELETE'
        };
      }
    }),
    editArtDocuments: build.mutation({
      query: (props) => {
        const { body, idDoc, idArt } = props;
        return {
          url: `arts/${idArt}/documents/${idDoc}`,
          method: 'PATCH',
          body
        };
      }
    }),
    deleteArtImage: build.mutation({
      query: (props) => {
        const { imgId, id } = props;
        return {
          url: `arts/${id}/delete_image/${imgId}`,
          method: 'DELETE'
        };
      }
    }),
    editArt: build.mutation({
      query: (props) => {
        const { body, id } = props;
        return {
          url: `arts/${id}`,
          method: 'PATCH',
          body
        };
      }
    }),
    buyArt: build.mutation({
      query: (body) => {
        return {
          url: 'transfer_requests/buy',
          method: 'POST',
          body
        };
      }
    }),
    sellArt: build.mutation({
      query: (body) => {
        return {
          url: 'transfer_requests/sell',
          method: 'POST',
          body
        };
      }
    }),
    getArtsByName: build.query({
      query: (args?: IArtSearch) => ({
        url: artUrlBuilder.getArtsByName(args),
        method: 'GET'
      }),
      providesTags: ['Arts']
    }),
    getFavoriteArts: build.query({
      query: (args?: IArtSearch) => ({
        url: artUrlBuilder.getFavoriteArts(args),
        method: 'GET'
      }),
      providesTags: ['Arts']
    }),
    getArtsById: build.query({
      query: (id?: ISingleArt) => ({
        url: artUrlBuilder.getArtsById(id),
        method: 'GET'
      }),
      providesTags: ['Arts']
    }),
    getArtDocuments: build.query({
      query: ({ id }) => ({
        url: `arts/${id}/documents`,
        method: 'GET'
      })
    }),
    getPreviousOwnersOfArt: build.query({
      query: ({artId}) => ({
        url: `arts/${artId}/previous_owners`,
        method: 'GET'
      }),
    }),
    activateDeactivateArt: build.mutation({
      query: ({artId}) => ({
          url: `/arts/${artId}/toggle_hidden`,
          method: 'PUT'
      }),
    }),
    getMyCollection: build.query({
      query: (args?: IArtSearch) => ({
        url: artUrlBuilder.getMyCollection(args),
        method: 'GET'
      }),
      providesTags: ['Arts']
    }),
    sendArtToUser: build.query({
      query: (id?: ISingleArt) => ({
        url: artUrlBuilder.sendArtToUser(id),
        method: 'GET'
      }),
      providesTags: ['Arts']
    }),
    acceptArt: build.mutation({
      query: (id?: ISingleArt) => ({
        url: artUrlBuilder.acceptArt(id),
        method: 'PATCH',
        body: id
      })
    }),
    declineArt: build.mutation({
      query: (id?: ISingleArt) => ({
        url: artUrlBuilder.declineArt(id),
        method: 'PATCH',
        body: id
      })
    }),
    cancelArt: build.mutation({
      query: (id?: ISingleArt) => ({
        url: artUrlBuilder.cancelArt(id),
        method: 'PATCH',
        body: id
      })
    }),
    likeArt: build.mutation({
      query: (arg?: ILikeArt) => ({
        url: artUrlBuilder.likeArt(),
        method: 'POST',
        body: arg
      }),
    })
  })
});

export const {
  useCreateArtMutation,
  useCreateArtDocumentsMutation,
  useDeleteArtMutation,
  useDeleteArtDocumentsMutation,
  useDeleteArtImageMutation,
  useEditArtDocumentsMutation,
  useActivateDeactivateArtMutation,
  useEditArtMutation,
  useGetArtDocumentsQuery,
  useGetPreviousOwnersOfArtQuery,
  useBuyArtMutation,
  useSellArtMutation,
  useGetArtsByNameQuery,
  useGetArtsByIdQuery,
  useGetMyCollectionQuery,
  useSendArtToUserQuery,
  useAcceptArtMutation,
  useDeclineArtMutation,
  useCancelArtMutation,
  useLikeArtMutation,
  useGetFavoriteArtsQuery
} = artsAPI;
