import styled from 'styled-components';
import { variant } from 'styled-system';
import { devices } from '../../Theme';

const GridType = variant({
  prop: 'type',
  variants: {
    profile: {
      maxWidth: '100%',
      gridGap: '50px',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      display: 'grid',
      gridTemplateColumns: '1fr'
    }
    // art: {
    //   justifyContent: 'center',
    //   alignItems: 'flex-start',
    //   display: 'grid',
    //   gridTemplateColumns: '530px 530px',
    //   gridGap: '50px',
    //   '@media screen and (max-width: 1199px)': {
    //     gridTemplateColumns: 'auto'
    //   }
    // }
  }
});

export const MainContainer = styled.div`
  padding: 27px 15px 41px 15px;
  display: flex;
  max-width: 1320px;
  margin: 0 auto;
  flex-direction: column;
  @media ${devices.laptop} {
    padding: 16px 18px;
  }
  @media ${devices.tablet} {
    padding: 16px 15px;
  }
  @media ${devices.mobileL} {
    padding: 16px 10px;
  }

  ${GridType}// form {
  //   grid-gap: 133px;
  //   justify-content: center;
  //   display: grid;
  //   grid-template-columns: 530px 530px;
  //   max-width: 1440px;
  //   @media ${devices.laptop} {
  //     grid-template-columns: auto;
  //     grid-gap: 16px;
  //   }
  // }
`;

export const MainContainerLandingPage = styled.div`
  /* padding: 27px 15px 41px 15px; */
  display: flex;
  /* max-width: 1320px; */
  margin: 0 auto;
  flex-direction: column;
  @media ${devices.laptop} {
    padding: 16px 0px;
  }
  @media ${devices.tablet} {
    padding: 16px 0px;
  }
  @media ${devices.mobileL} {
    padding: 16px 0px;
  }

  ${GridType}// form {
  //   grid-gap: 133px;
  //   justify-content: center;
  //   display: grid;
  //   grid-template-columns: 530px 530px;
  //   max-width: 1440px;
  //   @media ${devices.laptop} {
  //     grid-template-columns: auto;
  //     grid-gap: 16px;
  //   }
  // }
`;
