import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';
import { DashboardUrlBuilder } from '../query/urlBuilder/Dashboard';

export const reviewsAPI = createApi({
    reducerPath: 'reviewsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.provenart.io/api/v1/',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.accessToken;
            // if (token) {
            headers.set('authorization', `Bearer ${token}`);
            // }
            return headers;
        }
    }),
    tagTypes: ['Reviews'],
    endpoints: (build) => ({
        createReview: build.mutation({
            query: (body) => ({
                url: 'reviews',
                method: 'POST',
                body
            }),
        }),
        listReviews: build.query({
            query: ({userId, artId}) => ({
                url: `reviews?userId=${userId}&artId=${artId}`,
                method: 'GET'
            }),
        }),
    })
});

export const {
    useCreateReviewMutation,
    useLazyListReviewsQuery,
    useListReviewsQuery
} = reviewsAPI;
