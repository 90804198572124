import {TabsProps} from "antd";
import React from "react";
import {FlexCenter} from "../../Components/styled";
import {LocalLibraryOutlinedIcon, TransfersIcon} from "../../Icons";
import {Transfers} from "./Transfers";
import {MyArts} from "./MyArts";
import {InActiveArtsIcon} from "../../Icons/InActiveArtsIcon";
import {InActiveArts} from "./InActiveArts";


export const items: TabsProps['items'] = [
    {
        key: '1',
        label: (
            <FlexCenter>
                <LocalLibraryOutlinedIcon/>
                my arts
            </FlexCenter>
        ),
        children: <MyArts/>,
    },
    {
        key: '2',
        label: (
            <FlexCenter>
                <TransfersIcon/>
                transfers
            </FlexCenter>
        ),
        children: <Transfers/>,
        destroyInactiveTabPane: false
    },
    {
        key: '3',
        label: (
            <FlexCenter>
                <InActiveArtsIcon fill={'#666666'}/>
                inactive arts
            </FlexCenter>
        ),
        children: <InActiveArts/>,
        destroyInactiveTabPane: false
    },
];
