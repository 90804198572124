import {endpoints} from '../../../constant';

export const ChatsUrlBuilder = {
    getChats: function (): string {
        return `${endpoints.chats}`;
    },
    createChat: function (): string {
        return `${endpoints.chats}`;
    }
};
