import React from 'react';

export const WarningAmberOutlinedIcon = (props) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9993 5.94949L17.9018 17.8753H4.09685L10.9993 5.94949ZM10.9993 2.29199L0.916016 19.7087H21.0827L10.9993 2.29199Z"
                fill="white"/>
            <path d="M11.916 15.1253H10.0827V16.9587H11.916V15.1253Z" fill="white"/>
            <path d="M11.916 9.62533H10.0827V14.2087H11.916V9.62533Z" fill="white"/>
        </svg>

    );
};