import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from 'firebase/firestore'

export const firebaseConfig = {
    apiKey: "AIzaSyB6YtQxKaQIlPyf0iYrMg4hDmwqARwAdPY",
    authDomain: "chat2-4f030.firebaseapp.com",
    projectId: "chat2-4f030",
    storageBucket: "chat2-4f030.appspot.com",
    messagingSenderId: "371643048396",
    appId: "1:371643048396:web:ea89c0fcd9a3bc2e593d69",
    measurementId: "G-6MD88B0YVG"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)