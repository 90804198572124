import React, { useEffect, useState } from 'react';
import { forgotPassword } from '../../Redux/features/userActions';
import { Form } from 'antd';
import Button from '../../Components/Button';
import { useAppDispatch, useAppSelector } from '../../Hooks/redux';
import { UserOutlined } from '@ant-design/icons';
import { StyledInput } from '../../Components/Input/styled';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../Components/Layout/Auth';
import { FormBasic } from '../../Components';

export const EmailScreen = ({ setUser }: any) => {
  const { error, userInfo, success, loading } = useAppSelector((state) => state.user);
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo) {
      navigate('/code');
    }
    if (success) navigate('/code');
  }, [navigate, success, userInfo]);
  const sendCode = (data) => {
    if (!email) return;
    setUser({ email: email });
    dispatch(forgotPassword(data));
  };

  return (
    <AuthLayout>
      <FormBasic
        title={'Reset Code'}
        logo
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={sendCode}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please Input your email'
            }
          ]}
        >
          <StyledInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            prefix={<UserOutlined />}
            size={'md'}
          />
        </Form.Item>
        {error && <p>{error}</p>}
        <Button shape="circle" block primary="true" htmlType="submit">
          {loading ? 'loading...' : 'Send Code'}
        </Button>
      </FormBasic>
    </AuthLayout>
  );
};
