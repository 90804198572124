import React, { useEffect, useState } from 'react';
import {
  AddArtBtn,
  DropdownButton,
  DropdownItem,
  DropdownLink,
  DropdownMenu,
  Icon,
  List,
  Menu,
  MenuLabel,
  NavBar,
  Navigation,
  NavLogo,
  NavRoutes,
  UserEmail
} from './styled';
import { Dropdown, message } from 'antd';
import Logo from '../../Icons/Logo';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { getUserDetails } from '../../Redux/features';
import { RoutePrivate } from '../../Routes';
import { logout } from '../../Redux/slices';

import { ArrowDownIcon, LinesIcon, ProfileIcon, SupportOutlinedIcon } from '../../Icons';
import { SupportAgentOutlinedIcon } from '../../Icons/SupportAgentOutlined';
import { PlusLeftIcon } from '../../Icons/PlusLeftIcon';
import { Notification } from '../Notification';
import { ChatNotify } from '../Chat/Notifications';

export const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const { userInfo, accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // if (accessToken) {
    dispatch(getUserDetails());
    // }
  }, [accessToken, dispatch]);

  const logoutFromAccount = async () => {
    await message.info('You have successfully been logout out', 0.4);
    navigate('/home');
    dispatch(logout());
    window.location.reload();
  };

  const menu = (
    <DropdownMenu>
      {userInfo ? (
        <DropdownItem>
          {userInfo?.data?.attributes?.name}
          <UserEmail>{userInfo?.data?.attributes?.email}</UserEmail>
        </DropdownItem>
      ) : (
        <DropdownItem>
          <DropdownLink to={'/registration'}>Register</DropdownLink>
          <DropdownLink to={'/login'}>Sign in</DropdownLink>
        </DropdownItem>
      )}
      <DropdownItem>
        <DropdownLink to={'/help'}>
          <SupportAgentOutlinedIcon /> Help
        </DropdownLink>
        <DropdownLink to={'/privacy'}>
          <SupportOutlinedIcon />
          Privacy Policy
        </DropdownLink>
      </DropdownItem>
      {userInfo && (
        <DropdownItem>
          <DropdownLink onClick={logoutFromAccount}>Log out</DropdownLink>
        </DropdownItem>
      )}
    </DropdownMenu>
  );
  return (
    <NavBar>
      <NavLogo to={userInfo ? '/gallery' : '/home'}>
        {/* website logo (Provenart) */}
        <Logo />
      </NavLogo>
      <Menu>
        <NavRoutes>
          <RoutePrivate />
        </NavRoutes>
      </Menu>
      <Menu>
        <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
          <Icon clicked={click}></Icon>
        </MenuLabel>
        <Navigation clicked={click}>
          <List>
            <RoutePrivate />
          </List>
        </Navigation>
        {userInfo ? (
          <>
            <ChatNotify />
            <Notification />
          </>
        ) : null}
        <AddArtBtn
          primary="true"
          icon={<PlusLeftIcon />}
          onClick={() => navigate(userInfo ? '/art' : '/registration')}>
          Add art
        </AddArtBtn>
        <Dropdown overlay={menu} trigger={['click']}>
          <DropdownButton>
            {userInfo ? <ProfileIcon /> : <LinesIcon />}
            <ArrowDownIcon />
          </DropdownButton>
        </Dropdown>
      </Menu>
    </NavBar>
  );
};
