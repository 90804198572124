import styled from "styled-components";
import {Button, Modal, Typography} from "antd";
import {color, devices} from "../../../../Theme";


export const CustomModal = styled(Modal)`
  
  .ant-modal-body {
    padding: 24px;
    @media ${devices.mobileL} {
      padding: 15px 10px;
    }
  }
  
`;

export const ModalBox = styled.div`
  padding: 24px 16px 16px;
  @media ${devices.mobileL} {
    padding: 10px 0 5px;
  }
`;

export const ModalTitleWrap = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${color.grey};
  @media ${devices.mobileL} {
    padding-bottom: 10px;
  }
`;

export const ModalTitle = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding-right: 15px;
  @media ${devices.tablet} {
    font-size: 20px;
    line-height: 22px;
  }
  @media ${devices.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const CloseModalIconWrap = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const StarsWrap = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

export const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    cursor: pointer;
    margin-right: 5px;
  }
`;

export const LeaveRequest = styled(Typography)`
  color: ${color.primary};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  text-decoration: underline;
  cursor: pointer;
`;


export const TextAreaWrap = styled.div`
  position: relative;
`;

export const TextAreaText = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  top: -15px;
  left: 10px;
  padding: 1px 5px;
  background: #fff;
  width: fit-content;
  position: absolute;
  z-index: 5;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SaveReview = styled(Button)`
  text-transform: uppercase;
  text-align: center;
  height: 42px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  margin: 20px auto 0;
`;

export const MainConfirmation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 10px;
`;

export const MainConfirmationText = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const MainConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MainConfirmationButtonsWrap = styled.div`
  width: fit-content;
  display: flex;
`;

export const CancelConfirmationButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  margin-right: 8px;
  padding: 8px 22px;
  height: 42px;
`;

export const ConfirmationButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  padding: 8px 22px;
  height: 42px;
  margin-left: 8px;
`;
