import React, { useState } from 'react';
import Gallery from '../../../../Components/Gallery';
import useDebounce from '../../../../Hooks/useDebounce';
import { useGetArtsByNameQuery } from '../../../../Redux/query/ArtsAPI';
import { GallerySearchBox, Title } from '../../../../Components/Gallery/styled';
import { InputCustom } from '../../../../Components';
import { SearchOutlined } from '@ant-design/icons';
import GalleryLandingPage from '../../../../Components/GalleryLandingPage';

export const AllArts = () => {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(8);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 500);
  const handleSize = () => {
    setSize((prev) => prev + 8);
  };
  const { data, isLoading, isSuccess, isFetching } = useGetArtsByNameQuery(
    {
      page,
      size,
      search: debouncedSearch
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      skip: false
    }
  );
  return (
    <>
      <GallerySearchBox>
        <Title level={4}>All Art</Title>
        <InputCustom
          prefix={<SearchOutlined />}
          placeholder={'Search...'}
          value={search}
          type={'search'}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
      </GallerySearchBox>
      <Gallery
        countArts
        data={data}
        isSuccess={isSuccess}
        isLoading={isLoading}
        handleSize={handleSize}
        isFetching={isFetching}
      />
    </>
  );
};

export const AllArtLandingPage = () => {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(9);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 500);
  const handleSize = () => {
    setSize((prev) => prev + 8);
  };
  const { data, isLoading, isSuccess, isFetching } = useGetArtsByNameQuery(
    {
      page,
      size,
      search: debouncedSearch
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      skip: false
    }
  );
  return (
    <>
      <GalleryLandingPage
        countArts
        data={data}
        isSuccess={isSuccess}
        isLoading={isLoading}
        handleSize={handleSize}
        isFetching={isFetching}
      />
    </>
  );
};
