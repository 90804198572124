import React, {FC, useEffect, useState} from "react";
import {message, Modal, Spin} from "antd";
import {useCreateReviewMutation} from "../../../../Redux/reviews/reviewsAPI";
import {
    ButtonWrap,
    CancelConfirmationButton,
    CloseModalIconWrap,
    ConfirmationButton, CustomModal,
    MainConfirmation,
    MainConfirmationButtons,
    MainConfirmationButtonsWrap,
    MainConfirmationText,
    ModalBox,
    ModalTitle,
    ModalTitleWrap,
    SaveReview,
    StarsContainer,
    StarsWrap,
    TextAreaText,
    TextAreaWrap
} from "./styled";
import CloseModalIcon from "../../../../Icons/CloseModalIcon";
import StarRatings from 'react-star-ratings';

import TextArea from "antd/lib/input/TextArea";

interface ICreationReviewPopup {
    name: string;
    id: string;
    isModalOpen: boolean;
    setIsModalOpen: any;
}

export const CreationReviewPopup: FC<ICreationReviewPopup> = ({
                                                                  name,
                                                                  id,
                                                                  isModalOpen,
                                                                  setIsModalOpen
                                                              }) => {
    const [createReview, {data, isLoading, isSuccess}] = useCreateReviewMutation();
    const [rating, setRating] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [isConfirmModalOpen, setConfirmIsModalOpen] = useState(false);

    const createReviewHandler = async () => {
        await createReview({
            review: {
                text: description,
                rate: rating,
                user_id: id
            }
        }).unwrap();
    }

    useEffect(() => {
        if (isSuccess) {
            setIsModalOpen(false);
            message.info('Review was successfully created')
        }
    }, [isSuccess]);

    const cancelModal = () => {
        setIsModalOpen(false)
        if (description.length > 0 || rating > 0) {
            setConfirmIsModalOpen(true);
        }
    }

    const cancelConfirmationModal = () => {
        setRating(0);
        setDescription('');
        setConfirmIsModalOpen(false);
    }

    const backToCreateModal = () => {
        setConfirmIsModalOpen(false);
        setIsModalOpen(true);
    }

    return (
        <>
            {isLoading && <Spin size="large"/>}

            <CustomModal
                style={{padding: '0'}}
                open={isModalOpen}
                width={596}
                footer={null}
                closable={false}
                onCancel={() => cancelModal()}
            >
                <ModalBox>
                    <ModalTitleWrap>
                        <ModalTitle>Leave review for {name}</ModalTitle>
                        <CloseModalIconWrap onClick={() => cancelModal()}>
                            <CloseModalIcon/>
                        </CloseModalIconWrap>
                    </ModalTitleWrap>
                    <StarsWrap>
                        <StarsContainer>
                            <StarRatings
                                rating={rating}
                                starDimension={'25px'}
                                starRatedColor='#FFB400'
                                starHoverColor='#FFB400'
                                starEmptyColor='rgba(0, 0, 0, 0.23)'
                                changeRating={setRating}
                                numberOfStars={5}
                                name='rating'
                                starSpacing={'1px'}
                                svgIconPath='M13 19.2125L20.725 23.875L18.675 15.0875L25.5 9.175L16.5125 8.4125L13 0.125L9.4875 8.4125L0.5 9.175L7.325 15.0875L5.275 23.875L13 19.2125Z'
                                svgIconViewBox={'0 0 26 24'}
                            />
                        </StarsContainer>
                    </StarsWrap>
                    <TextAreaWrap>
                        <TextArea
                            value={description}
                            showCount
                            maxLength={250}
                            style={{
                                height: 140,
                                resize: 'none',
                            }}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <TextAreaText>
                            Additional comment
                        </TextAreaText>
                    </TextAreaWrap>
                    <ButtonWrap>
                        <SaveReview
                            disabled={description?.length < 10 || rating == 0}
                            type="primary"
                            onClick={() => createReviewHandler()}
                        > {isLoading ? <Spin size="large"/> : 'save review'}</SaveReview>
                    </ButtonWrap>
                </ModalBox>
            </CustomModal>
            <Modal
                style={{padding: '0'}}
                open={isConfirmModalOpen}
                width={596}
                footer={null}
                closable={false}
                onCancel={() => cancelModal()}
            >
                <ModalBox>
                    <ModalTitleWrap>
                        <ModalTitle>Leave review for {name}</ModalTitle>
                        <CloseModalIconWrap onClick={cancelConfirmationModal}>
                            <CloseModalIcon/>
                        </CloseModalIconWrap>
                    </ModalTitleWrap>
                    <MainConfirmation>
                        <MainConfirmationText>
                            You have unsaved changes. Would you like to continue?
                        </MainConfirmationText>
                    </MainConfirmation>
                    <MainConfirmationButtons>
                        <MainConfirmationButtonsWrap>
                            <CancelConfirmationButton type="primary" ghost onClick={cancelConfirmationModal}>
                                Leave without saving
                            </CancelConfirmationButton>
                            <ConfirmationButton type="primary" onClick={backToCreateModal}>
                                Continue with review
                            </ConfirmationButton>
                        </MainConfirmationButtonsWrap>

                    </MainConfirmationButtons>
                </ModalBox>
            </Modal>
        </>

    )
}
