import { KeyOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { InputCustom } from '../Components';
import { InputPassword } from '../Components/Input/styled';

export const signUpConfig = [
  {
    name: 'name',
    rules: [
      {
        required: true,
        message: 'Please Input your username!'
      },
      {
        pattern: /^[a-zA-Z0-9]+$/,
        message: 'Name can only include letters and numbers.'
      }
    ],
    placeholder: 'Username',
    prefix: <UserOutlined />,
    Component: InputCustom,
    size: 'md'
  },
  {
    name: 'email',
    rules: [
      {
        required: true,
        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
        message: 'Invalid email'
      }
    ],
    placeholder: 'Email',
    type: 'email',
    prefix: <MailOutlined />,
    Component: InputCustom,
    size: 'md'
  },
  {
    name: 'password',
    rules: [
      { required: true, message: 'Invalid password' },
      { min: 8, message: 'Password must be minimum 8 characters.' }
    ],
    placeholder: 'Password',
    type: 'password',
    prefix: <KeyOutlined />,
    Component: InputPassword
  }
];
