import styled from 'styled-components';
import { Typography } from 'antd';
import {devices} from "../../../Theme";

export const PieChartBox = styled.div`
  max-width: 630px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #e8eaf6;
  border-radius: 12px;
  margin: 0 20px 0 0;
  @media ${devices.laptop} {
    max-width: 500px;
    padding: 15px 10px;
    margin: 0 10px 0 0;
  }
  @media ${devices.tablet} {
    max-width: 100%;
    margin: 0 0 25px 0;
  }
  @media ${devices.mobileL} {
    //max-width: 600px;
    //width: 600px;
    //min-width: 600px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

export const PieChartContainer = styled.div`
  max-width: 680px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    overflow: visible;
  }
  @media ${devices.mobileL} {
    max-width: 600px;
    width: 600px;
    min-width: 600px;
    overflow-x: scroll;
    padding: 0;
  }
`;

export const TotalBox = styled.div`
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8eaf6;
  @media ${devices.mobileL} {
    max-width: 600px;
    width: 600px;
    min-width: 600px;
  }
`;

export const TotalTitle = styled(Typography)`
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  @media ${devices.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const TotalArts = styled.div`
  background: rgba(0, 0, 0, 0.04);
  letter-spacing: 0.4px;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 100%;
`;
