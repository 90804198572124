import styled from "styled-components";
import {Button, Typography} from "antd";
import {color} from "../../../Theme";

export const ModalBox = styled.div`
  padding: 24px 5px 16px;
  height: 620px;
`;

export const IconButton = styled(Typography)`
  height: 32px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 16px;
  color: ${color.brand}
`;
