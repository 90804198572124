import styled from "styled-components";
import {Typography} from "antd";
import {devices} from "../../../Theme";

export const LineChartBox = styled.div`
  max-width: 617px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #E8EAF6;
  border-radius: 12px;
  margin: 0 0 0 28px;
  @media ${devices.laptop} {
    max-width: 500px;
    padding: 15px 10px;
    margin: 0 0 0 10px;
  }
  @media ${devices.tablet} {
    max-width: 100%;
    margin: 0 0 25px;
  }
  @media ${devices.mobileL} {
    overflow-x: scroll;
  }
`;

export const DiagramWrap = styled.div`
  @media ${devices.mobileL} {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
  }
`;


export const TotalBox = styled.div`
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E8EAF6;
`;

export const TotalTitle = styled(Typography)`
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
`;

export const FilterBox = styled.div`
  padding-bottom: 18px;
  padding-top: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FilterPrice = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
`;

export const FilterTime = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TotalPrice = styled.div`
  background: rgba(0, 0, 0, 0.04);
  letter-spacing: 0.4px;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 100%;
  
  * {
    font-size: 15px;
    line-height: 166%;
  }
  
  span {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
    line-height: 166%;
    letter-spacing: 0.4px;
    font-family: 'Inter', sans-serif;
  }
`;

export const SpinBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 358px;
  
`;


