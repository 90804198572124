import { MenuProps, Modal} from "antd";
import React, {FC, useEffect, useRef, useState} from "react";
import MoreDotsIcon from "../../../../Icons/MoreDotsIcon";
import DeactivateIcon from "../../../../Icons/DeactivateIcon";
import DeleteArtIcon from "../../../../Icons/DeleteArtIcon";
import {useActivateDeactivateArtMutation, useDeleteArtMutation} from "../../../../Redux/query/ArtsAPI";

import CloseModalIcon from "../../../../Icons/CloseModalIcon";
import {ButtonCancel, ButtonSubmit, ModalButtonGroup} from "../../../Modal/TransferModal/styled";
import {useNavigate} from "react-router-dom";
import {
    CloseModalIconWrap,
    IconWrap, MenuWrap, MenuWrapItem,
    ModalBox,
    ModalTitle,
    ModalTitleWrap,
    ReviewWrap,
    ToolTipWrap
} from "./styled";
import {ActivateArtIcon} from "../../../../Icons/ActivateArtIcon";


interface DeleteDeactivateMenuProps {
    artId: string;
    title: string;
    isHidden: boolean;
}

export const DeleteDeactivateMenu:FC<DeleteDeactivateMenuProps> = ({artId, title, isHidden}) => {

    const [activeState, setActiveState] = useState<boolean>(isHidden);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteArt, {isSuccess,isLoading}] = useDeleteArtMutation();
    const navigate = useNavigate();
    const [activateDeactivateArt,
        {isLoading:isLoadingActivate,
            isSuccess:isSuccessActivate
        }] = useActivateDeactivateArtMutation();


    useEffect(()=>{
        setActiveState(isHidden);
    },[isHidden])
    const deleteArtHandle = () => {
        deleteArt({
            id: artId
        }).unwrap();
    }

    useEffect(()=>{
        if(isSuccess) {
            setIsModalOpen(false);
            navigate(`/portfolio`)
        }
    },[isSuccess])

    const activateDeactivateHandle = () => {
        activateDeactivateArt({
            artId: artId
        }).unwrap();
        setActiveState((prev)=>!prev);
        console.log('deactivate/activate')
    }

    const tooltipRef = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = (event) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
            setShowOptions(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    }
    const [showOptions, setShowOptions] = useState(false);
    return (
        <>
            <MenuWrap>
                <IconWrap onClick={()=>{setShowOptions((prev)=> !prev)}}>
                    <MoreDotsIcon />
                </IconWrap>
                {
                    showOptions &&
                    <ToolTipWrap ref={tooltipRef} className={showOptions ? "active" : ""} >
                        <MenuWrapItem onClick={()=> activateDeactivateHandle()}>
                            {activeState ? <ActivateArtIcon /> : <DeactivateIcon />}
                            { activeState ? "ACTIVATE" : 'DEACTIVATE'}
                        </MenuWrapItem>
                        <MenuWrapItem onClick={()=>setIsModalOpen(true)}>
                            <DeleteArtIcon /> DELETE
                        </MenuWrapItem>
                    </ToolTipWrap>
                }
            </MenuWrap>
            <Modal
                style={{padding: '0'}}
                open={isModalOpen}
                width={596}
                footer={null}
                closable={false}
            >
                    <ModalBox>
                        <ModalTitleWrap>
                            <ModalTitle>Delete {'“'}{title}{'"'}</ModalTitle>
                            <CloseModalIconWrap onClick={()=>setIsModalOpen(false)}>
                                <CloseModalIcon />
                            </CloseModalIconWrap>
                        </ModalTitleWrap>
                        <ReviewWrap>
                            Deleting will remove the artwork from your portfolio. You will not be able to undo this action. How would you like to continue?
                        </ReviewWrap>
                        <ModalButtonGroup>

                            <ButtonSubmit  onClick={deleteArtHandle}>
                                {isLoading ? 'loading...' : 'Delete artwork'}
                            </ButtonSubmit>
                            <ButtonCancel primary="true" onClick={handleCancel}>cancel</ButtonCancel>
                        </ModalButtonGroup>
                    </ModalBox>

            </Modal>
        </>
    )
}
