import styled from "styled-components";
import {color, devices} from "../../Theme";

export const ChatWrapper = styled.div`
  position: fixed;
  bottom: 2%;
  right: 2%;
  background: white;
  border-radius: 4px;
  width: 400px;
  height: 400px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  @media ${devices.mobileL} {
    width: 320px;
    height: 70%;
  }
`

export const Header = styled.div`
  position: relative;
  border-bottom: 1px solid ${color.lightGrey};
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.dark};
`

export const MessageWrapper = styled.div`
  padding: 8px;
`

export const Content = styled.div`
  height: 80%;
  padding: 8px;
`

export const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 16px 0;
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    max-width: 75%;
    margin-bottom: 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1;
  }
`

export const Text = styled.div`
  background: white;
  color: ${color.dark};
  padding: 16px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-bottom: 8px;

  &.sent {
    align-self: flex-end;
    background: rgba(0, 0, 0, 0.04);
  }

  &.received {
    align-self: flex-start;
  }
  @media ${devices.tablet} {
    padding: 12px 6px;
    font-size: 14px;
    line-height: 16px;
  }
  @media ${devices.mobileL} {
    padding: 12px 6px;
    font-size: 13px;
    line-height: 15px;
  }
`
export const Time = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
`

export const Typing = styled.span`
  display: flex;
  justify-content: flex-end;
  color: ${color.grey};
  font-size: 12px;
  font-weight: 400;
`

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  color: ${color.dark};
  border-top: 1px solid ${color.lightGrey};
  padding: 8px;
  font-size: 16px;
  font-weight: 500;

  form {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 0 !important;
  }
`
export const Close = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`
