import React from 'react';

const DeactivateIcon = (props) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.10797 2.1075L1.04297 3.165L2.74547 4.8675C1.95797 6.0525 1.50047 7.47 1.50047 9C1.50047 13.14 4.86047 16.5 9.00047 16.5C10.5305 16.5 11.948 16.0425 13.133 15.255L14.8355 16.9575L15.893 15.9L2.10797 2.1075ZM9.00047 15C5.69297 15 3.00047 12.3075 3.00047 9C3.00047 7.89 3.30797 6.855 3.84047 5.955L12.0455 14.16C11.1455 14.6925 10.1105 15 9.00047 15ZM5.95547 3.84L4.86797 2.745C6.05297 1.9575 7.47047 1.5 9.00047 1.5C13.1405 1.5 16.5005 4.86 16.5005 9C16.5005 10.53 16.043 11.9475 15.2555 13.1325L14.1605 12.0375C14.693 11.145 15.0005 10.11 15.0005 9C15.0005 5.6925 12.308 3 9.00047 3C7.89047 3 6.85547 3.3075 5.95547 3.84Z" fill="black" fillOpacity="0.54"/>
        </svg>
    );
};

export default DeactivateIcon;
