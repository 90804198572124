

export const getMonthYear = (date: string) => {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const dateM =  new Date(Date.parse(date));
    return month[dateM.getMonth()]+' '+dateM.getFullYear();
};

export const getDayMonthYear = (date: string) => {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const dateM =  new Date(Date.parse(date));
    return dateM.getDate()+' '+month[dateM.getMonth()]+' '+dateM.getFullYear();
};
