import styled from 'styled-components';
import {Checkbox, Typography} from "antd";
import {color, devices} from "../../../Theme";

export const AddNewDocumentBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const AddNewDocument = styled(Typography)`
  letter-spacing: 0.46px;
  color: ${color.brand};
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  svg {
    margin-right: 3px;
    fill: ${color.brand};
  }
`;

export const AddNewArtWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;
`;

export const AddNewArtContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media ${devices.tablet} {
    flex-wrap: wrap;
  }
  @media ${devices.mobileL} {
    overflow-x: hidden;
  }
`;

export const InputWrapContainerRight = styled.div`
  width: 50%;
  padding-left: 12px;
  @media ${devices.tabletS} {
    width: 100%;
    padding-left: 0;
    margin-bottom: 10px;
  }
`;
export const InputWrapContainerLeft= styled.div`
  width: 50%;
  
  : 12px;
  @media ${devices.tabletS} {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
`;

export const AddNewArtTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 23px;
  @media ${devices.mobileL} {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
`;

export const ArtAddTitle = styled(Typography)`
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 34px;
  line-height: 36px;
  @media ${devices.laptop} {
    font-size: 28px;
    line-height: 30px;
  }
  @media ${devices.tablet} {
    font-size: 24px;
    line-height: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const PurchasePrice = styled(Typography)`
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 12px;
`;

export const AvailableForChat = styled(Typography)`
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 14px;
  margin-right: 10px;
`;

export const PrivateCheckbox = styled(Checkbox)`
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 12px;
  span{
    font-size: 12px;
    line-height: 12px;
  }
`;

export const AddNewArtFormWrapImage = styled.div`
  width: 100%;
  max-width: 600px;
  margin-right: 20px;
  @media ${devices.laptop} {
    max-width: 500px;
  }
  @media ${devices.tablet} {
    max-width: 580px;
    margin: 0 auto 30px;
  }
`;

export const AddNewArtFormWrap = styled.div`
  width: 100%;
  max-width: 610px;
  @media ${devices.laptop} {
    max-width: 500px;
  }
  @media ${devices.tablet} {
    max-width: 100%;
  }
`;

export const PrivateNotesBar = styled.div`
  padding-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const PrivateNotesTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  line-height: 133.4%;
  margin-bottom: 15px;
`;

export const PrivateNotesSubTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin-bottom: 30px;
`;


