import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../../store';
import {ChatsUrlBuilder} from "../urlBuilder/Chat";


interface ICreateChat {
    ownership_history_id: string,
    title: string,
    user2_id: string
}

export const chatsAPI = createApi({
    reducerPath: 'chatsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.provenart.io/api/v1/',
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).user.accessToken;
            console.log(token);
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            localStorage.setItem('userToken', JSON.stringify(token));
            return headers;
        }
    }),
    tagTypes: ['Chats'],
    endpoints: (build) => ({
        getChats: build.query({
            query: (args?) => ({
                url: ChatsUrlBuilder.getChats(),
                method: 'GET'
            }),
            providesTags: ['Chats']
        }),
        createChat: build.mutation({
            query: (args?: ICreateChat) => ({
                url: ChatsUrlBuilder.createChat(),
                method: 'POST',
                body: {chat: args}
            }),
            invalidatesTags: ['Chats']
        }),
        sendChatNotification: build.mutation({
            query: (args?) => ({ 
                url: '/chats/chat_notify', 
                method: 'POST', 
                body: {userId: args}
            }), 

        }),
    })
});

export const {useGetChatsQuery, useCreateChatMutation, useSendChatNotificationMutation} = chatsAPI;
