import { endpoints } from '../../../constant';

export const DashboardUrlBuilder = {
  getDashboard: function ({
    page = 1,
    size
  }: {
    page?: number;
    size?: number;
  } = {}): string {
    return `${endpoints.dashboard}/table?page[number]=${page}&page[size]=${size}`;
  }
};
