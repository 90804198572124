import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, message, Modal, Upload, UploadFile } from 'antd';
import { Doc } from '../../../../Pages/art/View/SingleArt/typed';
import { FormField } from '../../../Form/styled';
import { StyledInput } from '../../../Input/styled';
import { ArrowDownOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { acceptedFiles, getBase64 } from '../../../../Utils';
import {ModalDocumentBox, ModalEditTitle, ModalButtonGroup, ButtonCancel, CustomModal} from './styled';
import { useEditArtDocumentsMutation } from '../../../../Redux/query/ArtsAPI';
import { UploadProps } from 'antd/es/upload/interface';
import {EditImageModal} from "../../../Upload/EditImageModal";
import {RcFile} from "antd/lib/upload/interface";

interface EditDocModalProps {
  open: boolean;
  doc: Doc;
  onCancel: () => void;
  refetch: () => void;
  setObject: any;
  indexOfDoc: number;
}

export const EditDocModal: FC<EditDocModalProps> = ({ open, doc, onCancel, refetch, setObject, indexOfDoc }) => {
  const [newDoc, setNewDoc] = useState<Doc>(null);
  const [editArtDoc, { isLoading, isSuccess }] = useEditArtDocumentsMutation();
  const [docStringArr, setDocStringArr] = useState<string[]>([]);
  const [contentType, setContentType] = useState<string>('');
  const [format, setFormat] = useState<string>('');
  const [uploadNewFile, setUploadNewFile] = useState<boolean>(false);


  useEffect(() => {
    if (doc?.fileString) {
      setNewDoc({...doc})
      const splitString = doc.contentType.split('/');
      setContentType(splitString[0]);
      setFormat(splitString[1]);
      if(splitString[0] == 'image'){
        setNewDoc((prev) => {
          const NewDoc = {...prev};
          NewDoc.prefixImageString = `data:image/${splitString[1]};base64,`
          return {...NewDoc};
        });
      }
    }
  }, [doc]);
  // console.log('newDoc 2', doc, newDoc);

  const closeModal = () => {
    // setNewDoc(null);
    onCancel();
  };

  const handleUpload = (e) => {
    setNewDoc((prev) => {
      const NewDoc = {...prev};
      // NewDoc.prefixImageString = '';
      NewDoc.file = e.file;
      NewDoc.fileName = e.file.name;

      // console.log('NewDoc',NewDoc);
      return {...NewDoc};
    });
    setUploadNewFile(true);
  };

  const handleRemoveFile = (file, index: number) => {
    setNewDoc(prev=> {
      const newArr = prev;
      newArr.file = null;
      newArr.fileString = doc.fileString;
      newArr.fileList = [];
      return {...newArr};
    });
    setUploadNewFile(false);
  }

  const handleBeforeUpload = (file, fileList) => {
    // console.log('Custom parameter:', file);
    const splitString = file.type.split('/');

    setContentType(splitString[0]);
    setFormat(splitString[1]);

    getBase64(file)
        .then((result) => {

          setNewDoc(prev=> {
            const NewDoc = prev;
            if(splitString[0] == 'image'){
              NewDoc.prefixImageString = '';
              NewDoc.contentType = file.type;
              NewDoc.fileString = result as string;
            }
            NewDoc.file = new File([file], file.name, {type: file.type});
            NewDoc.fileName = file.name;
            NewDoc.fileList = fileList;
            return {...NewDoc};
          })
        })
        .catch((err) => {
          console.log(err);
        });

    return false;
  };


  const handleEdit = useCallback(() => {
    if (newDoc) {
      editArtDoc({
        body: {
          document: {
            title: newDoc.title,
            description: newDoc.description,
            image: newDoc.fileString,
            fileName: newDoc.fileName
          }
        },
        idArt: doc.artId,
        idDoc: doc.id
      }).unwrap();
      setObject(prev=> {
        const newArr = prev;
        newArr[indexOfDoc].redacted = true;
        return [...newArr];
      })
      closeModal();

    }
  }, [newDoc, doc, indexOfDoc]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onCancel();
      message.info('Document was updated!');
    }
  }, [isSuccess]);

  const handleUpdateName = (index:number, newName: string, fileList ) => {
    const file = fileList[0];
    const fileContent = file.originFileObj as Blob;
    const newFile = new File([fileContent], newName, { type: file.type }) as RcFile;
    newFile.uid = file.uid as any;

// Update the corresponding UploadFile object
    const updatedFile = {
      ...fileList[index],
      name: newName,
      originFileObj: newFile,
    };
// Update the fileList state
    const updatedFileList = [updatedFile];


    setNewDoc(prev=> {
      const NewDoc = prev;
      NewDoc.file = new File([NewDoc.file], newName, {type: NewDoc.file.type});
      NewDoc.fileName = newName;
      NewDoc.fileList = updatedFileList;
      return {...NewDoc};
    })

  };

  return (
    <CustomModal open={open} footer={null} onCancel={closeModal}>
      {newDoc?.url && (
        <ModalDocumentBox style={{}}>
          <ModalEditTitle>Update document: {newDoc.title} </ModalEditTitle>
          <FormField label="Document title">
            <StyledInput
              onChange={(e) => setNewDoc((prev) => ({ ...prev, title: e.target.value }))}
              value={newDoc.title}
              placeholder={'Title of your document'}
            />
          </FormField>

          <FormField label="Document description">
            <TextArea
              onChange={(e) => setNewDoc((prev) => ({ ...prev, description: e.target.value }))}
              rows={2}
              value={newDoc.description}
              placeholder={'Describe the uploaded document'}
            />
          </FormField>
          {
            !uploadNewFile &&
              <a href={newDoc?.url} rel="noreferrer" target="_blank" style={{ marginRight: '15px' }}>
                <ArrowDownOutlined />
                {newDoc?.fileName}
              </a>
          }

          <Upload
            beforeUpload={(file, fileList) => handleBeforeUpload(file, fileList)}
            onRemove={(file)=>handleRemoveFile(file,indexOfDoc)}
            fileList={newDoc.fileList}
            accept={acceptedFiles}
            onChange={(e) => handleUpload(e)}
            maxCount={1}
            multiple={false}
          >
            <Button shape={'round'} icon={<UploadOutlined />}>
              Update File
            </Button>
          </Upload>
          {
            contentType == 'image' && newDoc?.fileString?.length > 0 &&
              <EditImageModal
                  file={newDoc?.prefixImageString+newDoc?.fileString}
                  index={0}
                  object={newDoc}
                  setDocStringArr={setDocStringArr}
                  setObject={setNewDoc}
                  edit={false}
                  fileName={newDoc.fileName}
                  changeNameOfFile={handleUpdateName}
                  newFile={uploadNewFile}
              />
          }
          <ModalButtonGroup>
            <Button onClick={handleEdit}>{isLoading ? 'Updating...' : 'Update'}</Button>
            <ButtonCancel onClick={closeModal}>cancel</ButtonCancel>
          </ModalButtonGroup>
        </ModalDocumentBox>
      )}
    </CustomModal>
  );
};
