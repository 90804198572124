import React, { FC, useEffect, useState } from 'react';
import { PopupProps } from './typed';
import {
  ButtonCancel,
  ButtonSubmit,
  CustomModal,
  ModalBox,
  ModalButtonGroup,
  Modaltitle
} from './styled';
import { CloseModalIconWrap, ModalDesc, ModalHeader } from '../TransferModal/styled';
import CloseModalIcon from '../../../Icons/CloseModalIcon';
import { useBuyArtMutation } from '../../../Redux/query/ArtsAPI';
import { FormInputRow } from '../../Form/FormInputGroup/styled';
import { Col } from 'antd';
import { FormFieldNew } from '../../Form/styled';
import { StyledInputNumberNew } from '../../Input/styled';
import { PrivateCheckbox } from '../../../Pages/art/Create/styled';

export const BuyArtModal: FC<PopupProps> = ({
  open,
  onCancel,
  title,
  price,
  artId,
  sellerId,
  buySuccess
}) => {
  const [transferPrice, setTransferPrice] = useState<number>(price);
  const [priceAgree, setPriceAgree] = useState<boolean>(false);

  const [buyArt, { isLoading, isSuccess }] = useBuyArtMutation();
  const handleBuyArt = async () => {
    const transfer = {
      transfer_request: {
        price: transferPrice,
        art_id: artId,
        seller_id: sellerId
      }
    };
    const res = await buyArt(transfer);
    onCancel();
    if ('data' in res) {
      if (res?.data?.data?.attributes?.status == 'pending') {
        buySuccess();
      }
    }
  };

  useEffect(() => {
    if (!transferPrice?.toString()?.length) {
      setPriceAgree(false);
    }
  }, [transferPrice]);

  const disableSubmitButton = priceAgree && transferPrice?.toString()?.length && !isLoading;

  return (
    <CustomModal width={620} open={open} footer={null} closable={false} onCancel={onCancel}>
      <ModalHeader>
        <Modaltitle>
          Send purchase request for {'"'}
          {title}
          {'"'}
        </Modaltitle>
        <CloseModalIconWrap onClick={() => onCancel()}>
          <CloseModalIcon />
        </CloseModalIconWrap>
      </ModalHeader>
      <ModalBox>
        <ModalDesc>
          If you wish to purchase the artwork for the current price, agree before “Send”. If you
          want to bid for a different price, update the price first.
        </ModalDesc>
        <ModalDesc>
          Your current price is
          {price ? <b> ${price.toLocaleString()}</b> : <b> private</b>}.
        </ModalDesc>
      </ModalBox>
      <FormInputRow style={{ width: '100%', maxWidth: '320px', margin: '0 auto' }}>
        <Col flex={1}>
          <FormFieldNew
            required
            label="Your bidding price"
            rules={[
              { required: true, message: 'please type price!' },
              { min: 0, message: `price can't be less 0` }
            ]}>
            <StyledInputNumberNew
              defaultValue={price}
              min={0}
              step={1}
              value={transferPrice}
              max={1000000000}
              onChange={(value) => setTransferPrice(value)}
              name="price"
              stringMode
              placeholder={price}
            />
          </FormFieldNew>
          <PrivateCheckbox
            checked={priceAgree}
            value="price"
            onChange={(e) => setPriceAgree(e.target.checked)}>
            Yes, I agree to purchase it for this price.
          </PrivateCheckbox>
        </Col>
      </FormInputRow>
      <ModalButtonGroup>
        <ButtonCancel onClick={onCancel}>cancel</ButtonCancel>
        <ButtonSubmit disabled={!disableSubmitButton} primary="true" onClick={handleBuyArt}>
          {isLoading ? 'loading...' : 'send'}
        </ButtonSubmit>
      </ModalButtonGroup>
    </CustomModal>
  );
};
