import React, {FC, useEffect, useState} from 'react';
import {Spin} from 'antd';
import {useAcceptArtMutation, useCancelArtMutation, useDeclineArtMutation} from '../../Redux/query/ArtsAPI';

import {
    CloseButton,
    DotActive,
    LoaderBox,
    NotificationBox,
    NotificationButton,
    NotificationButtonWrap,
    NotificationGrid,
    NotificationHeader,
    NotificationNew,
    NotificationTitle
} from './styled';
import {
    useLazyGetNotificationsQuery,
    useReadNotificationMutation
} from '../../Redux/query/notifications/notificationApi';
import {NotificationCard} from './NotificationCard';
import {INotification} from './typed';
import {GalleryBtnMore} from "../Gallery/styled";
import Button from "../Button";

export const Notification: FC = () => {
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(true);

    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(4);
    const [getNotification, {data: lazyNotif, isLoading, isFetching}] =
        useLazyGetNotificationsQuery();

    const [readNotification, {isSuccess}] = useReadNotificationMutation();

    const [acceptArtStatus, {isLoading: isLoadingAccept, isSuccess: isSuccessAccept}] =
        useAcceptArtMutation();
    const [declineArtStatus, {isLoading: isLoadingDecline, isSuccess: isSuccessDecline}] =
        useDeclineArtMutation();
    const [cancelArtStatus, {isLoading: isLoadingCancel, isSuccess: isSuccessCancel}] =
        useCancelArtMutation();


    const handleSize = () => {
        setSize((prev) => prev + 4);
    };
    const handleAccept = (id: string, idNot: string) => {
        console.log(id);
        acceptArtStatus({id}).unwrap();
        readNotification({id: idNot}).unwrap();
    };

    const handleDecline = (id: string, idNot: string) => {
        declineArtStatus({id}).unwrap();
        readNotification({id: idNot}).unwrap();
    };

    const closeModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isSuccessCancel || isSuccessAccept || isSuccessDecline) {
            setTimeout(() => {
                getNotification({size, page}).unwrap();
            }, 450);
        }
    }, [isSuccessCancel, isSuccessAccept, isSuccessDecline]);

    useEffect(() => {
        getNotification({size, page}).unwrap();
    }, [size, page]);

    useEffect(() => {
        if (lazyNotif?.data) {
            console.log(lazyNotif.data);
            setNotifications(lazyNotif.data);
        }
    }, [lazyNotif]);

    useEffect(() => {
        if (!open && notifications.length > 0) {
            notifications.forEach((notification: INotification) => {
                if (
                    notification.attributes.kind != 'pending_request' &&
                    notification.attributes.read == false
                ) {
                    readNotification({id: notification.id}).unwrap();
                }
            });
            getNotification({size, page}).unwrap();
        }
    }, [open, notifications, size, page]);

    const handleCancel = (id) => {
        cancelArtStatus({id}).unwrap();
    };

    const handleNotification = () => {
        if (!open) getNotification({size, page}).unwrap();
        setOpen((prev) => !prev);
    };

    useEffect(() => {
        if (open) getNotification({size, page}).unwrap();
    }, [open, size, page]);

    useEffect(() => {
        if (lazyNotif?.meta?.unreadCount > 0) {
            setIsActive(false);
        }
    }, [notifications, isActive]);

    return (
        <>
            <NotificationBox className={open && 'is-active'}>
                <NotificationHeader>
                    <NotificationTitle>Notifications</NotificationTitle>
                </NotificationHeader>
                <NotificationGrid>
                    <CloseButton onClick={() => closeModal()}>X</CloseButton>
                    {!notifications.length && !isFetching && !isLoading ? <h1>Nothing to show yet</h1> :
                        <NotificationNew>New</NotificationNew>}
                    {isLoading || (isFetching && <LoaderBox><Spin size="small"/></LoaderBox>)}
                    {!isFetching &&
                        !isLoading &&
                        notifications.length > 0 &&
                        notifications?.map((item) => (
                            <NotificationCard
                                key={item.id}
                                item={item}
                                handleCancel={handleDecline}
                                handleAccept={handleAccept}
                            />
                        ))}
                    {notifications.length && !isFetching ? (
                        <GalleryBtnMore>
                            <Button onClick={handleSize}>{isFetching || isLoading ? 'loading...' : 'more'}</Button>
                        </GalleryBtnMore>
                    ) : null}
                </NotificationGrid>
            </NotificationBox>
            <NotificationButtonWrap>
                {lazyNotif?.meta?.unreadCount > 0 && <DotActive>{lazyNotif?.meta?.unreadCount}</DotActive>}
                <NotificationButton className={'is-active'} onClick={handleNotification}/>
            </NotificationButtonWrap>
        </>
    );
};
