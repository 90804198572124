// Landing Page
import React, { useEffect, useState } from 'react';
import { HeaderOne, Button, Container, ItemGallery, Item, ItemImage, ItemText } from './styled';
import royLichtenstein from '../../../Img/roy_lichtenstein_website.svg';
import keithHaring from '../../../Img/keith_haring_website.svg';
import basqiat from '../../../Img/basquiat_website.svg';
import andyWarhol from '../../../Img/andy_warhol_website.svg';
import christo from '../../../Img/christo_website.svg';
import { useNavigate } from 'react-router-dom';
export const ArtistSection = () => {
  const items = [
    { id: 1, image: royLichtenstein, text: 'Roy Lichtenstein' },
    { id: 2, image: keithHaring, text: 'Keith Haring' },
    { id: 3, image: basqiat, text: 'Basquiat' },
    { id: 4, image: andyWarhol, text: 'Andy Warhol' },
    { id: 5, image: christo, text: 'Christo' }
  ];
  const navigate = useNavigate();
  return (
    <Container>
      <HeaderOne>Artists Featured On Provenart</HeaderOne>
      <ItemGallery>
        {items.map((item) => (
          <Item key={item.id}>
            <ItemImage src={item.image} alt={item.text} onClick={() => navigate('/gallery')} />
            <ItemText>{item.text}</ItemText>
          </Item>
        ))}
      </ItemGallery>
      <Button primary="true" onClick={() => navigate('/gallery')}>
        Explore Artworks
      </Button>
    </Container>
  );
};
