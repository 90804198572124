import React, { FC } from 'react';
import {
  GalleryCardsGrid,
  ArrowButtonLeftNonLaptop,
  ArrowButtonRightNonLaptop,
  ArrowButtonLeftLaptop,
  ArrowButtonRightLaptop,
  HeaderOne,
  Button,
  Header,
  ArrowsLaptop
} from './styled';
import { Empty, Spin } from 'antd';
import { IGalleryProps } from './typed';
import { EmptyGalleryIcon } from '../../Icons';
import { GalleryCardItem } from './GalleryCard';
import { FlexCenter } from '../styled';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GalleryLandingPage: FC<IGalleryProps> = ({
  data,
  isLoading,
  isSuccess,
  handleSize,
  isFetching,
  children,
  countArts,
  favorite
}) => {
  const artLength = countArts && data?.links?.next;
  const artLengthEmpty = data?.data.length > 0;
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleClickPrev = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  const handleClickNext = () => {
    if (currentSlide < data?.data.length - getVisibleArtItems()) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };
  const getVisibleArtItems = () => {
    const windowInnerWidth = window.innerWidth;
    if (windowInnerWidth > 624 && windowInnerWidth <= 1024) {
      // Tablet screen size or larger
      return 2;
    } else if (windowInnerWidth <= 768) {
      // Mobile screen size
      return 1;
    } else {
      return 3;
    }
  };

  const [visibleArtItems, setVisibleArtItems] = useState(getVisibleArtItems());
  useEffect(() => {
    const handleResize = () => {
      setVisibleArtItems(getVisibleArtItems()); // Update visible art items on resize
      setCurrentSlide(0); // Reset current slide on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentSlide(0); // Reset current slide whenever visible art items change
  }, [visibleArtItems]);
  const navigate = useNavigate();
  return (
    <>
      {children}
      <Header>
        <HeaderOne>Artworks</HeaderOne>
        <ArrowsLaptop>
          <ArrowButtonLeftLaptop onClick={handleClickPrev}>{'<'}</ArrowButtonLeftLaptop>
          <ArrowButtonRightLaptop onClick={handleClickNext}>{'>'}</ArrowButtonRightLaptop>
        </ArrowsLaptop>
      </Header>
      {(isLoading || isFetching) && (
        <FlexCenter>
          <Spin tip="Loading" size="small" />
        </FlexCenter>
      )}
      <GalleryCardsGrid>
        {isSuccess &&
          data?.data
            .slice(currentSlide, currentSlide + visibleArtItems)
            .map((item) => <GalleryCardItem favorite={favorite} item={item} key={item.id} />)}
      </GalleryCardsGrid>
      <ArrowButtonLeftNonLaptop onClick={handleClickPrev}>{'<'}</ArrowButtonLeftNonLaptop>
      <ArrowButtonRightNonLaptop onClick={handleClickNext}>{'>'}</ArrowButtonRightNonLaptop>
      <Button primary="true" onClick={() => navigate('/gallery')}>
        View Artworks
      </Button>
    </>
  );
};

export default GalleryLandingPage;
