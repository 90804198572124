import React from 'react';
import { FormField } from '../Form/styled';
import { StyledInput } from './styled';
import { IInputProps } from './typed';

export const InputCustom = ({
  type,
  size,
  placeholder,
  prefix,
  allowClear,
  onChange,
  name,
  label,
  rules,
  disabled,
  isHiddenInput,
  value
}: IInputProps) => {
  return (
    <FormField
      key={name}
      label={label}
      name={name}
      rules={rules}
      hidden={name == 'series' && isHiddenInput}
    >
      <StyledInput
        value={value}
        type={type}
        size={size}
        onChange={onChange}
        allowClear={allowClear}
        placeholder={placeholder}
        prefix={prefix}
        disabled={disabled}
      />
    </FormField>
  );
};
