import React, {FC, useEffect, useRef, useState} from 'react';
import {
    ChatWrapper,
    Close,
    Content,
    ContentWrapper,
    Footer,
    Header,
    LoaderBox,
    Message,
    MessageWrapper,
    Text,
    Time
} from "./styled";
import {Input, InputRef, Spin} from "antd";
import {IChatProps} from "./typed";
import CloseModalIcon from "../../Icons/CloseModalIcon";
import {db} from "./firebase";
import {addDoc, collection, doc, onSnapshot, orderBy, query} from 'firebase/firestore';
import Button from "../Button";
import {getAuth, signInAnonymously} from 'firebase/auth';
import {updateDoc} from "@firebase/firestore";
import {useAppSelector} from "../../Hooks";
import moment from "moment/moment";
import { useSendChatNotificationMutation } from '../../Redux/query/chats/chatsAPI';

const Chat: FC<IChatProps> = ({visible, closePopup, externalID, isLoading, ownerID}) => {
    const {userInfo} = useAppSelector((state) => state.user);
    const chatTxt = useRef<InputRef>(null);
    const [input, setInput] = useState('');
    const [date, setDate] = useState(new Date());
    const [messages, setMessages] = useState([]);
    //const [notifiedUsers, setnotifiedUsers] = useState<string[]>([]);
    const [sendChatNotificationMutation, { data: dataNofitication, isSuccess: isSuccessNotification, isLoading: isLoadingChatNotification }] =
  useSendChatNotificationMutation();
    const scroll = useRef(null);
    const auth = getAuth();

    signInAnonymously(auth)
        .then(() => {
            console.log("Signed in successful");
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
        });

    const sendMessage = async (e) => {
        e.preventDefault()
        if (input === '') {
            alert('Please enter a valid message')
            return
        }
        const diffDate = date instanceof Date ? ((new Date()).getTime() - date.getTime()) / (3.6 * 10 ** 6) : Infinity
        if (diffDate > 24){
            sendChatNotificationMutation({user2_id: ownerID})
        }
        const newDate = new Date();
        const {uid} = auth.currentUser
        await addDoc(collection(db, `conversations/${externalID}/messages`), {
            externalId: externalID,
            text: input,
            isRead: false,
            ownerID: userInfo?.data?.id,
            createdAt: newDate,
            updatedAt: newDate,
            uid,
            timestamp: newDate,
        })
        const lastMessUpdate = doc(db, 'conversations', externalID)
        const data = {
            timestamp: newDate,
            lastMessage: {
                externalId: externalID,
                text: input,
                ownerID: userInfo?.data?.id,
                isRead: false,
                uid,
                timestamp: newDate
            },
            isRead: false,
        }
        await updateDoc(lastMessUpdate, data).then(r => console.log(r))
        setInput('')
        scroll.current.scrollIntoView({behavior: 'smooth'})
    }
    
    useEffect(() => {
        const q = query(collection(db, `conversations/${externalID}/messages`), orderBy('timestamp'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = [];
            querySnapshot.forEach((doc) => {
                messages.push({...doc.data(), id: doc.id});
            });
            setMessages(messages);
            setDate(messages.length && new Date(messages[messages?.length - 1]?.timestamp?.seconds * 1000 + messages[messages?.length - 1]?.timestamp?.nanoseconds / 1000000))
        });
        return () => unsubscribe();
    }, [externalID]);

    //setDate(messages.length && new Date(messages[messages?.length - 1]?.timestamp?.seconds * 1000 + messages[messages?.length - 1]?.timestamp?.nanoseconds / 1000000))
    //const date = messages.length && new Date(messages[messages?.length - 1]?.timestamp?.seconds * 1000 + messages[messages?.length - 1]?.timestamp?.nanoseconds / 1000000)
    
    return (
        <ChatWrapper hidden={!visible}>
            <Header>
                Chat
                <Close onClick={closePopup}><CloseModalIcon/></Close>
            </Header>
            <Content>
                <ContentWrapper>
                    {isLoading && <LoaderBox><Spin/></LoaderBox>}
                    <Time>{moment(date).format('Do MMM h:mm')}</Time>
                    <MessageWrapper>
                        {messages &&
                            messages?.map((message) => {
                                return (
                                    <Message key={message.id}>
                                        <Text message={message}
                                              className={`${message.uid === auth.currentUser.uid ? "sent" : "received"}`}>
                                            {message.text}
                                        </Text>
                                        <span ref={scroll}></span>
                                    </Message>
                                )
                            })}
                    </MessageWrapper>

                </ContentWrapper>
            </Content>
            <Footer>
                <form onSubmit={sendMessage}>
                    <Input ref={chatTxt} size={'large'} value={input} onChange={(e) => setInput(e.target.value)}
                           placeholder={'send message'}/>
                    <Button primary={'true'} onClick={sendMessage}>Send</Button>
                </form>
            </Footer>
        </ChatWrapper>
    );
};

export default Chat;
