import React from 'react';

export const KeyboardUpArrowIcon = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 9.1665L6.675 10.3415L10.5 6.52484L14.325 10.3415L15.5 9.1665L10.5 4.1665L5.5 9.1665Z"
                  fill="#2E7D32"/>
            <path d="M5.5 14.658L6.675 15.833L10.5 12.0164L14.325 15.833L15.5 14.658L10.5 9.65804L5.5 14.658Z"
                  fill="#2E7D32"/>
        </svg>
    );
};
