import React from 'react';

export const ExpandMoreIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29687 7.41L12.8769 12L8.29687 16.59L9.70687 18L15.7069 12L9.70687 6L8.29687 7.41Z" fill="black"
                  fillOpacity="0.54"/>
        </svg>

    );
};
