import React from 'react';

export const LocalLibraryOutlinedIcon = (props) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 9C14.71 9 16.5 7.21 16.5 5C16.5 2.79 14.71 1 12.5 1C10.29 1 8.5 2.79 8.5 5C8.5 7.21 10.29 9 12.5 9ZM12.5 3C13.6 3 14.5 3.9 14.5 5C14.5 6.1 13.6 7 12.5 7C11.4 7 10.5 6.1 10.5 5C10.5 3.9 11.4 3 12.5 3ZM12.5 11.55C10.14 9.35 6.98 8 3.5 8V19C6.98 19 10.14 20.35 12.5 22.55C14.86 20.36 18.02 19 21.5 19V8C18.02 8 14.86 9.35 12.5 11.55ZM19.5 17.13C16.97 17.47 14.57 18.43 12.5 19.95C10.44 18.43 8.03 17.46 5.5 17.12V10.17C7.6 10.55 9.55 11.52 11.14 13L12.5 14.28L13.86 13.01C15.45 11.53 17.4 10.56 19.5 10.18V17.13Z"
                fill="currentColor"/>
        </svg>
    );
};
