import {useGetPreviousOwnersOfArtQuery} from "../../../../Redux/query/ArtsAPI";
import React, {FC, useEffect, useState} from "react";
import {ArtItem, ArtText, VerifiedIconBox} from "../../../../Pages/art/View/SingleArt/styled";
import {CreationReviewPopup} from "../CreationReviewPopup";
import {Modal, Space, Spin} from "antd";
import {
    LeaveRequestBox,
    PreviousOwnerAvatarContainer,
    PreviousOwnerAvatarImg,
    PreviousOwnerBox,
    PreviousOwnerContainer,
    PreviousOwnerInfoContainer,
    PreviousOwnerJoined,
    PreviousOwnerName,
    RateBox, ReviewsCount,
    StarsContainer,
    TextRate
} from "./styled";
import {IPreviousOwner} from "../../../../Pages/art/View/SingleArt/typed";
import StarRatings from 'react-star-ratings';

import {ReviewsListModal} from "../ReviewsListModal";
import {LeaveRequest} from "../CreationReviewPopup/styled";
import {getMonthYear} from "../../../../Utils/DateFormat";
import {Tooltip} from "../../../Tooltip";


interface IPreviousOwnersList {
    artId: string;
    currentUserId: string;
}

export const PreviousOwnersList:FC<IPreviousOwnersList> = ({artId, currentUserId}) => {

    const { data, isLoading, isFetching} = useGetPreviousOwnersOfArtQuery({artId: artId});
    const [previousOwnersList, setPreviousOwnersList] = useState<IPreviousOwner[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCreateReviewModalOpen, setIsCreateReviewModalOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<{id: string; name: string; hidden: boolean}>(
        {id: '', name: '', hidden: false});

    useEffect(()=>{
        if(data?.data?.length > 0)
        {
            setPreviousOwnersList(data?.data)
        }
    },[data])


    return (
        <>

            { (isFetching || isLoading) && <Spin size="large" />}
            {
                (!isFetching && !isLoading) && previousOwnersList?.length > 0 &&
                    <PreviousOwnerBox>
                        <ArtText type={'label'} style={{paddingBottom: '15px'}}>Previous owner(s) ({data?.data?.length}):</ArtText>
                        {
                            previousOwnersList?.map((item: IPreviousOwner, index) =>
                                <PreviousOwnerContainer key={item.id}>
                                    <PreviousOwnerAvatarContainer>
                                        <PreviousOwnerAvatarImg>
                                            {item.attributes.name.slice(0,1).toLocaleUpperCase()}
                                        </PreviousOwnerAvatarImg>
                                        <PreviousOwnerInfoContainer>
                                            <PreviousOwnerName>
                                                {
                                                    currentUserId == item.id ?
                                                        'You' :
                                                    item.attributes.name
                                                }
                                                {item.attributes.userVerified &&
                                                    <Tooltip title="Verified">
                                                        <VerifiedIconBox/>
                                                    </Tooltip>
                                                }
                                            </PreviousOwnerName>
                                            <PreviousOwnerJoined>
                                               Joined in {getMonthYear(item.attributes.createdAt)}
                                            </PreviousOwnerJoined>
                                        </PreviousOwnerInfoContainer>
                                    </PreviousOwnerAvatarContainer>
                                    <RateBox>
                                        <StarsContainer>
                                            {
                                                item.attributes.rateScore &&
                                                <>
                                                    <StarRatings
                                                        rating={item.attributes.rateScore}
                                                        isSelectable={false}
                                                        starDimension={'20px'}
                                                        starRatedColor='#FFB400'
                                                        starHoverColor='#FFB400'
                                                        starEmptyColor='rgba(0, 0, 0, 0.23)'
                                                        numberOfStars={5}
                                                        name='rating'
                                                        starSpacing={'1px'}
                                                        svgIconPath='M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z'
                                                        svgIconViewBox={'0 0 20 20'}
                                                    />
                                                    <TextRate>
                                                        {item.attributes.rateScore}
                                                    </TextRate>
                                                </>
                                            }
                                            {
                                                (currentUserId == item.id || !item.attributes.hideOwner ) &&
                                                    <ReviewsCount
                                                        onClick={() => {
                                                            if(item.attributes.rateCount != 0)
                                                            {setIsModalOpen(true);
                                                                setSelectedUser(
                                                                    {id: item.id, name: item.attributes.name,
                                                                        hidden: item.attributes.hideOwner
                                                                    })}
                                                        }}
                                                        style={{
                                                            textDecoration: item.attributes.rateCount == 0 ? 'none': 'underline',
                                                            cursor: item.attributes.rateCount == 0 ? 'default': 'pointer'
                                                        }}>
                                                        ({item.attributes.rateCount} reviews)
                                                    </ReviewsCount>
                                            }
                                        </StarsContainer>
                                        {
                                            index == 0 && item.attributes.pendingReviews &&
                                            <LeaveRequestBox>
                                                <LeaveRequest type={'label'} onClick={() => setIsCreateReviewModalOpen(true)}>
                                                    Leave review
                                                </LeaveRequest>
                                                <CreationReviewPopup isModalOpen={isCreateReviewModalOpen} setIsModalOpen={setIsCreateReviewModalOpen} name={item.attributes.name} id={item.id}/>
                                            </LeaveRequestBox>
                                        }
                                    </RateBox>
                                </PreviousOwnerContainer>
                            )
                        }
                    </PreviousOwnerBox>
            }
            <ReviewsListModal
                artId={artId}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                user={selectedUser}
            />
        </>
    )
}
