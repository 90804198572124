import React from 'react';
import { Layout } from '../../../Components';
import { items } from './config';
import { Tabs } from '../../../Components/Tabs';
import { FlexCenter, TableTitle } from '../../../Components/styled';
import { Typography } from 'antd';

export const GalleryPage = () => {
  return (
    <Layout>
      <FlexCenter>
        <TableTitle level={2}>Gallery</TableTitle>
      </FlexCenter>
      <Tabs items={items} centered />
    </Layout>
  );
};
