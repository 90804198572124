import styled from "styled-components";
import {Table, TableProps, Typography} from "antd";
import {devices} from "../../../Theme";

export const TableBox = styled.div`
  padding: 24px;
  border: 1px solid #e8eaf6;
  border-radius: 12px;
  @media ${devices.tablet} {
    padding: 20px;
  }
  @media ${devices.mobile} {
    padding: 12px 10px;
  }
`;
export const TableItem = styled.div`
  padding: 25px 16px;
  border-top: 1px solid #e8eaf6;
  border-bottom: 1px solid #e8eaf6;
  width: 100%;
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    padding: 18px 15px;
  }
  @media ${devices.mobile} {
    padding: 12px 10px;
  }
`;

export const TableItemName = styled.div`
  width: 80%;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.87);
  padding-right: 20px;
`;
export const TableItemSwitch = styled.div`
  width: 20%;
`;
export const TableRows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  @media ${devices.mobile} {
   width: 100%;
    margin-right: 5px;
    margin-bottom: 20px;
  }
`;
export const TableTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
  
`;
export const TableArrowRight = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TableArrowLeft = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  margin-right: 15px;
`;

export const TableTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 10px;
  width: 100%;
  @media ${devices.tablet} {
    font-size: 18px;
  }
`;

export const TableFooter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding: 16px 0 0;
  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`;

export const TableBody = styled.div({
    width: '100%',
    flexWrap: 'wrap',
    display: 'flex',

    ':nth-child(even)': {
        background: '#fff'
    },
    ':nth-child(odd)': {
        background: 'rgba(0, 0, 0, 0.04)'
    },
});
