import styled from "styled-components";
import {Tabs, TabsProps} from "antd";
import {FC} from "react";

export const TabsStyled: FC<TabsProps> = styled(Tabs)`
  .ant-tabs-nav {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 24px;

    :before {
      display: none;
    }

    svg {
      margin-right: 8px;
    }
  }

  .ant-tabs-tab {
    padding: 9px 0;
  }
`