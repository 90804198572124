import React, { FC, useEffect, useState } from 'react';
import { PopupProps } from './typed';
import {
  ButtonCancel,
  ButtonSubmit,
  CloseModalIconWrap,
  CustomModal,
  InputsContainer,
  ModalBox,
  ModalButtonGroup,
  ModalDesc,
  ModalHeader,
  Modaltitle
} from './styled';
import { useSellArtMutation, useSendArtToUserQuery } from '../../../Redux/query/ArtsAPI';
import { StyledInputNew, StyledInputNumberNew } from '../../Input/styled';
import { FormFieldNew } from '../../Form/styled';
import { Col, Spin } from 'antd';
import { PrivateCheckbox } from '../../../Pages/art/Create/styled';
import { FormInputRow } from '../../Form/FormInputGroup/styled';
import useDebounce from '../../../Hooks/useDebounce';
import CloseModalIcon from '../../../Icons/CloseModalIcon';

export const TransferModal: FC<PopupProps> = ({
  open,
  setIsModalOpen,
  title,
  price,
  artId,
  transferSuccess
}) => {
  const [transferPrice, setTransferPrice] = useState<number>(price);
  const [userId, setUserId] = useState('');
  const [skip, setSkip] = useState(true);
  const [priceAgree, setPriceAgree] = useState<boolean>(false);

  const debouncedSearch = useDebounce<string>(userId, 300);

  const {
    data: userData,
    isLoading: userLoading,
    isSuccess: userSuccess,
    isError
  } = useSendArtToUserQuery(
    {
      id: debouncedSearch
    },
    {
      skip,
      refetchOnMountOrArgChange: true
    }
  );
  const [sellArt, { isLoading }] = useSellArtMutation();

  const handleTransferArt = async () => {
    const transfer = {
      transfer_request: {
        price: transferPrice,
        art_id: artId,
        buyer_id: userData.data.id
      }
    };

    const res = await sellArt(transfer);
    handleCancel();
    if ('data' in res) {
      if (res?.data?.data?.attributes?.status == 'pending') {
        transferSuccess();
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUserId('');
    setPriceAgree(false);
  };

  useEffect(() => {
    if (userId.length > 2) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [userId]);

  useEffect(() => {
    if (!transferPrice?.toString()?.length) {
      setPriceAgree(false);
    }
  }, [transferPrice]);

  const disableSubmitButton =
    priceAgree && transferPrice?.toString()?.length && userSuccess && !isLoading;

  return (
    <CustomModal width={596} open={open} footer={null} closable={false} onCancel={handleCancel}>
      <ModalHeader>
        <Modaltitle>
          Transfer {'"'}
          {title}
          {'"'}
        </Modaltitle>
        <CloseModalIconWrap onClick={() => setIsModalOpen(false)}>
          <CloseModalIcon />
        </CloseModalIconWrap>
      </ModalHeader>
      <ModalBox>
        <ModalDesc>
          To transfer artwork, select the username you wish to transfer it to. You also need to
          agree to the final selling price, you will not be able to change it later.
        </ModalDesc>
        <ModalDesc>
          Your current price is <b>${price.toLocaleString()}</b>.
        </ModalDesc>
      </ModalBox>
      <InputsContainer>
        <FormFieldNew
          required
          label="Username"
          rules={[{ required: true, message: 'Please Input your artist name!' }]}>
          <StyledInputNew
            onChange={(event) => setUserId(event.target.value)}
            required
            name="username"
            value={userId}
            allowClear={true}
            placeholder={'Type username'}
          />
        </FormFieldNew>
        {userLoading && <Spin size="small" />}
        {isError && !userLoading && 'User not found'}

        <FormInputRow style={{ width: '100%', marginTop: '40px' }}>
          <Col flex={1}>
            <FormFieldNew
              required
              label="Final selling price"
              rules={[
                { required: true, message: 'please type price!' },
                { min: 0, message: `price can't be less 0` }
              ]}>
              <StyledInputNumberNew
                defaultValue={price}
                min={0}
                step={1}
                value={transferPrice}
                max={1000000000}
                onChange={(value) => setTransferPrice(value)}
                name="price"
                stringMode
                placeholder={price}
              />
            </FormFieldNew>
            <PrivateCheckbox
              checked={priceAgree}
              value="price"
              onChange={(e) => setPriceAgree(e.target.checked)}>
              Yes, I agree to transfer it for this price.
            </PrivateCheckbox>
          </Col>
        </FormInputRow>
      </InputsContainer>

      <ModalButtonGroup>
        <ButtonCancel onClick={handleCancel}>cancel</ButtonCancel>
        <ButtonSubmit disabled={!disableSubmitButton} primary="true" onClick={handleTransferArt}>
          {isLoading ? 'loading...' : 'Transfer'}
        </ButtonSubmit>
      </ModalButtonGroup>
    </CustomModal>
  );
};
