import {color, devices} from "../../../../Theme";
import {Typography} from "antd";
import styled from "styled-components";


export const InfoDetails = styled.div`
  padding-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 32px;
  margin-bottom: 20px;
`;

export const InfoTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
`;

export const InfoSubTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
`;

export const InfoDetailsWrap = styled.div`
 display: flex;
`;
export const InfoDetailsColFirst = styled.div`
 margin-right: 32px;
  min-width: 150px;
  @media ${devices.tablet} {
    margin-right: 15px;
    min-width: 120px;
  }
`;

export const InfoDetailsCol = styled.div`
 
`;

export const InfoColName = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  @media ${devices.tablet} {
    font-size: 14px;
    line-height: 120%;
  }
  @media ${devices.mobileL} {
    font-size: 13px;
    line-height: 110%;
    margin-bottom: 13px;
  }
`;

export const InfoColValue= styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  @media ${devices.tablet} {
    font-size: 14px;
    line-height: 120%;
  }
  @media ${devices.mobileL} {
    font-size: 13px;
    line-height: 110%;
    margin-bottom: 13px;
  }
`;
