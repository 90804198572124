import React, {FC, useEffect, useState} from 'react';
import {
    CloseButton,
    DotActive,
    NotificationButtonWrap,
    NotificationGrid,
    NotificationHeader,
    NotificationNew,
    NotificationTitle
} from '../../Notification/styled';

import {List, MessageColumn, MessageDescNot, MessageItem, NotificationBox, NotificationButton, Time} from "./styled";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../firebase";
import {Message, MessageDesc, MessageImg, MessagesBox} from "../../../Pages/ChatRoom/components/ChatBox/styled";
import {useGetChatsQuery} from "../../../Redux/query/chats/chatsAPI";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../Hooks";
import {ExpandIcon} from '../../../Icons';
import {Divider} from 'antd';
import moment from "moment";

export const ChatNotify: FC = () => {
    const {userInfo} = useAppSelector((state) => state.user);
    const {data: chats} = useGetChatsQuery({})
    const navigate = useNavigate();
    const [isOpenChat, setIsOpenChat] = useState<boolean>(false);

    const [conversationsList, setConversationsList] = useState([])
    const [unreadConversationsList, setUnreadConversationsList] = useState([])
    const [chatsID, setChatsID] = useState([]);


    useEffect(() => {
        if (chats) {
            let chatsIDs = [];
            if (chats?.data?.length) {
                chatsIDs = chats?.data?.map(el => el?.id);
                setChatsID([...chatsIDs])
            }
        }
    }, [chats])

    useEffect(() => {
        const conversationsArray = [];
        const q1 = query(collection(db, `conversations`), where('user1.id', "==", userInfo?.data?.id));
        const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                conversationsArray.push({...doc.data(), id: doc.id});
            });
            setConversationsList(conversationsArray)
            return () => unsubscribe1();
        });

    }, []);

    useEffect(() => {
        const conversationsArray = [];
        const q2 = query(collection(db, `conversations`), where('user2.id', "==", userInfo?.data?.id));

        const conversationSnapshot = onSnapshot(q2, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                conversationsArray.push({...doc.data(), id: doc.id});
            });
        });
        return () => conversationSnapshot();
    }, []);

    const readConversations = conversationsList.filter(x => !unreadConversationsList.includes(x))

    useEffect(() => {
        if (chatsID?.length) {
            let unreadConversations = [];
            const queryUnreadConversations = chatsID && query(collection(db, `conversations`), where('lastMessage.ownerID', "!=", userInfo?.data?.id), where('isRead', "==", false), where('externalChatId', 'in', chatsID));
            const conversationSnapshot = onSnapshot(queryUnreadConversations, (querySnapshot) => {
                unreadConversations = [];
                querySnapshot.forEach((doc) => {
                    unreadConversations.push({...doc.data()});
                });
                setUnreadConversationsList(unreadConversations)
            });
            return () => conversationSnapshot();
        }
    }, [conversationsList, chatsID]);

    const handleOpenChatMessages = () => {
        setIsOpenChat((prev) => !prev)
    }

    const handleOpen = () => {
        navigate('/chatroom')
    }

    return (
        <>
            <NotificationBox className={isOpenChat && 'is-active'}>
                <NotificationHeader>
                    <NotificationTitle>Messages</NotificationTitle>
                    <CloseButton onClick={() => handleOpenChatMessages()}>X</CloseButton>
                </NotificationHeader>
                <NotificationNew>{unreadConversationsList?.length > 0 ? 'New' : 'No incoming messages'}</NotificationNew>
                <NotificationGrid>
                    <MessagesBox>
                        <List>
                            {unreadConversationsList?.map((el, index) => {
                                const date = new Date(el?.lastMessage?.timestamp?.seconds * 1000 + el?.lastMessage?.timestamp?.nanoseconds / 1000000)
                                return (
                                    <>
                                        <MessageItem key={index} id={index} onClick={handleOpen}>
                                            <MessageImg
                                                src={el?.imageUrl}/>
                                            <MessageColumn>
                                                <MessageDesc elipsis>
                                                    {el?.lastMessage?.text}
                                                </MessageDesc>
                                                <Time>{moment(date).format('Do MMM h:mm')}</Time>
                                            </MessageColumn>
                                            <ExpandIcon/>
                                        </MessageItem>
                                        <Divider/>
                                    </>
                                )
                            })}
                            <NotificationNew>{readConversations?.length > 0 ? 'Read messages' : ''}</NotificationNew>
                            {readConversations?.map((el, index) => {
                                const date = new Date(el?.lastMessage?.timestamp?.seconds * 1000 + el?.lastMessage?.timestamp?.nanoseconds / 1000000)
                                return (
                                    <>
                                        <MessageItem key={index} id={index} onClick={handleOpen}>
                                            <MessageImg
                                                src={el?.imageUrl}/>
                                            <MessageColumn>
                                                <MessageDescNot elipsis>
                                                    {el?.lastMessage?.text}
                                                </MessageDescNot>
                                                <Time>{moment(date).format('Do MMM h:mm')}</Time>
                                            </MessageColumn>
                                            <ExpandIcon/>
                                        </MessageItem>
                                        <Divider/>
                                    </>
                                )
                            })}
                        </List>
                    </MessagesBox>
                </NotificationGrid>
            </NotificationBox>
            <NotificationButtonWrap>
            </NotificationButtonWrap>
            <NotificationButtonWrap>
                {unreadConversationsList?.length > 0 && <DotActive>{unreadConversationsList?.length}</DotActive>}
                <NotificationButton className={isOpenChat && 'is-active'} onClick={handleOpenChatMessages}/>
            </NotificationButtonWrap>
        </>
    );
};
