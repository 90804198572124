import { BuyArtModal, TransferModal } from '../../../Modal';
import Button from '../../../Button';
import { Modal } from 'antd';
import React, { FC, useState } from 'react';
import { useAppSelector } from '../../../../Hooks';
import { useParams } from 'react-router-dom';
import { TransferBuyButton } from './styled';

interface TransferBuyProps {
  data: any;
  refetch: () => void;
}

export const TransferBuy: FC<TransferBuyProps> = ({ data, refetch }) => {
  const params = useParams();
  const { userInfo } = useAppSelector((state) => state.user);
  const artOwner = data?.data?.attributes?.ownerId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isBuyModalOpen, setBuyIsModalOpen] = useState(false);

  const showModal = () => {
    setBuyIsModalOpen(true);
  };

  const handleCancel = () => {
    setBuyIsModalOpen(false);
  };

  const transferSuccess = () => {
    setModalType('sell');
    setIsModalOpen(true);
  };

  const buySuccess = () => {
    setModalType('buy');
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    refetch();
  };

  return (
    <>
      {data?.data?.attributes?.buyingStatus !== 'undefined' && (
        <>
          {data?.data?.attributes?.buyingStatus == 'pending' ? (
            <p>Your transaction request has been placed</p>
          ) : (
            <>
              {userInfo?.data.id == artOwner ? (
                <>
                  {data?.data?.attributes?.sellingStatus !== 'undefined' && (
                    <>
                      {data?.data?.attributes?.sellingStatus == 'available' && (
                        <>
                          <TransferModal
                            title={data?.data?.attributes?.title}
                            price={data?.data?.attributes?.price}
                            open={isBuyModalOpen}
                            setIsModalOpen={setBuyIsModalOpen}
                            artId={params.id}
                            transferSuccess={transferSuccess}
                          />
                          <TransferBuyButton primary="true" onClick={showModal}>
                            Transfer artwork
                          </TransferBuyButton>
                        </>
                      )}
                      {data?.data?.attributes?.sellingStatus == 'pending' && (
                        <p>Artwork has been reserved!</p>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {data?.data?.attributes?.buyingStatus == 'available' && (
                    <>
                      <BuyArtModal
                        artId={params.id}
                        sellerId={artOwner}
                        price={data.data.attributes.price}
                        title={data.data.attributes.title}
                        onCancel={handleCancel}
                        open={isBuyModalOpen}
                        buySuccess={buySuccess}
                      />
                      <TransferBuyButton primary="true" onClick={showModal}>
                        Send Purchase Request
                      </TransferBuyButton>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <Modal title="Success!" open={isModalOpen} onOk={handleOk} onCancel={handleOk}>
        <>
          {modalType == 'sell' && <p>Artwork has been transferred successfully!</p>}
          {modalType == 'buy' && <p>Your transaction request has been placed</p>}
        </>
      </Modal>
    </>
  );
};
