import styled from 'styled-components';
import { variant } from 'styled-system';
import { color } from '../../Theme';

const BoxType = variant({
  prop: 'type',
  variants: {
    card: {
      background: `${color.lightGrey}`,
      borderRadius: '10px'
    }
  }
});

export const StyledBox = styled.div`
  width: 100%;
  padding: 24px;
  ${BoxType}
`;
