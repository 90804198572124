import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICode, ICodeForgot } from '../../Models/IUser';

const location = 'https://api.provenart.io';
// const location = 'https://api.provenart.io';

export const registerCode = createAsyncThunk(
  '/verify_code',
  async (user: ICode, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      await axios.post(`${location}/verify_code`, { user }, config);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const forgotPassword = createAsyncThunk(
  '/forgot_password',
  async (user: ICodeForgot, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(`${location}/forgot_password`, { user }, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendVerifyCode = createAsyncThunk(
  '/verify_code',
  async (user: ICodeForgot, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(`${location}/verify_code`, { user }, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  '/reset_password',
  async (user: ICodeForgot, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(`${location}/reset_password`, { user }, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  '/api/v1/user',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const user: any = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${user.user.accessToken}`
        }
      };
      const { data } = await axios.get(`${location}/api/v1/user`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
