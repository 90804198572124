import React, { FC, useCallback, useEffect, useState } from 'react';
import { Cell, Pie, PieChart, Sector } from 'recharts';
import { useGetPieChartQuery } from '../../../Redux/analytics/analyticsAPI';
import { Spin } from 'antd';
import { PieChartBox, PieChartContainer, TotalArts, TotalBox, TotalTitle } from './styled';

import { Total } from '../types';

const COLORS = [
  '#304FFE',
  '#3D5AFE',
  '#536DFE',
  '#8C9EFF',
  '#1A237E',
  '#283593',
  '#303F9F',
  '#3949AB',
  '#3F51B5',
  '#5C6BC0',
  '#7986CB',
  '#9FA8DA',
  '#6200EA',
  '#651FFF',
  '#7C4DFF',
  '#311B92',
  '#4527A0',
  '#512DA8',
  '#5E35B1',
  '#673AB7'
];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text fontSize="18px" x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {`$ ${value.toLocaleString()}`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        fontSize="13px"
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333">
        {payload.name.length > 18 ? payload.name.slice(0, 17) + '...' : payload.name}
      </text>
    </g>
  );
};

interface IPieChartComponent {
  totalObj: Total;
}

export const PieChartComponent: FC<IPieChartComponent> = ({ totalObj }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [total, setTotal] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const { data, isLoading, isSuccess, isFetching } = useGetPieChartQuery({});

  useEffect(() => {
    if (data?.data) {
      const arr = [];
      let counter = 0;
      for (const key in data.data) {
        arr.push({
          name: key,
          value: data.data[key]
        });
        counter++;
      }
      setTotal(counter);
      setPieChartData(arr);
    }
  }, [data]);

  return (
    <PieChartBox>
      <TotalBox>
        <TotalTitle> Total Artists: ({total})</TotalTitle>
        {totalObj?.artists_count && <TotalArts>Total Artworks: {totalObj.arts_count}</TotalArts>}
      </TotalBox>
      <PieChartContainer>
        {(isLoading || isFetching) && <Spin tip="Loading" size="small" />}
        {!isLoading && !isFetching && pieChartData.length && (
          <PieChart width={450} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={pieChartData}
              cx={200}
              cy={200}
              innerRadius={60}
              outerRadius={110}
              dataKey="value"
              onMouseEnter={onPieEnter}>
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        )}
      </PieChartContainer>
    </PieChartBox>
  );
};
