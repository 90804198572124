import styled from "styled-components";
import {Space, Typography, TypographyProps} from "antd";
import {color, devices} from "../../../Theme";
import {FavoriteIcon} from "../../../Icons";

interface IFavoriteIcon {
    onClick?: (e) => void
    id?: string
}

export const GalleryImg = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;

  &:not([src]) {
    content: url('https://api.lorem.space/image?w=150&h=180');
  }
`;
export const GalleryItem = styled(Space)`
  padding: 16px;
  @media ${devices.laptop} {
    padding: 16px 8px;
  }
`
export const GalleryCardInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  @media ${devices.tablet} {
    min-height: 250px;
  }
  @media ${devices.tabletS} {
    min-height: 230px;
  }
`;

export const ArtistBox = styled.div`
  display: flex;
  align-items: center;
`;

export const LikeIconBox = styled(FavoriteIcon)<IFavoriteIcon>({
    display: "flex",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    padding: '4px',
    position: 'relative',
    "&:hover, &:focus": {
        background: 'rgba(211, 47, 47, 0.04)',
        borderRadius: '48px',
    },
});
export const Card = styled.div`
  cursor: pointer;
  width: calc(25% - 20px);
  margin: 0 10px 20px;
  @media ${devices.laptop} {
    width: calc((100% / 3) - 20px);
  }
  @media ${devices.tabletS} {
    width: calc(50% - 20px);
  }
  @media ${devices.mobileL} {
    width: calc(90% - 20px);
  }
  @media ${devices.mobile} {
    width: calc(100% - 20px);
  }
`;

export const GalleryCard = styled.div`
  background: ${color.cardBg};
  box-shadow: 0 0 0 1px #E0E0E0;
  border: 1px solid #E8EAF6;
  width: 100%;
  height: 487px;
  border-radius: 4px;
  overflow: hidden;

  @media ${devices.tabletS} {
    height: 447px;
  }
  
  &:hover, &:active, &:focus {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const GalleryFooter = styled.div`
  padding: 5px 16px 10px;
`

export const ArtPrice = styled(Typography)`
  color: ${color.dark};
  font-size: 24px;
  margin-bottom: 16px;
  @media ${devices.laptopL} {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media ${devices.laptop} {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const ArtistName = styled(Typography.Text)<TypographyProps>({
    color: color.dark,
    fontWeight: 500
})
export const ArtistTitle = styled(Typography)`
    color: ${color.primary};
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
  @media ${devices.laptopL} {
    line-height: 120%;
  }
  @media ${devices.laptop} {
    font-size: 18px;
    line-height: 120%;
  }
  @media ${devices.mobileL} {
    font-size: 16px;
  }
`;

export const ArtistDesc = styled(Typography)`
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.15px;
    max-height: 100px;
    overflow-y: hidden;
`;

export const ArtistTag = styled.div`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  background: #ECEFF1;
  color: #607D8B;
  border-radius: 16px;
  padding: 7px 10px;
  max-width: fit-content;
  display: flex;
  align-items: center;
`
export const ArtistFooterBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const GalleryBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //align-items: center;
`
export const LikeCount = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: #757575;
`
