import React from 'react';

export const NotificationIcon = (props) => {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4987 32.0834C19.1029 32.0834 20.4154 30.7709 20.4154 29.1667H14.582C14.582 30.7709 15.8945 32.0834 17.4987 32.0834ZM26.2487 23.3334V16.0417C26.2487 11.5646 23.8716 7.81671 19.6862 6.82504V5.83337C19.6862 4.62296 18.7091 3.64587 17.4987 3.64587C16.2883 3.64587 15.3112 4.62296 15.3112 5.83337V6.82504C11.1404 7.81671 8.7487 11.55 8.7487 16.0417V23.3334L5.83203 26.25V27.7084H29.1654V26.25L26.2487 23.3334ZM23.332 24.7917H11.6654V16.0417C11.6654 12.425 13.8674 9.47921 17.4987 9.47921C21.1299 9.47921 23.332 12.425 23.332 16.0417V24.7917Z"
                fill="#1976D2"/>
        </svg>

    );
};
